export const QboardMenus = [
  {
    label: "qboard-global",
    name: "Global",
  },
  {
    label: "qboard-myinterest",
    name: "My Interest",
  },
  {
    label: "qboard-myconnect",
    name: "My Connect",
  },
];

export const RequirementMenus = [
  {
    label: "requirements-jobs",
    name: "Jobs",
  },
  {
    label: "requirements-training",
    name: "Training",
  },
];

export const TrainingMenus = [
  {
    label: "Online",
    name: "Online",
  },
  {
    label: "Offline",
    name: "Offline",
  },
  {
    label: "Classroom",
    name: "Classroom",
  },
];

import { useState, useEffect } from 'react'

import { Redirect } from "react-router-dom";
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Autocomplete from 'react-autocomplete';

import InterestModal from './InterestModal';
import ProfileService from "../../../services/profile.service";
import CommonService from '../../../services/common.service';
import { getUserData } from '../../../utils/UserDetails';

const PrimaryProfile = () => {

  const currentUser = getUserData();
  const successData = {
    isModalOpen: false,
    isGoogleUser: false,
  }
  
  const [successModalData, setSuccessModalData] = useState(successData)
  const [iserror, setError] = useState(false)
  const [activeData, setActiveData] = useState({})
  const [profileData, setprofileData] = useState({});
  const [companyData, setcompanyData] = useState({});
  const [masterRoles, setMasterRoles] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [profileLabel, setprofileLabel] = useState([]);

  useEffect(() => {
    getRoles();

    if (currentUser) {
      let userProfile = currentUser.user_profile;
      let googleUser = (currentUser.is_new_user) ? true : false;
      let companydetails = (currentUser.company_data) ? currentUser.company_data : {};

      if (companydetails && companydetails.company_name) {
        setprofileLabel([
          { label: 'Food' },
          { label: 'Intrest' },

        ])
      } else {
        setprofileLabel([
          { label: 'Sales trainer' },
          { label: 'Communication trainer' },
          { label: 'Java trainer' },
          { label: 'Softskill trainer' }
        ])
      }

      setSuccessModalData({ ...successModalData, isModalOpen: true, isGoogleUser: googleUser })
      setprofileData(userProfile)
      setcompanyData(companydetails);
    } else {
      setError(true);
    }
  }, [])

  useEffect(() => {
    if (currentUser) {
      let role = (currentUser.user_role);
      let roleKey = 0;

      masterRoles.map((u, k) => {
        if (u.roles_master_code === role) {
          roleKey = k;
        }
      });

      if (masterRoles.length)
        setUserRole(masterRoles[roleKey]['roles_master_name'])
    }
  }, [masterRoles])

  const closeSuccessModal = () => {
    setSuccessModalData({ ...successModalData, isModalOpen: false })
  }

  const getRoles = async () => {
    let resultData = await CommonService.getRoles();

    if (resultData.status === 2000) {
      setMasterRoles(resultData.data)
    }
  }

  const handleChange = (value) => {
    let profile = { ...profileData };
    profile['user_profile_name'] = value;
    setprofileData(profile);
  }

  const setProfileName = async () => {

    let profile = { ...profileData };
    profile['errors'] = "";

    if (profile['user_profile_name']) {
      let resultData = await ProfileService.newProfile(profile);
      console.log(profile)
console.log(resultData)
      if (resultData.status) {
        setActiveData(profile)
        closeSuccessModal();
      } else {
        toast.error('Try again')
      }
    } else {
      profile['errors'] = "Enter user profile name";
    }
    setprofileData(profile)
  }
console.log(activeData)
  return (
    <>
      {(iserror) &&
        <Redirect to="/" />
      }

      {(activeData && activeData.user_profile_id) ?
        <InterestModal profileDetails={activeData} fromSignIn={1} />
        : null
      }

      <Modal show={successModalData.isModalOpen} onHide={closeSuccessModal} backdrop="static" keyboard={false} className="tj-modal" size="lg">
        <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container p-4 text-center">
            <div className="form-group mb-2">
              {
                (companyData && companyData.company_name) ?
                  <>
                    <h5 className="mb-2">Welcome {currentUser.user_name} from {companyData.company_name} !</h5>
                    <p>kindly select your company's line of business.</p>
                  </> :
                  <>
                    <h5 className="mb-2">Welcome {userRole}!</h5>
                    <p>Kindly select your primary profile to login.</p>
                  </>
              }
              <Autocomplete
                getItemValue={(item) => item.label}
                items={profileLabel}
                renderItem={(item, isHighlighted) =>
                  <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                    {item.label}
                  </div>
                }
                value={(profileData && profileData.user_profile_name) ? profileData.user_profile_name : ""}
                onChange={(e) => handleChange(e.target.value)}
                onSelect={(val) => handleChange(val)}
              />
              <br />
              <button className="btn btn-primary btn-sm mx-auto d-block mt-4" onClick={setProfileName}>
                Continue
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PrimaryProfile;

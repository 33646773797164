import CommonService from "../services/common.service";
import ZoomService from "../services/zoom.service";


import {
  SET_ZOOM_MEETINGS,
  ZOOM_NOTIFICATION,
  ZOOM_MEETING_MODAL,
  INSERT_ZOOM_MEETINGS,
  UPDATE_ZOOM_MEETINGS,
  DELETE_ZOOM_MEETINGS,
  ZOOM_IS_AUTH

} from './types';

export const getZoomMeetings = () => async (dispatch) => {
  try {
    const result = await ZoomService.getZoomMeetings();
    if(result && result.status === true) {
      dispatch({
        type: SET_ZOOM_MEETINGS,
        payload: result.data,
    });
    } else {
      dispatch(seZoomLinkNotification({message:"Please Try Again", isSuccess: 0}))
    }
  } catch (err) {
    dispatch(seZoomLinkNotification({message:"Please Try Again", isSuccess: 0}))
  }
};

export const setZoomModal = (data) => {
  return {
    type: ZOOM_MEETING_MODAL,
    payload: data,
  };
}

export const saveZoomMeeting = (formRequestData, resetForm, setSubmitting, closeModal) =>  async(dispatch) => {
  try {
  const resultData = await ZoomService.setZoomScheduleMeeting(formRequestData);
  if(resultData && resultData.status === true) {
    if(formRequestData.zoom_meetings_zoom_id) {
      dispatch(updateData(resultData.data));
      dispatch(seZoomLinkNotification({message:"Updated", isSuccess: 1}))
    } else {
      dispatch(insertData(resultData.data));
      dispatch(seZoomLinkNotification({message:"Added", isSuccess: 1}))
    }
    closeModal();
    resetForm();
  } else {
    if(resultData && resultData.isAuth === false) {
      dispatch(zoomAuthCheck(true))
    } else {
      dispatch(seZoomLinkNotification({message:"Please Try Again", isSuccess: 0}))
    }
  }
   
  setSubmitting(false);
  } catch(err) {
    dispatch(seZoomLinkNotification({message:"Please Try Again", isSuccess: 0}))
    setSubmitting(false);
  }
} 

export const getZoomConnect = (data) => async(dispatch) => {
  const resultData = await ZoomService.getZoomConnect(data);
  if(resultData && resultData.status === true) {
    dispatch(seZoomLinkNotification({message:"Connected", isSuccess: 1}))
  } else {
    dispatch(seZoomLinkNotification({message:"Please Try Again", isSuccess: 0}))
  }
}

export const setInstantZoomMeeting = (data) => async(dispatch) => {
  const resultData = await ZoomService.setZoomScheduleMeeting(data);

  if(resultData && resultData.status === true) {
    CommonService.redirectLink(resultData.data.zoom_meetings_join_url);
    dispatch(seZoomLinkNotification({message:"Created", isSuccess: 1}))
  } else {
    if(resultData && resultData.isAuth === false) {
      dispatch(zoomAuthCheck(true))
    } else {
    dispatch(seZoomLinkNotification({message:"Please Try Again", isSuccess: 0}))
    }
  }

}

export const deleteZoomMeetings = (deleteID, setConfirmationOpen) => async(dispatch) => {
  const resultData = await ZoomService.deleteZoomMeeting(deleteID);

  if(resultData && resultData.status === true) {
    dispatch(deleteData(deleteID))
    dispatch(seZoomLinkNotification({message:"Deleted", isSuccess: 1}))
    setConfirmationOpen(false)
  } else {
    dispatch(seZoomLinkNotification({message:"Please Try Again", isSuccess: 0}))
  }
}

export const seZoomLinkNotification = (message={}) => {
  return {
      type: ZOOM_NOTIFICATION,
      payload: message,
  };
}

export const zoomAuthCheck = (isTrue) => {
  return {
    type: ZOOM_IS_AUTH,
    payload: isTrue
  }
}

const insertData = (data) => {
  return {
    type: INSERT_ZOOM_MEETINGS,
    payload: data
  }
}

const updateData = (data) => {
  return {
    type: UPDATE_ZOOM_MEETINGS,
    payload: data
  }
}

const deleteData = (data) => {
  return {
    type: DELETE_ZOOM_MEETINGS,
    payload: data
  }
}
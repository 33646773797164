import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";

const VerticalMenus = (props) => {
  return (
    <>
      <div className="row">
        <div className="col-sm-1 refresh-column bg-white">
          <FontAwesomeIcon
            icon={faSyncAlt}
            style={{ marginLeft: "-8px" }}
            className="text-muted"
            role="button"
            onClick={() =>
              props.setShowRefreshAlertModal({
                ...props.showRefreshAlertModal,
                showAlert: true,
                source: props.verticalMenu,
              })
            }
          />
        </div>
      </div>
      <div className="row">
        <div
          className={
            (props.verticalMenu === "default" ? `col-sm-1 vertical-text-column btn-${props.activeColor}` : `col-sm-1 vertical-text-column `)
          }
          style={{ height: "85px" }}
        >
          <label
            className="verticaltext"
            onClick={() => props.addGroup("default")}
            role="button"
          >
            My Choice
          </label>
        </div>
      </div>
      <div className="row">
        <div
          className={
            (props.verticalMenu === "plans" ? `col-sm-1 vertical-text-column btn-${props.activeColor}` : `col-sm-1 vertical-text-column `)
          }
          style={{ height: "100px" }}
        >
          <label
            className="verticaltext"
            onClick={() => props.addGroup("plans")}
            role="button"
          >
            {" "}
            Plan Trainings{" "}
          </label>
        </div>
      </div>

      <div className="row">
        <div
          className={
            (props.verticalMenu === "batch" ? `col-sm-1 vertical-text-column btn-${props.activeColor}` : `col-sm-1 vertical-text-column `)
          }
          style={{ height: "85px" }}
        >
          <label
            className="verticaltext"
            onClick={() => props.addGroup("batch")}
            role="button"
          >
            {" "}
            Batches{" "}
          </label>
        </div>
      </div>

      <div className="row">
        <div
          className={
            (props.verticalMenu === "associates" ? `col-sm-1 vertical-text-column btn-${props.activeColor}` : `col-sm-1 vertical-text-column `)
          }
          style={{ height: "84px" }}
        >
          <label
            className="verticaltext"
            onClick={() => props.addGroup("associates")}
            role="button"
          >
            {" "}
            Associates{" "}
          </label>
        </div>
      </div>
      <div className="row">
        <div
          className={
            (props.verticalMenu === "finance" ? `col-sm-1 vertical-text-column btn-${props.activeColor}` : `col-sm-1 vertical-text-column `)
          }
          style={{ height: "84px" }}
        >
          <label
            className="verticaltext"
            onClick={() => props.addGroup("finance")}
            role="button"
          >
            {" "}
            Finance{" "}
          </label>
        </div>
      </div>
      <div className="row">
        <div
          className={
            (props.verticalMenu === "feedback" ? `col-sm-1 vertical-text-column btn-${props.activeColor}` : `col-sm-1 vertical-text-column `)
          }
          style={{ height: "84px" }}
        >
          <label
            className="verticaltext"
            onClick={() => props.addGroup("feedback")}
            role="button"
          >
            {" "}
            Feedback{" "}
          </label>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
});

export default connect(mapStateToProps)(VerticalMenus);

import React, { Suspense } from "react";
import { withRouter, Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { getUserMenus, getUserRole } from "../../utils/UserDetails";
import PrivateRoute from "../../routers/PrivateRoute";
import Home from "./Home";
import Dashboard from "../Trainers/dashboard/Dashboard";
import Profile from "../Trainers/profile/Profile";
import Training from "../Trainers/training/Training";
import Preview from "../Trainers/profile/preview/Preview";
import PrimaryProfile from "../Trainers/profile/PrimaryProfile";

const Student = () => {
  return (
    <div className="dashboard-wrapper">
      <Suspense fallback={<div className="loading" />}>
        <Switch>
          {getUserRole() === "STUDENT" ? (
            <>
              <Switch>
                <PrivateRoute
                  exact
                  path={"/student/home"}
                  component={Dashboard}
                />
                {getUserMenus()?.some(
                  (menu) =>
                    menu.functionality?.functionality_master_name === "Profile",
                ) ? (
                  <PrivateRoute
                    exact
                    path={"/student/profile"}
                    component={Profile}
                  />
                ) : null}
                {getUserMenus()?.some(
                  (menu) =>
                    menu.functionality?.functionality_master_name ===
                    "Manage My Trainings",
                ) ? (
                  <PrivateRoute
                    exact
                    path={"/student/training"}
                    component={Training}
                  />
                ) : null}
                <PrivateRoute
                  exact
                  path={"/contact/admin"}
                  component={Training}
                />
                {getUserMenus()?.some(
                  (menu) =>
                    menu.functionality?.functionality_master_name === "Profile",
                ) ? (
                  <PrivateRoute
                    exact
                    path={"/student/preview/:id"}
                    component={Preview}
                  />
                ) : null}
                {getUserMenus()?.some(
                  (menu) =>
                    menu.functionality?.functionality_master_name === "Profile",
                ) ? (
                  <PrivateRoute
                    exact
                    path={"/student/primary-profile-view"}
                    component={PrimaryProfile}
                  />
                ) : null}
                <Redirect to="/student/home" />
              </Switch>
            </>
          ) : (
            <Redirect to="/" />
          )}
        </Switch>
      </Suspense>
    </div>
  );
};

const mapStateToProps = () => {
  //
};

export default Student;

import React, { Suspense } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";

import AuthRoute from "./routers/AuthRoute";
import PrivateRoute from "./routers/PrivateRoute";
import TrainersPrivateRoute1 from "./routers/TrainersPrivateRoute1";
import AuthForm from "./pages/Auth/AuthForm";
import ViewStudent from "./pages/Students";
import ViewRecruiter from "./pages/Recruiter";
import ViewTrainer from "./pages/Trainers";
import PublicRoute from "./routers/PublicRoute";
import EmailVerification from "./components/EmailVerification";
import ProfileRender from "./pages/Trainers/profile/ProfileRender";
import AllUser from "./pages/AllUser/AllUser";
import LoginModal from "./pages/AllUser/LoginModal";
import Profile from "./pages/AllUser/Profile";

class App extends React.Component {
  render() {
    return (
      <div className="h-100">
        <>
          <Suspense fallback={<div className="loading" />}>
            <Router>
              <Switch>
                <TrainersPrivateRoute1
                  path="/trainer"
                  component={ViewTrainer}
                />

                <PrivateRoute path="/student" component={ViewStudent} />
                <PrivateRoute path="/recruiter" component={ViewRecruiter} />

                <AuthRoute
                  path={["/login", "/register", "/"]}
                  component={AuthForm}
                  exact
                />

                <PublicRoute
                  path="/verify-user/:token/:email"
                  component={EmailVerification}
                />
                <PublicRoute path="/explore/:role" component={AllUser} />
                <PublicRoute path="/profile/:role" component={Profile} />

                <PublicRoute
                  path="/profile-view/:id"
                  component={ProfileRender}
                  exact={true}
                />

                <Redirect to="/error" />
              </Switch>
            </Router>
          </Suspense>
        </>
      </div>
    );
  }
}

export default App;

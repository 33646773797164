import { useEffect, useRef } from "react";

import { Formik, Form, Field } from "formik";
import { Modal } from "react-bootstrap";
import { connect } from "react-redux";
import * as Yup from "yup";

import FormLoader from "../../../../../components/FormLoader";
import { setJobApplyData, applyJob } from "../../../../../actions/job";

const JobApplyModal = (props) => {
  const formikRef = useRef();
  const jobApplyFormValues = {
    requirements_job_apply_term: 0,
    requirements_job_apply_resume: null,
    requirements_job_apply_description:
      props.jobApply.requirements_job_apply_description,
    requirements_job_apply_requirements_id:
      props.jobApply.requirements_job_apply_requirements_id,
  };

  const jobApplyValidationSchema = Yup.object().shape({
    requirements_job_apply_term: Yup.bool().oneOf(
      [true],
      "Accept Terms & Conditions is required",
    ),
    requirements_job_apply_resume: Yup.mixed()
      .required("Upload a file!")
      .test("fileSize", "The file is too large", (value) => {
        return value && value.size <= 262144000;
      })
      .test(
        "type",
        "Only the following formats are accepted: .pdf and .doc",
        (value) => {
          return (
            value &&
            (value.type === "application/pdf" ||
              value.type === "application/msword")
          );
        },
      ),
  });

  useEffect(() => {
    if (props.jobApply.isModalOpen) formikRef.current?.resetForm();
  }, [props.jobApply.isModalOpen]);

  const closeModal = () => {
    let jobApplyData = { ...props.jobApply };
    props.setJobApplyData({ ...jobApplyData, isModalOpen: false });
  };

  return (
    <>
      <Formik
        innerRef={formikRef}
        enableReinitialize={true}
        initialValues={jobApplyFormValues}
        validationSchema={jobApplyValidationSchema}
        validateOnChange={false}
        onSubmit={(formData, { resetForm, setSubmitting }) => {
          props.applyJob(formData, resetForm, setSubmitting, closeModal);
        }}
      >
        {(formProps) => {
          const { values, isSubmitting, errors, touched, setFieldValue } =
            formProps;

          return (
            // <Form id="jobapplymodal">
            //    <Modal show={props.jobApply.isModalOpen} size={"lg"} className={`tj-modal ` + (props.darkTheme ? 'is-dark-theme' : '')}  onHide={closeModal} backdrop="static" keyboard={false} centered>
            //     <Modal.Header closeButton className="d-flex">
            //      <Modal.Title className="flex-grow-1">Apply for the job</Modal.Title>
            //       </Modal.Header>
            //       <Modal.Body>
            //         <div className="job-box container" style={{padding: "55px 0px", height: "387px"}}>
            //           <div className="job-description">
            //             {values.requirements_job_apply_description}
            //           </div>
            //           <div className="row my-3">
            //             <div className = "col-md-6 my-2">
            //               <p>I am interested to apply for the above position. Enclosing the CV along with this.</p>
            //             </div>
            //             <div className = "col-md-6 my-2">
            //               <Field className="form-check-input"  type="checkbox"  name="requirements_job_apply_term" checked = { (parseInt(values.requirements_job_apply_term)  === 1) ? true :  false } onChange={() => setFieldValue("requirements_job_apply_term", (values.requirements_job_apply_term) ? 0 : 1 ) } />
            //               <p className='d-inline mx-2'>Accept Terms &amp; Conditions</p>
            //               <span className="error-message mb-1 d-block">{ (errors.requirements_job_apply_term && touched.requirements_job_apply_term) ? errors.requirements_job_apply_term : null }</span>
            //             </div>
            //             <div className="col-md err-msg-height my-2">
            //               <input name="requirements_job_apply_resume" class="form-control form-control-sm" id="formFileSm" type="file" accept="application/*"
            //                   onChange={(event) => {
            //                     setFieldValue("requirements_job_apply_resume", event.currentTarget.files[0]);
            //                   }}
            //                   />
            //                    <span className="error-message mb-1 d-block">{ (errors.requirements_job_apply_resume && touched.requirements_job_apply_resume) ? errors.requirements_job_apply_resume : null }</span>
            //             </div>
            //             {/* <div className="col-md">
            //               <input name="requirements_job_apply_resume" type="file" accept="application/*"
            //                   onChange={(event) => {
            //                     setFieldValue("requirements_job_apply_resume", event.currentTarget.files[0]);
            //                   }}
            //                 />
            //                 <span className="error-message mb-1 d-block">{ (errors.requirements_job_apply_resume && touched.requirements_job_apply_resume) ? errors.requirements_job_apply_resume : null }</span>
            //             </div> */}
            //           </div>

            //         </div>
            //       </Modal.Body>
            //     <Modal.Footer>
            //       <div className="row">
            //           <div className="col button">
            //             <div className="d-flex  m-2 pull-right">
            //                <button type="submit"  form="jobapplymodal" className={`btn btn-block mx-2 btn-${props.activeColor}`} disabled={isSubmitting} >	{isSubmitting ? (
            //                   <FormLoader />
            //                   ) : (
            //                     <span>Apply</span>
            //                   )}
            //                  </button>
            //                 <button type="button" className="btn btn-outline-danger btn-block" onClick={closeModal}>Close</button>
            //             </div>
            //           </div>
            //         </div>

            //     </Modal.Footer>
            //    </Modal>
            // </Form>
            <Form id="jobapplymodal">
              <Modal
                show={props.jobApply.isModalOpen}
                size="lg"
                className={`tj-modal ${props.darkTheme ? "is-dark-theme" : ""}`}
                onHide={closeModal}
                backdrop="static"
                keyboard={false}
                centered
              >
                <Modal.Header closeButton className="d-flex">
                  <Modal.Title className="flex-grow-1">
                    Apply for the Job
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="job-box container py-4">
                    <div className="job-description mb-4">
                      {values.requirements_job_apply_description}
                    </div>
                    <div className="row my-3">
                      <div className="col-md-6 my-2">
                        <p>
                          I am interested in applying for the above position.
                          Enclosing the CV along with this.
                        </p>
                      </div>
                      <div className="col-md-6 my-2 d-flex align-items-center">
                        <label className="form-check-label">
                          <Field
                            className="form-check-input me-2"
                            type="checkbox"
                            name="requirements_job_apply_term"
                            checked={
                              parseInt(values.requirements_job_apply_term) === 1
                            }
                            onChange={() =>
                              setFieldValue(
                                "requirements_job_apply_term",
                                values.requirements_job_apply_term ? 0 : 1,
                              )
                            }
                          />
                          Accept Terms & Conditions
                        </label>
                        <span className="error-message text-danger ms-3">
                          {errors.requirements_job_apply_term &&
                          touched.requirements_job_apply_term
                            ? errors.requirements_job_apply_term
                            : null}
                        </span>
                      </div>
                      <div className="col-md-12 my-2">
                        <div className="input-group">
                          <input
                            name="requirements_job_apply_resume"
                            className="form-control form-control-sm"
                            id="formFileSm"
                            type="file"
                            accept="application/*"
                            onChange={(event) => {
                              setFieldValue(
                                "requirements_job_apply_resume",
                                event.currentTarget.files[0],
                              );
                            }}
                          />
                        </div>
                        <span className="error-message text-danger d-block mt-1">
                          {errors.requirements_job_apply_resume &&
                          touched.requirements_job_apply_resume
                            ? errors.requirements_job_apply_resume
                            : null}
                        </span>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <div className="row w-100">
                    <div className="col d-flex justify-content-end">
                      <button
                        type="submit"
                        form="jobapplymodal"
                        className={`btn btn-${props.activeColor} mx-2`}
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? <FormLoader /> : <span>Apply</span>}
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger"
                        onClick={closeModal}
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </Modal.Footer>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  jobApply: state.job.jobApply,
});
export default connect(mapStateToProps, { setJobApplyData, applyJob })(
  JobApplyModal,
);

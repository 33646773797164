import { useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";

import { deleteRecord, setActiveProfile } from "../../../../actions/profile";

const Trash = (props) => {
  const dispatch = useDispatch();
  const [deleteData, setDeleteData] = useState({
    id: "",
    source: "",
    index: "",
  });
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleClick = () => {
    let newActiveProfile = { ...props.activeProfile };
    let deleteInfo = {
      id: deleteData.id,
      source: deleteData.source,
    };

    if (deleteData.id !== null) {
      props.deleteRecord(deleteInfo);
    }

    switch (deleteData.source) {
      case "experience":
        props.active.splice(deleteData.index, 1);
        newActiveProfile["experience"] = props.active;
        break;
      case "job":
        props.active.splice(deleteData.index, 1);
        newActiveProfile["job_experience"] = props.active;
        break;
      case "qualification":
        props.active.splice(deleteData.index, 1);
        newActiveProfile["qualification"] = props.active;
        break;
      case "certificate":
        props.active.splice(deleteData.index, 1);
        newActiveProfile["certificates"] = props.active;
        break;
      case "award":
        props.active.splice(deleteData.index, 1);
        newActiveProfile["awards"] = props.active;
        break;
      case "skills":
        props.active.splice(deleteData.index, 1);
        newActiveProfile["skills"] = props.active;
        break;
      case "interest":
        props.active.splice(deleteData.index, 1);
        newActiveProfile["interest"] = props.active;
        break;
      case "profile":
        props.active.splice(deleteData.index, 1);
        newActiveProfile["profiles"] = props.active;
        break;
      default:
        break;
    }

    dispatch(props.setActiveProfile(newActiveProfile));
    setDeleteData({ ...deleteData, id: "", source: "", index: "" });
    setShowDeleteModal(false);
  };

  const handleDelete = (id, source, index) => {
    setDeleteData({ ...deleteData, id: id, source: source, index: index });
    setShowDeleteModal(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  return (
    <>
      <FontAwesomeIcon
        icon={faTrash}
        onClick={(e) =>
          props.muted !== true
            ? handleDelete(props.id, props.source, props.index)
            : ""
        }
      />

      {/* delete modal */}
      <Modal
        show={showDeleteModal}
        onHide={closeDeleteModal}
        backdrop="static"
        keyboard={false}
        centered
        className="tj-modal delete-modal "
      >
        <Modal.Header closeButton className="d-flex px-0 mx-2">
          <Modal.Title className="flex-grow-1 text-center h5">
            Are you sure want to delete ?
          </Modal.Title>
        </Modal.Header>
        <div className="text-center px-0 mx-2">
          If you delete this, you won't be able to recover the data.
        </div>
        <Modal.Footer className="px-0 mx-0 pb-2 mb-0 row w-100">
          <div className="d-flex justify-content-center gap-3">
            <button
              type="button"
              className={`btn mb-button mb-button-confirm btn-${props.activeColor}`}
              onClick={handleClick}
            >
              Yes
            </button>

            <button
              type="button"
              className="btn mb-button mb-button-cancel"
              onClick={closeDeleteModal}
            >
              No
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, {
  setActiveProfile,
  deleteRecord,
})(Trash);

import React, { useState, useEffect, Suspense } from "react";

import { connect } from "react-redux";

import Filter from "../Trainers/dashboard/Filter";
import Qboard from "../Trainers/dashboard/qboard/Qboard";
import Requirements from "../Trainers/dashboard/requirements/Requirements";
import { getUser } from "../../utils/UserDetails";
import ProfileService from "../../services/profile.service";
import {
  filterData,
  setModalData,
  setActiveKeyTable,
  resetFilterData,
} from "../../actions/dashboradCommon";
import {
  QboardMenus,
  RequirementMenus,
  TrainingMenus,
} from "../../constants/Menu";
import CommonService from "../../services/common.service";
import { getInterests } from "../../actions/profile";
import TrainingMode from "../Trainers/dashboard/TrainingMode/TrainingMode";

const LoginModal = React.lazy(() => import("./LoginModal"));
const DeleteModal = React.lazy(() =>
  import("../Trainers/dashboard/DeleteModal"),
);
const CommentModal = React.lazy(() =>
  import("../Trainers/dashboard/CommentModal"),
);
const JobModal = React.lazy(() =>
  import("../Trainers/dashboard/requirements/job/JobModal"),
);
const TrainingModal = React.lazy(() =>
  import("../Trainers/dashboard/requirements/training/TrainingModal"),
);

const FeedTabContent = (props) => {
  const currentUser = getUser();
  const [activeTab, setActiveTab] = useState(null);
  const [tabHeaders, setTabHeaders] = useState([]);
  const [postPlaceholder, setPostPlaceholder] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // useEffect(() => {
  //   props.getInterests();
  // }, []);

  useEffect(() => {
    let tabs = QboardMenus;
    if (
      props.activeParentTab &&
      props.activeParentTab.toLowerCase() === "requirements"
    ) {
      tabs = RequirementMenus;
    } else if (
      props.activeParentTab &&
      props.activeParentTab.toLowerCase() === "training"
    ) {
      tabs = TrainingMenus;
    }
    setTabHeaders(tabs);
    setActiveTab(tabs[0].label);
  }, [props.activeParentTab]);

  useEffect(() => {
    if (
      props.activeParentTab &&
      props.activeParentTab.toLowerCase() === "qboard"
    ) {
      let tabFilter = { ...props.mainFilter };
      tabFilter.tab_view = activeTab;
      props.filterData(tabFilter);
      setPostPlaceholder("Post your queries ...");
      props.setActiveKeyTable(1);
    } else {
      if (activeTab === "requirements-jobs") {
        setPostPlaceholder("Post your job requirements ...");
        props.setActiveKeyTable(3);
      } else {
        setPostPlaceholder("Post your training requirements ...");
        props.setActiveKeyTable(2);
      }
      props.resetFilterData();
    }
    if (activeTab == "qboard-myinterest" || activeTab == "qboard-myconnect") {
      handleShow();
      setActiveTab(" qboard-global");
    }
    CommonService.scrollToTop();
  }, [activeTab]);

  const openModal = () => {
    let modalData = { ...props.modalContent };
    if (props.activeParentTab.toLowerCase() === "qboard") {
      props.setModalData({ ...modalData, qboardModal: true, qboardObj: {} });
    } else {
      if (activeTab === "requirements-jobs") {
        props.setModalData({
          ...modalData,
          requirementJobModal: true,
          jobObj: {},
        });
      } else {
        props.setModalData({
          ...modalData,
          requirementTrainingModal: true,
          trainingObj: {},
        });
      }
    }
  };

  const handleShow = () => setShowModal(true);

  const handleClose = () => setShowModal(false);
  console.log(activeTab);
  return (
    <>
      <div className="sticky-center">
        <div className={`post-details-headers ${props.activeColor}`}>
          <div className="qboard-header my-2 align-items-center">
            <ul className="qboard-header2-css p-0">
              {tabHeaders.map((tab) => {
                return (
                  <div
                    key={tab.label}
                    className={
                      activeTab === tab.label
                        ? "font-weight-bold margin-right-css"
                        : "margin-right-css"
                    }
                    onClick={() => setActiveTab(tab.label)}
                  >
                    {tab.name}
                  </div>
                );
              })}
            </ul>
            <div>
              <Filter
                activeParentTab={props.activeParentTab}
                InterestsData={props.allInterests}
              />
            </div>
          </div>
        </div>
        {props.activeParentTab !== "Training" ? (
          <div className="post-query-input d-flex">
            <img
              className="profile-icon rounded-circle "
              src={
                props.activeProfile &&
                props.activeProfile.user_profile_image_url
                  ? props.activeProfile.user_profile_image_url
                  : ProfileService.defaultProfilePic()
              }
              alt="profile-logo"
              onError={(e) => {
                e.target.onerror = null;
                e.target.src =
                  process.env.PUBLIC_URL + "/images/icons/profile1.jpg";
              }}
            />
            <input
              type="text"
              placeholder={postPlaceholder}
              className="form-control primary-bg-color"
              onClick={handleShow}
              readOnly
            />
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="post-details-content">
        <div className="post-queries-data">
          {props.activeParentTab &&
            props.activeParentTab.toLowerCase() === "qboard" && <Qboard />}
          {props.activeParentTab &&
            props.activeParentTab.toLowerCase() === "requirements" && (
              <Suspense fallback={""}>
                <Requirements activeTab={activeTab} />
              </Suspense>
            )}
          {props.activeParentTab &&
            props.activeParentTab.toLowerCase() === "training" && (
              <Suspense fallback={""}>
                <TrainingMode activeTab={activeTab} />
              </Suspense>
            )}
        </div>
      </div>

      {/* modal view */}
      <Suspense fallback={""}>
        <LoginModal show={showModal} handleClose={handleClose} />
      </Suspense>
      <Suspense fallback={""}>
        <CommentModal />
      </Suspense>

      <Suspense fallback={""}>
        <DeleteModal />
      </Suspense>

      <Suspense fallback={""}>
        <TrainingModal />
      </Suspense>

      <Suspense fallback={""}>
        <JobModal />
      </Suspense>
    </>
  );
};
const mapStateToProps = (state) => ({
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
  mainFilter: state.dashboardCommon.mainFilter,
  modalContent: state.dashboardCommon.modalContent,
  allInterests: state.profile.allInterests,
});

export default connect(mapStateToProps, {
  filterData,
  setActiveKeyTable,
  resetFilterData,
  setModalData,
  getInterests,
})(FeedTabContent);

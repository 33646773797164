import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { getUserRole } from '../utils/UserDetails';

function TrainersPrivateRoute1({ component: Component, ...rest }) {

  let redirectLink = '/';

  if (getUserRole() === 'TRAINER') {
    redirectLink = '/trainer/home';
  }
  
  return (
    <Route
      {...rest}
      render={(props) =>
        getUserRole() === 'TRAINER' ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: redirectLink,
            }}
          />
        )
      }
    />
  );
}

export default TrainersPrivateRoute1;

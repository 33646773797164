import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import renderHTML from "react-render-html";

import Trash from "../trash/Trash";
import moment from "moment";

const Certificates = (props) => {
  let descriptionIsVisible = 0;
  let certificateNameIsVisible = 0;
  let institutionIsVisible = 0;
  let validityIsVisible = 0;

  if (props.certificateData.user_certificates_description) {
    descriptionIsVisible = 1;
  }

  if (props.certificateData.user_certificates_name) {
    certificateNameIsVisible = 1;
  }
  if (props.certificateData.user_certificate_validity) {
    validityIsVisible = 1;
  }
  if (props.certificateData.user_certificates_institution_name) {
    institutionIsVisible = 1;
  }

  if (props.editableStatus.status) {
    descriptionIsVisible = 1;
    certificateNameIsVisible = 1;
    institutionIsVisible = 1;
    validityIsVisible = 1;
  }

  const handleBlur = () => {
    console.log(props.certificateData);
    if (
      props.certificateData.user_certificates_name !== null &&
      props.certificateData.user_certificates_institution_name !== null &&
      props.certificateData.user_certificate_validity !== null &&
      props.certificateData.user_certificates_description !== null &&
      props.certificateData.user_certificates_description !== "<p><br></p>"
    ) {
      props.editCertificate(props.index, props.certificateData);
    }
  };

  return (
    <div className={!props.editableStatus.status ? "" : "card mb-4"}>
      {certificateNameIsVisible || institutionIsVisible ? (
        <div className={!props.editableStatus.status ? "" : "card-header p-0"}>
          <div className="col hide-resp profile-hide-view-css">
            {props.editableStatus.status ? (
              <>
                <div
                  className={
                    props.certificateData.user_certificates_show === 1
                      ? "checkbox-container-off"
                      : "checkbox-container"
                  }
                >
                  <input
                    type="checkbox"
                    id={"checkbox-cert-" + props.index}
                    onChange={(e) =>
                      props.showAndHideOption(
                        e.currentTarget.checked,
                        props.certificateData.user_certificates_id,
                        "certificate",
                        props.index,
                      )
                    }
                    name="certificateShowHide"
                    defaultChecked={
                      props.certificateData.user_certificates_show === 1
                        ? false
                        : true
                    }
                  />
                  <label htmlFor={"checkbox-cert" + props.index}>
                    <span
                      className={
                        props.certificateData.user_certificates_show === 1
                          ? "inner-hide-text-position1"
                          : "inner-hide-text-position"
                      }
                    >
                      Hide
                    </span>
                  </label>
                </div>
                {props.activeCertificates.length > 1 ? (
                  <div className="job-trash trash">
                    <Trash
                      id={props.certificateData.user_certificates_id}
                      index={props.index}
                      source="certificate"
                      active={props.activeCertificates}
                    />
                  </div>
                ) : (
                  <div className="job-trash trash trash-muted-css">
                    <Trash muted={true} />
                  </div>
                )}
              </>
            ) : (
              <div className="checkbox-container"></div>
            )}
          </div>
          <div className="d-flex flex-md-wrap flex-sm-wrap flex-column flex-lg-row flex-md-row flex-sm-row gap-1 mx-auto align-items-center">
            {certificateNameIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto width-css ` +
                  (props.editableStatus.status &&
                  !props.certificateData.user_certificates_show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    Certification name{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="text"
                    key={`certiname${props.index}`}
                    title={props.certificateData.user_certificates_name}
                    className={
                      `form-control py-1 my-2 text-center ` +
                      (props.editableStatus.status &&
                      !props.certificateData.user_certificates_show
                        ? "hide-opacity"
                        : "")
                    }
                    name="user_certificates_name"
                    value={
                      props.certificateData.user_certificates_name
                        ? props.certificateData.user_certificates_name
                        : ""
                    }
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.certificateChangeHandler(e, "text", props.index)
                    }
                  />
                ) : (
                  <p className="tab-sub-heading">
                    {props.certificateData.user_certificates_name
                      ? props.certificateData.user_certificates_name
                      : ""}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-4"></div>
            )}

            {institutionIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto width-css ` +
                  (props.editableStatus.status &&
                  !props.certificateData.user_certificates_show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    Institution name{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="text"
                    key={`certiinst${props.index}`}
                    className={
                      `form-control py-1 my-2 text-center ` +
                      (props.editableStatus.status &&
                      !props.certificateData.user_certificates_show
                        ? "hide-opacity"
                        : "")
                    }
                    name="user_certificates_institution_name"
                    title={
                      props.certificateData.user_certificates_institution_name
                    }
                    value={
                      props.certificateData.user_certificates_institution_name
                        ? props.certificateData
                            .user_certificates_institution_name
                        : ""
                    }
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.certificateChangeHandler(e, "text", props.index)
                    }
                  />
                ) : (
                  <p className="">
                    {props.certificateData.user_certificates_institution_name
                      ? props.certificateData.user_certificates_institution_name
                      : ""}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-4 px-4"></div>
            )}

            {validityIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto width-css ` +
                  (props.editableStatus.status &&
                  !props.certificateData.user_certificates_show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    Validity
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="date"
                    className={
                      `form-control py-1 my-2 text-center date-width date ` +
                      (props.editableStatus.status &&
                      !props.certificateData.user_certificates_show
                        ? "hide-opacity"
                        : "")
                    }
                    name="user_certificate_validity"
                    title={props.certificateData.user_certificate_validity}
                    value={
                      props.certificateData.user_certificate_validity
                        ? props.certificateData.user_certificate_validity
                        : ""
                    }
                    onChange={(e) =>
                      props.certificateChangeHandler(e, "text", props.index)
                    }
                    onBlur={() => handleBlur()}
                  />
                ) : (
                  <p className="sub-tab-date">
                    {" "}
                    {props.certificateData.user_certificate_validity !== null
                      ? moment(
                          props.certificateData.user_certificate_validity,
                        ).format("MMM-YYYY")
                      : ""}{" "}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-2 px-2"></div>
            )}
          </div>
        </div>
      ) : (
        "No certificate details found"
      )}

      {/* description section */}
      <div>
        {descriptionIsVisible ? (
          <div className="card-body p-1 desc-resp">
            <div className="row">
              <div className="col-md-12">
                {props.editableStatus.status ? (
                  <ReactQuill
                    className={
                      props.editableStatus.status &&
                      !props.certificateData.user_certificates_show
                        ? "hide-opacity"
                        : ""
                    }
                    key={`certidesc${props.index}`}
                    name="user_certificates_description"
                    modules={props.modules}
                    formats={props.formats}
                    value={props.certificateData.user_certificates_description}
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.certificateChangeHandler(e, "editor", props.index)
                    }
                  />
                ) : (
                  <p className="">
                    {props.certificateData.user_certificates_description
                      ? renderHTML(
                          props.certificateData.user_certificates_description,
                        )
                      : ""}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Certificates;

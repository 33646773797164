import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";

import { Button, Modal, Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";

import useDidMountEffect from "../../../../components/DidMount";
import Spinner from "../../../../components/spinner/CommanSpinner";
import { addSession, deleteSession } from "../../../../actions/training";
import { event } from "jquery";

const SessionModal = (props) => {
  const inputRef = useRef([]);
  const sessionObj = {
    session_id: null,
    session_name: null,
    session_number: null,
  };
  const [sessionData, setSessionData] = useState([]);
  const [sessionDeleteAlert, setSessionDeleteAlert] = useState({
    show: false,
    sessionId: null,
    index: null,
  });
  const [showSpinner, setShowSpinner] = useState(false);
  const [staticData, setStaticData] = useState([]);

  useEffect(() => {
    if (props.sessionData.length) {
      let data = props.sessionData[0];
      setSessionData(data.sessions);
    } else {
      setSessionData([]);
    }
  }, [props.sessionData.length]);

  useDidMountEffect(() => {
    setShowSpinner(false);
    setStaticData([]);
  }, [props.session]);

  const generateSessionGrid = (event) => {
    if (event.target.value !== null) {
      let temp = [...sessionData];
      let count = event.target.value - temp.length;

      if (count >= 1) {
        for (let i = 0; i < count; i++) {
          let tempObj = { ...sessionObj };
          tempObj.key = i;
          temp.push(tempObj);
        }
      } else {
        for (let i = 1; i > 0; i--) {
          let tempObj = { ...sessionObj };
          tempObj.key = i;
          temp.pop(tempObj);
        }
      }

      setSessionData(temp);
    }
  };

  const handleSessionChange = (event, index) => {
    let newSession = [...sessionData];

    newSession[index][event.target.name] = event.target.value;
    newSession[index][inputRef.current[index]?.name] =
      inputRef.current[index]?.value;
    newSession[index]["course_id"] = props.courseId[0].id;

    setSessionData(newSession);
  };

  const submitSession = (e) => {
    setShowSpinner(true);
    const filteredArray = staticData.filter((item) => item !== undefined);
    let param = [...sessionData, ...filteredArray];
    let sessionInfo = {
      courseId: props.courseId[0].id,
      data: param,
    };
    setSessionData(param);
    props.addSession(sessionInfo);
  };

  const deleteSession = (index, id) => {
    setSessionDeleteAlert({
      ...sessionDeleteAlert,
      show: true,
      sessionId: id,
      index: index,
    });
  };

  const confirmDeleteSession = () => {
    let temp = [...sessionData];
    temp.splice(sessionDeleteAlert.index, 1);
    setSessionData(temp);
    setSessionDeleteAlert({
      ...sessionDeleteAlert,
      show: false,
      sessionId: null,
      index: null,
    });

    if (sessionDeleteAlert.sessionId !== null) {
      let sessionInfo = {
        courseId: props.courseId[0].id,
        sessionId: sessionDeleteAlert.sessionId,
      };
      props.deleteSession(sessionInfo);
    }
  };

  const closeSessionDeleteAlert = () => {
    setSessionDeleteAlert({
      ...sessionDeleteAlert,
      show: false,
      sessionId: null,
      index: null,
    });
  };

  const handleSession = (event, index) => {
    let newSession = [...staticData];
    if (!staticData[index]) {
      newSession[index] = {
        session_id: null,
        session_name: "",
        session_number: null,
        key: index,
        course_id: props.courseId[0].id,
      };
    }
    newSession[index][event.target.name] = event.target.value;
    newSession[index][inputRef.current[index]?.name] =
      inputRef.current[index]?.value;
    newSession[index]["course_id"] = props.courseId[0].id;

    setStaticData(newSession);
  };
  const handleClick = () => {
    let newSession = [...sessionData];
    let i = 0;
    const temp = {
      session_id: null,
      session_name: null,
      session_number: null,
    };
    temp.key = sessionData?.length ? sessionData?.length + 5 : i + 6;
    if (!sessionData?.length) {
      i++;
    }
    newSession.push(temp);

    setSessionData(newSession);
  };

  return (
    <>
      <Modal
        show={props.showSessionModal}
        onHide={props.closeSessionModal}
        backdrop="static"
        keyboard={false}
        centered
        className="tj-modal"
        size="lg"
      >
        <Modal.Header
          closeButton
          className={`d-flex mb-2  mt-3`}
          style={{ height: "50px" }}
        >
          <Modal.Title className="flex-grow-1 ">Add Session</Modal.Title>
        </Modal.Header>

        <Modal.Body st yle={{ height: "350px" }}>
          <div className="mx-2">
            <div className="row mb-2">
              {/* <div className="col-md-3">
                <label>Number of sessions</label>
              </div>
              <div className="col-md-2">
                <input
                  type="number"
                  className="form-control form-control-sm"
                  onChange={(e) => generateSessionGrid(e)}
                  defaultValue={sessionData.length}
                />
              </div> */}
              <div className="d-flex justify-content-end mb-2">
                <FontAwesomeIcon
                  icon={faPlus}
                  className="icon-color mx-2 "
                  onClick={handleClick}
                  role="button"
                  style={{ fontSize: "18px" }}
                />
                {/* <button
                  type="button"
                  className="btn btn-outline-primary mx-2 btn-sm"
                  onClick={handleClick}
                >
                  Add
                </button> */}
              </div>
            </div>
          </div>
          <div className="m-2">
            <Table
              striped
              bordered
              hover
              size="md"
              responsive
              style={{ height: "auto" }}
            >
              <thead className="thead-border">
                <tr>
                  <th
                    className="text-center"
                    style={{ width: "20%", backgroundColor: "#babfc7" }}
                  >
                    Session No
                  </th>
                  <th
                    className="text-center"
                    style={{ backgroundColor: "#babfc7" }}
                  >
                    Session Name
                  </th>
                  <th
                    className="text-center"
                    style={{ width: "10%", backgroundColor: "#babfc7" }}
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="tbody-overflow">
                <tr>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm text-center"
                      placeholder="Session number"
                      // ref={(el) =>
                      //   (inputRef.current[
                      //     sessionData?.length ? sessionData?.length + 1 : 0
                      //   ] = el)
                      // }
                      name="session_number"
                      onChange={(e) =>
                        handleSession(
                          e,
                          sessionData?.length ? sessionData?.length + 1 : 0,
                        )
                      }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Session name"
                      name="session_name"
                      // defaultValue={sessionData[0]?.session_name}
                      onChange={(e) =>
                        handleSession(
                          e,
                          sessionData?.length ? sessionData?.length + 1 : 0,
                        )
                      }
                    />
                  </td>
                  <td></td>
                </tr>
                {/* <tr>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm text-center"
                      placeholder="2"
                      name="session_number"
                      // ref={(el) =>
                      //   (inputRef.current[
                      //     sessionData?.length ? sessionData?.length + 2 : 1
                      //   ] = el)
                      // }
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Session name"
                      name="session_name"
                      // defaultValue={sessionData[1]?.session_name}
                      onChange={(e) =>
                        handleSession(
                          e,
                          sessionData?.length ? sessionData?.length + 2 : 1,
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm text-center"
                      placeholder="3"
                      name="session_number"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Session name"
                      name="session_name"
                      // defaultValue={sessionData[2]?.session_name}
                      onChange={(e) =>
                        handleSession(
                          e,
                          sessionData?.length ? sessionData?.length + 3 : 2,
                        )
                      }
                    />
                  </td>
                </tr> */}
                {/* <tr>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm text-center"
                      placeholder="4"
                      name="session_number"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Session name"
                      name="session_name"
                      // defaultValue={sessionData[3]?.session_name}
                      onChange={(e) =>
                        handleSession(
                          e,
                          sessionData?.length ? sessionData?.length + 4 : 3,
                        )
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm text-center"
                      placeholder="5"
                      name="session_number"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Session name"
                      name="session_name"
                      onChange={(e) =>
                        handleSession(
                          e,
                          sessionData?.length ? sessionData?.length + 5 : 4,
                        )
                      }
                    />
                  </td>
                </tr> */}
                {sessionData?.map((item, k) => (
                  <tr>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm text-center"
                        placeholder="Session number"
                        name="session_number"
                        ref={(el) => (inputRef.current[k] = el)}
                        value={item?.session_number}
                        onChange={(e) => handleSessionChange(e, k)}
                        key={k}
                      />
                    </td>
                    <td>
                      <input
                        type="text"
                        className="form-control form-control-sm"
                        placeholder="Session name"
                        name="session_name"
                        onChange={(e) => handleSessionChange(e, k)}
                        value={item?.session_name}
                        key={k}
                      />
                    </td>
                    <td className="text-center align-middle">
                      <div className="">
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="batch-delete"
                          role="button"
                          onClick={(e) => deleteSession(k, item.session_id)}
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-outline-primary mx-2 btn-sm"
            onClick={(e) => submitSession(e)}
          >
            {showSpinner ? <Spinner /> : "Submit"}
          </button>
        </Modal.Footer>
      </Modal>

      {/* delete session alert modal */}
      <Modal
        show={sessionDeleteAlert.show}
        onHide={closeSessionDeleteAlert}
        backdrop="static"
        keyboard={false}
        centered
        className="tj-modal"
      >
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1 text-center">
            Delete confirmation
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className="text-center">Are you sure want to delete session ?</p>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <button
            type="button"
            className="btn btn-success mx-2"
            onClick={confirmDeleteSession}
          >
            Yes
          </button>
          <button
            type="button"
            className="btn btn-outline-danger"
            onClick={closeSessionDeleteAlert}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  session: state.training.session,
});

export default connect(mapStateToProps, { addSession, deleteSession })(
  SessionModal,
);

import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { Modal, Overlay } from "react-bootstrap";

const SessionGrid = (props) => {
  const [allSessions, setAllSessions] = useState([]);

  const [columnDefs] = useState([
    {
      field: "Session Number",
      flex: 1,
      // cellStyle: { textAlign: "center" },
      // headerStyle: { textAlign: "center" },
      // cellClass: "grid-cell-centered",
    },
    { field: "Session Name", flex: 1 },
  ]);

  useEffect(() => {
    let temp = [];
    if (props.viewData.length) {
      props.viewData[0].sessions.map((session) => {
        let obj = {};
        obj["Session Number"] = session.session_number;
        obj["Session Name"] = session.session_name;
        temp.push(obj);
      });
      setAllSessions(temp);
    }
  }, [props.viewData]);

  return (
    <>
      <Modal
        show={props.showViewSessions}
        onHide={props.closeSessionViewModal}
        backdrop="static"
        keyboard={false}
        centered
        className="tj-modal"
        size="lg"
      >
        <Modal.Header
          closeButton
          className={`d-flex mb-2 mt-3`}
          style={{ height: "50px" }}
        >
          <Modal.Title className="flex-grow-1">Sessions</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row  m-2">
            <div className="col-md-3">
              <label>
                <b>Course Name:</b>
              </label>
            </div>
            <div className="col-md-3">
              <label>
                {" "}
                {props.viewData.length
                  ? props.viewData[0].course_name
                  : ""}{" "}
              </label>
            </div>
            {/* <div className="col-md-3">
              <label>
                <b>Batch Name:</b>
              </label>
            </div>
            <div className="col-md-3">
              <label>
                {props.viewData.length
                  ? props.viewData[0].course_batchname
                  : ""}
              </label>
            </div> */}
          </div>

          <div className="ag-theme-alpine mt-3 mb-4  " style={{ height: 300 }}>
            <style>
              {`
          .ag-header-cell {
            margin-left: 20px;
          }
          .ag-cell {
            margin-left: 20px;
          }
        `}
            </style>
            <AgGridReact
              rowData={allSessions}
              columnDefs={columnDefs}
              suppressHorizontalScroll={true}
            ></AgGridReact>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(SessionGrid);

import { useState, useEffect } from "react";

import { connect } from "react-redux";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroller";

import JobApplyModal from "./JobApplyModal";
import JobApplyDetailsModal from "./JobApplyDetailsModal";
import PostCardHeader from "../../postcard/PostCardHeader";
import PostCardFooter from "../../postcard/PostCardFooter";
import PostCardBody from "../../postcard/PostCardBody";
import CommentView from "../../commentview/CommentView";
import {
  getJobPost,
  insertJobPost,
  setJobError,
  setJobSuccess,
  updateJobPost,
} from "../../../../../actions/job";
import { setModalData } from "../../../../../actions/dashboradCommon";
import Axios from "../../../../../config/axios";

toast.configure();

const Jobs = (props) => {
  const isFrom = "jobs";
  const [isLoading, setIsLoading] = useState(false);
  const [hasMoreItems, setHasMoreItems] = useState(true);

  useEffect(() => {
    if (props.broadcastingEcho) {
      props.broadcastingEcho
        .private("requirement-job-chat")
        .listen("RequirementJobEvent", (e) => {
          let postdata = e.postdata;
          if (postdata.isUpdate) {
            props.updateJobPost(postdata);
          } else {
            props.insertJobPost(postdata);
          }
        });

      setTimeout(function () {
        Axios.interceptors.request.use(function (config) {
          config.headers["X-Socket-ID"] = props.broadcastingEcho?.socketId()
            ? props.broadcastingEcho.socketId()
            : "";
          return config;
        });
      }, 2000);
    }
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getJobPost(1);
  }, [props.mainFilter]);

  useEffect(() => {
    if (props.jobSuccess) {
      toast.success(props.jobSuccess);
      props.setJobSuccess("");
    }
  }, [props.jobSuccess]);

  useEffect(() => {
    if (props.jobError) {
      toast.error(props.jobError);
      props.setJobError("");
    }
  }, [props.jobError]);

  useEffect(() => {
    if (
      props?.jobPosts &&
      props.jobPosts.length &&
      props.jobPosts.length === props.jobPostCount
    ) {
      setHasMoreItems(false);
    }
  }, [props.jobPosts]);

  const getJobPost = (isFromFilter = 0, skip = 0) => {
    let jobRequestData = { ...props.mainFilter };
    jobRequestData.skip = skip;
    props.getJobPost(jobRequestData, setIsLoading, isFromFilter);
  };

  const openModal = (jobObj = {}) => {
    let modalContent = { ...props.modalContent };
    props.setModalData({
      ...modalContent,
      requirementJobModal: true,
      jobObj: { ...jobObj },
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={50}
          width={50}
          className="loader text-center"
        />
      ) : (
        <>
          {props.jobPosts && props.jobPosts.length ? (
            <InfiniteScroll
              pageStart={0}
              loadMore={() =>
                getJobPost(
                  0,
                  props.jobPosts && props.jobPosts.length
                    ? props.jobPosts.length
                    : 0,
                )
              }
              hasMore={hasMoreItems}
              loader={
                <Loader
                  type="ThreeDots"
                  color="#00BFFF"
                  height={50}
                  width={50}
                  className="loader text-center"
                />
              }
              initialLoad={false}
            >
              {props.jobPosts.map((post, postKey) => {
                return (
                  <>
                    <div className="position-relative mb-2">
                      <div>
                        <div className="post-card">
                          <PostCardHeader
                            post={post}
                            key={postKey}
                            index={postKey}
                            openModal={openModal}
                            isFrom={isFrom}
                          />
                          <PostCardBody
                            post={post}
                            key={postKey}
                            index={postKey}
                            isFrom={isFrom}
                          />
                          {/* <PostCardFooter 
                            post = {post}
                            key = {postKey}
                            index = {postKey}
                            isFrom = {isFrom}
                          /> */}
                          {post.common_comments.length && post.showComment ? (
                            <div className="post-comment-view mb-1">
                              {post.common_comments.map((cmts, postKeys) => {
                                return (
                                  <CommentView post={cmts} key={postKeys} />
                                );
                              })}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    {/* comment view  */}
                  </>
                );
              })}
            </InfiniteScroll>
          ) : (
            <p className="text-center no-data-found my-2"> No Data Found</p>
          )}
        </>
      )}

      <JobApplyModal />
      <JobApplyDetailsModal />
    </>
  );
};

const mapStateToProps = (state) => ({
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
  jobPosts: state.job.jobPost,
  jobPostCount: state.job.jobPostCount,
  modalContent: state.dashboardCommon.modalContent,
  mainFilter: state.dashboardCommon.mainFilter,
  broadcastingEcho: state.common.broadcastingEcho,
  jobSuccess: state.job.jobSuccess,
  jobError: state.job.jobError,
});

export default connect(mapStateToProps, {
  getJobPost,
  setModalData,
  insertJobPost,
  updateJobPost,
  setJobError,
  setJobSuccess,
})(Jobs);

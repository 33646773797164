import { Modal } from 'react-bootstrap';

const BatchAlertModal = (props) => {
  return (
    <Modal show={props.showBatchAlert} onHide={props.closeBatchAlert} backdrop="static" keyboard={false} centered className='tj-modal delete-modal'>
      <Modal.Header closeButton className="d-flex">
        <Modal.Title className="text-center">Alert</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5 className='text-center'>Click assign batch button to edit batch data</h5>
      </Modal.Body>
    </Modal>
  )
}

export default BatchAlertModal;
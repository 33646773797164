import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { connect } from 'react-redux';

import Loader from 'react-loader-spinner';

import { getCurrencies } from '../../../../actions/training';

const Currency = forwardRef((props, ref) => {
  const [allCurrencies, setCurrencies] = useState([]);
  const refInput = useRef(null);
  const [value, setValue] = useState(null);

  useEffect(() => {
    props.getCurrencies();
  }, [])

  useEffect(() => {
    if (props.currencies && props.currencies.length) {
      setCurrencies(props.currencies);
    }
  }, [props.currencies])

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },

      myCustomFunction() {
        return {
          rowIndex: props.rowIndex,
          colId: props.column.getId()
        };
      }
    };
  });

  return (
    <>
      {(allCurrencies.length) ?
        <select className="form-control" ref={refInput} value={value} onChange={event => setValue(event.target.value)}>
          {allCurrencies.map((currency) => (
            <option value={currency.currency_name}>{currency.currency_name}</option>
          ))}
        </select> : <Loader type="TailSpin" color="#00BFFF" height={25} width={25} className="loader" />
      }
    </>
  )
})

const mapStateToProps = (state) => ({
  currencies: state.training.currencies
});

export default connect(mapStateToProps, { getCurrencies }, null, { forwardRef: true })(Currency);
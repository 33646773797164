import { useState, useEffect } from "react";

import Select from "react-select";
import { connect } from "react-redux";

import { getUser } from "../../../utils/UserDetails";
import { filterData } from "../../../actions/dashboradCommon";
import { DropdownTreeSelect } from "../../../components/InterestDropdown";

const Filter = (props) => {
  const currentUser = getUser();
  const starClass = currentUser
    ? props.mainFilter && props.mainFilter.star_filter
      ? "fas fa fa-star fa-1x highlight-star"
      : "fas fa fa-star fa-1x"
    : "fas fa fa-star fa-1x invisible";
  const colourStyles = {
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#999999" : null,
      };
    },
  };

  const [interestData, setInterestData] = useState([]);
  const [selectedNodeKey, setSelectedNodeKey] = useState(null);

  useEffect(() => {
    filterSelect();
  }, [props.activeParentTab]);

  useEffect(() => {
    if (props.InterestsData) {
      let allInterestsData = props.InterestsData;
      allInterestsData = allInterestsData?.map((item) => {
        return {
          key: item.interests_id,
          value: item.interests_id,
          label: item?.interests_name,
          children: item.children?.map((item1) => {
            return {
              key: item1.interests_id,
              value: item1.interests_id,
              label: item1.interests_name,
            };
          }),
        };
      });
      let data = {
        key: "",
        value: "",
        label: "Filter",
      };
      setInterestData([data, ...allInterestsData]);
    }
  }, [props.InterestsData]);

  const filterSelect = (data) => {
    setSelectedNodeKey(data?.value);
    setfilterData(data?.value, 1);
  };

  const setfilterData = (value = "", isFromFilter = 0) => {
    let filterData = {};
    if (isFromFilter) {
      filterData.category_id = value;
    } else {
      filterData.star_filter = props.mainFilter.star_filter ? 0 : 1;
    }
    props.filterData(filterData);
  };

  return (
    <div
      // className="filter-data col-md-4 col-8 my-1 d-flex pe-3 gap-1"
      className="d-flex gap-2 my-1"
    >
      <div>
        {/* <Select
          className="category-select w-100"
          onChange={filterSelect}
          options={props.categories}
          value={categoryFilter}
          placeholder={"Filter"}
          styles={colourStyles}
        /> */}
        <DropdownTreeSelect
          value={selectedNodeKey}
          onChange={filterSelect}
          options={currentUser ? interestData : ""}
          placeholder="Filter"
          className="filter-category-css"
        />
      </div>
      <div className="m-auto">
        <i className={starClass} onClick={() => setfilterData()}></i>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  categories: state.common.categories,
  mainFilter: state.dashboardCommon.mainFilter,
});

export default connect(mapStateToProps, { filterData })(Filter);

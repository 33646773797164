import React from 'react'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';

const CarouselData = (props) => {
  return (
    <div className="card text-center">
      <div className="card-body">
          <h5 className="card-title" style={{ color: `${props.item.footerBgColor}`}}><FontAwesomeIcon icon={faQuoteRight} /></h5>
          <p className="card-text">
            Lorem Ipsum Dolor Sit Amet, Consetetur 
            Sadipscing Elitr, Sed Diam Nonumy Eirmod 
            Tempor Invidunt Ut Labore Et
        </p>
      <img src={props.item.profilePic} alt="stud-pic" />
      </div>
      <div className="card-footer" style={{ backgroundColor: `${props.item.footerBgColor}`}}>
        {props.item.footerData}
      </div>
  </div>
  )
}

export default CarouselData

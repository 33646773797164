import React, { Suspense } from "react";
import { withRouter, Switch, Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
import { getUserMenus, getUserRole } from "../../utils/UserDetails";
import TrainersPrivateRoute from "../../routers/TrainersPrivateRoute";
import Dashboard from "./dashboard/Dashboard";
import Profile from "./profile/Profile";
import Training from "./training/Training";
import Preview from "./profile/preview/Preview";
import PrimaryProfile from "./profile/PrimaryProfile";
import TrainersPrivateRoute1 from "../../routers/TrainersPrivateRoute1";

const Trainer = () => {
  return (
    <div className="dashboard-wrapper">
      <Suspense fallback={<div className="loading" />}>
        <Switch>
          {getUserRole() === "TRAINER" ? (
            <>
              <Switch>
                <TrainersPrivateRoute1
                  exact
                  path={"/trainer/home"}
                  component={Dashboard}
                />
                {getUserMenus()?.some(
                  (menu) =>
                    menu.functionality?.functionality_master_name === "Profile",
                ) ? (
                  <TrainersPrivateRoute1
                    path="/trainer/profile"
                    component={Profile}
                    exact={true}
                  />
                ) : (
                  ""
                )}
                {getUserMenus()?.some(
                  (menu) =>
                    menu.functionality?.functionality_master_name ===
                    "Manage My Trainings",
                ) ? (
                  <TrainersPrivateRoute1
                    path="/trainer/training"
                    component={Training}
                  />
                ) : null}
                <TrainersPrivateRoute1
                  path="/contact/admin"
                  component={Training}
                  exact={true}
                />
                {getUserMenus()?.some(
                  (menu) =>
                    menu.functionality?.functionality_master_name === "Profile",
                ) ? (
                  <TrainersPrivateRoute1
                    path="/trainer/preview/:id"
                    component={Preview}
                    exact={true}
                  />
                ) : null}
                {getUserMenus()?.some(
                  (menu) =>
                    menu.functionality?.functionality_master_name === "Profile",
                ) ? (
                  <TrainersPrivateRoute1
                    path="/trainer/primary-profile-view"
                    component={PrimaryProfile}
                    exact
                  />
                ) : null}
                <Redirect to="/trainer/home" />
              </Switch>
            </>
          ) : (
            <Redirect to="/error" />
          )}
        </Switch>
      </Suspense>
    </div>
  );
};

const mapStateToProps = () => {
  //
};

export default Trainer;

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as moment from "moment";
import renderHTML from "react-render-html";

import Trash from "../trash/Trash";

const TrainingExperience = (props) => {
  let descriptionIsVisible = 0;
  let designationIsVisible = 0;
  let fromIsVisible = 0;
  let toIsVisible = 0;

  if (props.trainingData.user_experience_description) {
    descriptionIsVisible = 1;
  }

  if (props.trainingData.userexperience_designation) {
    designationIsVisible = 1;
  }

  if (props.trainingData.userexperience_from) {
    fromIsVisible = 1;
  }

  if (props.trainingData.userexperience_to) {
    toIsVisible = 1;
  }

  if (props.editableStatus.status) {
    descriptionIsVisible = 1;
    designationIsVisible = 1;
    fromIsVisible = 1;
    toIsVisible = 1;
  }

  const handleBlur = () => {
    if (
      props.trainingData.userexperience_designation !== null &&
      props.trainingData.userexperience_from !== null &&
      props.trainingData.userexperience_to !== null &&
      props.trainingData.user_experience_description
    ) {
      props.editExperienceDesignation(props.index, props.trainingData);
    }
  };

  return (
    <div className={!props.editableStatus.status ? "" : "card mb-4 "}>
      {designationIsVisible || fromIsVisible || toIsVisible ? (
        <div className={!props.editableStatus.status ? "" : "card-header p-0"}>
          <div className="col hide-resp profile-hide-view-css">
            {props.editableStatus.status ? (
              <>
                <div
                  className={
                    props.trainingData.userexperience_show === 1
                      ? "checkbox-container-off"
                      : "checkbox-container"
                  }
                >
                  <input
                    type="checkbox"
                    id={"checkbox-training-" + props.index}
                    onChange={(e) =>
                      props.showAndHideOption(
                        e.currentTarget.checked,
                        props.trainingData.userexperience_id,
                        "texperience",
                        props.index,
                      )
                    }
                    name="trainingExperienceShowHide"
                    defaultChecked={
                      props.trainingData.userexperience_show === 1
                        ? false
                        : true
                    }
                  />
                  <label htmlFor={"checkbox-training-" + props.index}>
                    <span
                      className={
                        props.trainingData.userexperience_show === 1
                          ? "inner-hide-text-position1"
                          : "inner-hide-text-position"
                      }
                    >
                      Hide
                    </span>
                  </label>
                </div>
                {props.activeExperience.length > 1 ?
                  <div className="training-trash trash">
                    <Trash
                      id={props.trainingData.userexperience_id}
                      index={props.index}
                      source="experience"
                      active={props.activeExperience}
                    />
                  </div>
                  :
                  <div className="training-trash trash trash-muted-css">
                    <Trash
                      muted={true}
                    />
                  </div>
                }
              </>
            ) : (
              <div className="checkbox-container"></div>
            )}
          </div>
          <div className="d-flex flex-column flex-lg-row flex-md-row flex-sm-row gap-1 mx-auto align-items-center">
            {/* <div className="col flex-wrap"> */}
            {designationIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill w-100 mx-auto` +
                  (props.editableStatus.status &&
                    !props.trainingData.userexperience_show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    Experience{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="text"
                    key={`expdesg${props.index}`}
                    className={
                      `form-control py-1 my-2 text-center ` +
                      (props.editableStatus.status &&
                        !props.trainingData.userexperience_show
                        ? "hide-opacity"
                        : "")
                    }
                    name="userexperience_designation"
                    value={
                      props.trainingData.userexperience_designation
                        ? props.trainingData.userexperience_designation
                        : ""
                    }
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.experienceChangeHandler(e, "text", props.index)
                    }
                  />
                ) : (
                  <p className="tab-sub-heading">
                    {props.trainingData.userexperience_designation
                      ? props.trainingData.userexperience_designation
                      : ""}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-4"></div>
            )}

            {fromIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill w-100 mx-auto` +
                  (props.editableStatus.status &&
                    !props.trainingData.userexperience_show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    From{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="date"
                    className={
                      `form-control py-1 my-2 text-center date ` +
                      (props.editableStatus.status &&
                        !props.trainingData.userexperience_show
                        ? "hide-opacity"
                        : "")
                    }
                    name="userexperience_from"
                    value={
                      props.trainingData.userexperience_from
                        ? props.trainingData.userexperience_from
                        : ""
                    }
                    onChange={(e) =>
                      props.experienceChangeHandler(e, "text", props.index)
                    }
                    onBlur={() => handleBlur()}
                  />
                ) : (
                  <p className="sub-tab-date">
                    {" "}
                    {props.trainingData.userexperience_from !== null
                      ? moment(props.trainingData.userexperience_from).format(
                        "MMM-YYYY",
                      )
                      : ""}{" "}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-2 px-4"></div>
            )}

            {toIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill w-100 mx-auto` +
                  (props.editableStatus.status &&
                    !props.trainingData.userexperience_show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    To{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="date"
                    className={
                      `form-control py-1 my-2 text-center date ` +
                      (props.editableStatus.status &&
                        !props.trainingData.userexperience_show
                        ? "hide-opacity"
                        : "")
                    }
                    name="userexperience_to"
                    value={
                      props.trainingData.userexperience_to
                        ? props.trainingData.userexperience_to
                        : ""
                    }
                    onChange={(e) =>
                      props.experienceChangeHandler(e, "text", props.index)
                    }
                    onBlur={() => handleBlur()}
                  />
                ) : (
                  <p className="sub-tab-date">
                    {" "}
                    {props.trainingData.userexperience_to !== null
                      ? moment(props.trainingData.userexperience_to).format(
                        "MMM-YYYY",
                      )
                      : ""}{" "}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-2 px-4"></div>
            )}
            {/* </div> */}

          </div>
        </div>
      ) : (
        "No experience details found"
      )}

      {/* description section */}
      <div>
        {descriptionIsVisible ? (
          <div className="card-body p-1 desc-resp">
            <div className="row">
              <div className="col-md-12">
                {props.editableStatus.status ? (
                  <ReactQuill
                    className={
                      props.editableStatus.status &&
                        !props.trainingData.userexperience_show
                        ? "hide-opacity"
                        : ""
                    }
                    key={`expdesc${props.index}`}
                    name="user_experience_description"
                    modules={props.modules}
                    formats={props.formats}
                    value={props.trainingData.user_experience_description}
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.experienceChangeHandler(e, "editor", props.index)
                    }
                  />
                ) : (
                  <p className="">
                    {props.trainingData.user_experience_description
                      ? renderHTML(
                        props.trainingData.user_experience_description,
                      )
                      : ""}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <span className="profile-card-border-bottom"></span>
    </div>
  );
};

export default TrainingExperience;

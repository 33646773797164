import React, { useState, useEffect } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import { connect } from "react-redux";
import {
  setApplyDetails,
  AppliedDetails,
  RecruiterAppliedDetails,
  downloadResume,
} from "../../../../actions/reqTraining";
import { getUserRole } from "../../../../utils/UserDetails";
import CommanSpinner from "../../../../components/spinner/CommanSpinner";
import { getDate } from "../../../../constants/DefaultValues";

const TrainingApplyDetailsModal = (props) => {

  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props.trainingApplyDetails.isModalOpen) {
      if (getUserRole() == "STUDENT" || getUserRole() == "TRAINER") {
        setLoading(true);
        props.AppliedDetails({ type: activeTab }, setLoading);
      } else if (getUserRole() == "RECRUITER") {
        setLoading(true);
        props.RecruiterAppliedDetails({ type: activeTab }, setLoading);
      }
    }
  }, [activeTab]);

  const closeModal = () => {
    setActiveTab("1");
    let trainingApplyData = {
      ...props?.trainingApplyDetails?.appliedDetailsData,
    };
    props.setApplyDetails({ ...trainingApplyData, isModalOpen: false });
  };

  const downloadFile = (data) => {
   console.log(data)
  };

  const renderTableBody = (tabTittle) => {
    // const data =
    //   activeTab === "1"
    //     ? props.trainingApplyDetails.appliedDetailsData
    //     : props.trainingApplyDetails.postedDetailsData;

    const data = props.trainingApplyDetails.appliedDetailsData;

    return !loading ? (
      (getUserRole() == "STUDENT" || getUserRole() == "TRAINER") ?
        (
          tabTittle === 'Job' ?
            (
              data?.length > 0 ? (
                data.map((details, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center" style={{
                      maxWidth: '150px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                      {details.requirements_job_apply_description}{" "}
                    </td>
                    <td className="text-center">{details?.requirement?.requirements_company_details}</td>
                    <td className="text-center">{details?.requirement?.requirements_contact_person}</td>
                    <td className="text-center">{getDate(details.created_at)}</td>
                    <td className="text-center">{details?.requirement?.requirements_job_location}</td>
                  </tr>
                ))
              ) :
                <tr>
                  <td colSpan="5" className="text-center ">
                    No records Found
                  </td>
                </tr>
            )
            :
            (
              data?.length > 0 ? (
                data.map((details, index) => (
                  <tr key={index}>
                    <td className="text-center">{index + 1}</td>
                    <td className="text-center"  style={{
                      maxWidth: '150px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}>
                      {details.requirements_training_apply_description}{" "}
                    </td>
                    <td className="text-center">{details?.training?.requirement_user_profile_name}</td>
                    <td className="text-center">{getDate(details?.requirements_training_apply_startdate)}</td>
                    <td className="text-center">{getDate(details?.requirements_training_apply_enddate)}</td>
                    <td className="text-center">{details?.requirements_training_apply_no_of_days}</td>
                    <td className="text-center">{details?.training?.requirements_training_hours_per_day}</td>
                    <td className="text-center">
                      {details?.training?.requirements_training_cost !== null ? details?.training?.requirements_training_cost + '(' + details?.training?.requirements_training_cost_currency + ')' : '-'}
                    </td>
                    <td className="text-center">{getDate(details.created_at)}</td>
                  </tr>
                ))
              ) :
                <tr>
                  <td colSpan="5" className="text-center ">
                    No records Found
                  </td>
                </tr>
            ))
        :
        (getUserRole() == "RECRUITER") &&
          tabTittle === 'Job' ?
          (
            (data?.[0]?.applied_job?.length > 0) ? (
              data?.[0]?.applied_job.map((details, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-left">
                    {details.user.first_name + ' ' + details.user.last_name}
                  </td>
                  <td className="text-left">
                    {details.user.email}
                  </td>
                  <td className="text-center">{(details.user.user_role)?.charAt(0).toUpperCase() + (details.user.user_role)?.slice(1).toLowerCase()}</td>
                  <td className="text-center" style={{color:"#007bff", cursor:'pointer'}}  onClick={(e) => {
                            const params = {
                              job_applied_id:details.requirements_job_apply_id
                            };
                            props.downloadResume(params);
                          }}>{details.requirements_job_apply_resume_URL !== null ? 'Download' : '-'}</td>
                  <td className="text-center">{getDate(details.created_at)}</td>
                </tr>
              ))
            ) :
              <tr>
                <td colSpan="5" className="text-center ">
                  No records Found
                </td>
              </tr>
          )
          :
          (
            data?.[0]?.applied_training?.length > 0 ? (
              data?.[0]?.applied_training.map((details, index) => (
                <tr key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td className="text-left">
                    {details.user.first_name + ' ' + details.user.last_name}
                  </td>
                  <td className="text-left">
                    {details.user.email}
                  </td>
                  <td className="text-center">{(details.user.user_role)?.charAt(0).toUpperCase() + (details.user.user_role)?.slice(1).toLowerCase()}</td>
                  <td className="text-center">{getDate(details.created_at)}</td>
                </tr>
              ))
            ) :
              <tr>
                <td colSpan="5" className="text-center ">
                  No records Found
                </td>
              </tr>
          )
    ) : (
      <tr>
        <td colSpan="5" className="text-center ">
          <CommanSpinner />
        </td>
      </tr>
    );
  };

  const getTitle = () => {
    switch (getUserRole()) {
      case "STUDENT":
        return "Job Application Details";
      case "RECRUITER":
      case "TRAINER":
        return "Applications";
      default:
        return "Details";
    }
  };

  return (
    <Modal
      show={props.trainingApplyDetails.isModalOpen}
      size={"xl"}
      className={`tj-modal ` + (props.darkTheme ? "is-dark-theme" : "")}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
      centered
    >
      <Modal.Header closeButton className="d-flex">
        <Modal.Title className="flex-grow-1">{getTitle()}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs
          id="training-apply-details-tabs"
          activeKey={activeTab}
          onSelect={(tab) => setActiveTab(tab)}
          className="justify-content-center job-modal-nav"
        >
          <Tab eventKey="1" title="Job">
            {(getUserRole() == "STUDENT" || getUserRole() == "TRAINER") ?
              <div className="job-application-view table-responsive mt-3">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center font-weight-bold">S.No</th>
                      <th className="text-center font-weight-bold">Title</th>
                      <th className="text-center font-weight-bold">Company Name</th>
                      <th className="text-center font-weight-bold">Contact Person</th>
                      <th className="text-center font-weight-bold">Date</th>
                      <th className="text-center font-weight-bold">Location</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableBody('Job')}</tbody>
                </table>
              </div>
              : (getUserRole() == "RECRUITER") &&
              <div className="job-application-view table-responsive mt-3">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center font-weight-bold">S.No</th>
                      <th className="text-center font-weight-bold">Name</th>
                      <th className="text-center font-weight-bold">Email</th>
                      <th className="text-center font-weight-bold">Role</th>
                      <th className="text-center font-weight-bold">Resume</th>
                      <th className="text-center font-weight-bold">Applied Date</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableBody('Job')}</tbody>
                </table>
              </div>
            }
          </Tab>
          <Tab eventKey="2" title="Training">
            {(getUserRole() == "STUDENT" || getUserRole() == "TRAINER") ?
              <div className="job-application-view table-responsive mt-3">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center font-weight-bold">S.No</th>
                      <th className="text-center font-weight-bold">Title</th>
                      <th className="text-center font-weight-bold">Trainer Name</th>
                      <th className="text-center font-weight-bold">Start Date</th>
                      <th className="text-center font-weight-bold">End Date</th>
                      <th className="text-center font-weight-bold">Duration(Days)</th>
                      <th className="text-center font-weight-bold">Timing(Hrs)</th>
                      <th className="text-center font-weight-bold">Cost</th>
                      <th className="text-center font-weight-bold">Date</th>

                    </tr>
                  </thead>
                  <tbody>{renderTableBody('Training')}</tbody>
                </table>
              </div>
              : (getUserRole() == "RECRUITER") &&
              <div className="job-application-view table-responsive mt-3">
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th className="text-center font-weight-bold">S.No</th>
                      <th className="text-center font-weight-bold">Name</th>
                      <th className="text-center font-weight-bold">Email</th>
                      <th className="text-center font-weight-bold">Role</th>
                      <th className="text-center font-weight-bold">Applied Date</th>
                    </tr>
                  </thead>
                  <tbody>{renderTableBody('Training')}</tbody>
                </table>
              </div>
            }
          </Tab>
        </Tabs>
      </Modal.Body>
      <Modal.Footer></Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  trainingApplyDetails: state.reqTraining.ApplyDetails,
});

export default connect(mapStateToProps, {
  setApplyDetails,
  AppliedDetails,
  RecruiterAppliedDetails,
  downloadResume
})(TrainingApplyDetailsModal);

import {
  SET_ZOOM_MEETINGS,
  ZOOM_NOTIFICATION,
  INSERT_ZOOM_MEETINGS,
  UPDATE_ZOOM_MEETINGS,
  DELETE_ZOOM_MEETINGS,
  ZOOM_MEETING_MODAL,
  ZOOM_IS_AUTH
} from "../actions/types";

const initialState = { 
  zoomMeetings: [],
  zoomMeetingModal: {
    isModalOpen: false,
    zoomMeetingObj: {},
  },
  zoomMeetingNotification: {
    message: '',
    isSuccess: 1
  },
  zoomIsAuth: false,
}

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case SET_ZOOM_MEETINGS:
      return {
        ...state, 
        zoomMeetings:payload
      }
    case ZOOM_NOTIFICATION:
      return {
        ...state, 
        zoomMeetingNotification: (payload.message) ? payload : initialState.zoomMeetingNotification
      }
      case ZOOM_MEETING_MODAL:
        return {
          ...state, 
          zoomMeetingModal: {...state, ...payload}
        }
    case INSERT_ZOOM_MEETINGS:
      return {
        ...state, 
        zoomMeetings: [payload, ...state.zoomMeetings]
      }
    case UPDATE_ZOOM_MEETINGS:
      return {
        ...state, 
        zoomMeetings: state.zoomMeetings.map(meeting => meeting.zoom_meetings_id  === payload.zoom_meetings_id  ? 
          { ...meeting, ...payload} : meeting
        )
      }
    case DELETE_ZOOM_MEETINGS:
      return {
        ...state,
        zoomMeetings:  state.zoomMeetings.filter(meeting => meeting.zoom_meetings_zoom_id !== payload.zoom_meetings_zoom_id)
      }
      case ZOOM_IS_AUTH: 
      return {
        ...state,
        zoomIsAuth: payload
      }
    default:
      return state;
  }
}
export default reducer;
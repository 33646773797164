import Axios from '../config/axios';

class QboardService {

	getQboardPosts =  (filterData) => {

		return Axios.post('/getpostquery', filterData).then((response) => {
			const { data } = response;
    		  return data;
		}).catch((error) => {
			console.log(error);
		});
	}

	addFavourite = async (data) => {
			return Axios.post('/add-favourite', data).then((response) => {
			const { data } = response;
    		  return data;
		}).catch((error) => {
			console.log(error);
		});
	}

	postQboardData = async (data) => {
		let form_data = new FormData();

		for ( var key in data ) {
				if(key === 'qboard_hashtag_view') {
					form_data.append('qboard_hashtag_view', JSON.stringify(data[key]));
				} else {
					form_data.append(key, data[key]);
				}
			
		}

		let returnData = [];

		await Axios.post("/postquery", form_data)
			.then(res => {
				returnData = res.data;
			})

		return returnData;
	}

	deleteQboardPost = (qboardId) => {
		return 	Axios.post("/destroy-qboard/" + qboardId, []).then((response) => {
			const { data } = response;
    	return data;
		}).catch((error) => {
			console.log(error);
		});
	}

	qboardCommentUpdate = (targetComment) => {
		return Axios.post("/post-comments", targetComment).then((response) => {
			const { data } = response;
    	return data;
		}).catch((error) => {
			console.log(error);
		});
	}

	qboardCommentDelete = async (data) => {
		let returnData;

		await Axios.post("/destroy-comment", data)
			.then(res => {
				if (res.data.errCode === 0) {
					returnData = {
						'errCode': 0,
					}

				} else {
					returnData = {
						'errCode': 1
					};
				}
			})

		return returnData;
	}

	qboardPostLike = async (data) => {
		let returnData;

		await Axios.post("/postlike", data).then(res => {
			if (res.data.errCode === 0) {

				returnData = {
					'errCode': 0,
					'updatePost': res.data
				}
			} else {
				returnData = {
					'errCode': 1
				};
			}
		})

		return returnData;
	}


	getComments = async (data) => {
		
		let resultData = [];

		await Axios.get("/get-comments", {
			params: data,
		}).then(res => {
			resultData = res.data;
		})

		return resultData;
	}

	qboardFileDownlaod = async (url, fileName) => {
		Axios({
			url: '/qboard-file-download',
			method: 'GET',
			responseType: 'blob', // important
			params: {
				filename: url,
			},
		}).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', fileName); //or any other extension
			document.body.appendChild(link);
			link.click();
		});
	}

	commonLikeData = async (data) => {
		let resultData = [];

		await Axios.get("/get-commonlike-data", {
			params: data,
		}).then(res => {
			resultData = res.data;
		})

		return resultData;
	}
}
const qboardServiceInstance = new QboardService();
export default qboardServiceInstance;
import { useEffect, useState } from "react";

import Loader from "react-loader-spinner";

import Profile from './Profile';
import ProfileView from "./ProfileView";
import ProfileService from '../../../services/profile.service';
import { getUser } from "../../../utils/UserDetails";
import NewProfileView from '../profile/preview/NewProfileView';


const ProfileRender = (props) => {
  let profileId = props.match.params.id;
  const currentUser = getUser();
  const [profileData, setProfileData] = useState([]);

  useEffect(() => {
    let profileObj = {
      profileId: profileId
    }
    getProfileViewDetails(profileObj);
  }, []);

  const getProfileViewDetails = async (profileObj) => {
    let resultData = await ProfileService.getProfileViewDetails(profileObj);
    setProfileData(resultData.details)
  }

  return (
    <>
      {(currentUser === null) ?
        (profileData && typeof (profileData) !== undefined && profileData.user_profile_id) ? <NewProfileView data={profileData} /> : <Loader type="ThreeDots" color="#00BFFF" height={50} width={50} className="loader" />
        : (profileData.user_profile_email === currentUser.email) ?
          <Profile id={props.match.params.id} />
          : (profileData && typeof (profileData) !== undefined && profileData.user_profile_id) ? <NewProfileView data={profileData} /> :
            <Loader type="ThreeDots"
              color="#00BFFF"
              height={50}
              width={50}
              className="loader"
            />
      }
    </>
  );
}

export default ProfileRender;
import { useState, useEffect } from "react";

import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAngleDoubleLeft,
  faTimes,
  faUserPlus,
  faUser,
  faEye,
  faUserSecret,
  faBookOpen,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import renderHTML from "react-render-html";
import { Tab, Tabs, Modal, Button, ModalFooter } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import LeftMenu from "./LeftMenu";
import TrainingExperience from "./trainingexperience/TrainingExperience";
import JobExperience from "./jobexperience/JobExperience";
import Qualification from "./qualifications/Qualifications";
import Certificates from "./certifications/Certificates";
import Awards from "./awards/Awards";
import MoreSkills from "./moreskills/MoreSkills";
import Skillendorsement from "./skillendorsement/SkillEndorsement";
import Interests from "./interests/Interests";
import CommanSpinner from "../../../components/spinner/CommanSpinner";
import Layout from "../../../components/layout/Layout";
import ProfileService from "../../../services/profile.service";
import * as userProfileAction from "../../../actions/profile";
import {
  getProfileDetails,
  addUserProfile,
  setActiveProfile,
  trainingExperience,
  jobExperience,
  qualification,
  certificate,
  award,
  skill,
  description,
  recordHide,
  createNewProfile,
  sectionShow,
  endorse,
  getEndorseDetails,
  editProfileMenu,
  addInterest,
  getInterests,
  getProfile,
  setImages,
} from "../../../actions/profile";
import { setActiveColor, setDarkTheme } from "../../../actions/colorTheme";
import commonService from "../../../services/common.service";
import Trash from "../profile/trash/Trash";
import useDidMountEffect from "./DidMount";
import { getUserRole } from "../../../utils/UserDetails";

const Profile = (props) => {
  const experienceObj = {
    userexperience_id: null,
    userexperience_profile_id: null,
    userexperience_designation: null,
    user_experience_description: null,
    user_experience_display_order: null,
    userexperience_show: 1,
    userexperience_from: null,
    userexperience_to: null,
  };

  const jobExperienceObj = {
    user_job_experience_id: null,
    user_job_experience_profile_id: null,
    user_job_experience_job_name: null,
    user_job_experience_company_name: null,
    user_job_experience_description: null,
    user_job_experience_display_order: null,
    user_job_experience_show: 1,
    user_job_experience_from: null,
    user_job_experience_to: null,
  };

  const qualificationObj = {
    userqualification_id: null,
    userqualification_profile_id: null,
    userqualification_name: null,
    userqualification_institution_name: null,
    userqualification_subject: null,
    userqualification_display_order: null,
    userqualification__show: 1,
    userqualification_year_start: null,
    userqualification_year_end: null,
  };

  const certificateObj = {
    user_certificates_id: null,
    user_certificates_profile_id: null,
    user_certificates_name: null,
    user_certificates_institution_name: null,
    user_certificates_display_order: null,
    user_certificates_show: 1,
    user_certificates_description: null,
  };

  const awardObj = {
    user_awards_id: null,
    user_awards_title: null,
    user_awards_description: null,
    user_awards_profile_id: null,
    user_awards_show: 1,
    user_awards_display_order: null,
  };

  const skillObj = {
    userskill_id: null,
    userskill_profile_id: null,
    userskill_name: null,
    userskill_display_order: null,
    userskill_expertize: null,
    userskill_show: 1,
  };

  const newProfileObj = {
    user_profile_id: null,
    user_profile_userid: null,
    user_profile_name: null,
    user_profile_location: null,
    user_profile_url: null,
    user_profile_email: null,
    user_profile_description: null,
    user_profile_contact_number: null,
    user_profile_image_url: null,
    user_profile_enabled: 1,
    user_profile_show: 1,
    user_experience_enabled: 1,
    user_job_experience_enabled: 1,
    user_qualification_enabled: 1,
    user_description_enabled: 1,
    user_certifications_enabled: 1,
    user_awards_enabled: 1,
    user_profile_locality: null,
    user_profile_city: null,
    user_profile_state: null,
    user_profile_country: null,
    user_profile_page_color: null,
    user_profile_page_dark: null,
    user_profile_bg_img_url: null,
  };

  let modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
  };

  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const colorSet1 = [
    "type1-1",
    "type1-2",
    "type1-3",
    "type1-4",
    "type1-5",
    "type1-6",
    "type1-7",
    "type1-8",
    "type1-9",
    "type1-10",
    "type1-11",
    "type1-12",
    "type1-13",
    "type1-14",
    "type1-15",
  ];

  const colorSet2 = [
    "type2-1",
    "type2-2",
    "type2-3",
    "type2-4",
    "type2-5",
    "type2-6",
    "type2-7",
    "type2-8",
    "type2-9",
    "type2-10",
    "type2-11",
    "type2-12",
    "type2-13",
    "type2-14",
    "type2-15",
  ];

  const location = useLocation();
  const dispatch = useDispatch();
  const [profileDetails, setProfileDetails] = useState({});
  const [editableStatus, setEditable] = useState({ status: false });
  const [loaderStatus, setLoaderStatus] = useState({ status: true });
  const [showEndorseDetails, setShowEndorseModal] = useState({
    showModal: false,
  });
  const [showSpinner, setSpinner] = useState({ status: false, id: "" });
  const [endorseData, setEndorseData] = useState([]);
  const [btnText, setBtnText] = useState({ text: false });
  const [interestModalData, setInterestModalData] = useState([]);
  const [showInterestsModal, setShowInterestsModal] = useState({
    showModal: false,
  });
  const [interestData, setInterestData] = useState([]);
  const [interests, setInterests] = useState([]);
  const [subCategoriesActive, setSubCategoriesActive] = useState(false);
  const [categoriesActive, setCategoriesActive] = useState(true);
  const [showCropModal, setShowCropModal] = useState({ showModal: false });
  const [interestChildData, setInterestChildData] = useState([]);
  const [comparison, setComparison] = useState([]);
  const [showInterestAlertModal, setShowInterestAlertModal] = useState({
    showAlert: false,
  });
  const [missedInterests, setMissedInterests] = useState([]);
  const [bgImg, setBgImg] = useState(false);

  useEffect(() => {
    let active = { ...profileDetails };
    active = props.activeProfile;
    setProfileDetails(active);
  }, [props.activeProfile]);

  useEffect(() => {
    if (location.pathname === "/trainer/profile") {
      document.title = "Profile - TJunction"; // Set the title for the new tab
    }
  }, [location]);

  useEffect(() => {
    if (profileDetails.user_profile_id) {
      setLoaderStatus({ ...loaderStatus, status: false });

      if (!props.activeProfile.experience.length) {
        addNewTraining(experienceObj, 0, 1, 1);
      }

      if (!props.activeProfile.job_experience.length) {
        addNewJob(jobExperienceObj, 0, 1, 1);
      }

      if (!props.activeProfile.qualification.length) {
        addNewQualification(qualificationObj, 0, 1, 1);
      }

      if (!props.activeProfile.certificates.length) {
        addNewCertificate(certificateObj, 0, 1, 1);
      }

      if (!props.activeProfile.awards.length) {
        addNewAward(awardObj, 0, 1, 1);
      }

      if (!props.activeProfile.skill.length) {
        addNewSkill(skillObj, 0, 1, 1);
      }

      setInterestData(profileDetails.interest);
    }
  }, [profileDetails]);

  useEffect(() => {
    let result = [];
    let child = [];
    let newComparison = [...comparison];

    if (interestData.length) {
      result = interestData.map(
        (parent) => parent.interests.parent[0].interests_id,
      );
      child = interestData.map((child) => child.user_interests_interest_id);
      newComparison = interestData.map(
        (comp) =>
          comp.user_interests_interest_id +
          "_" +
          comp.interests.parent[0].interests_id,
      );
    }
    // to avoid duplications
    result = result.filter(function (item, pos) {
      return result.indexOf(item) === pos;
    });

    child = child.filter(function (item, pos) {
      return child.indexOf(item) === pos;
    });

    setInterests(result);
    setInterestChildData(child);
    setComparison(newComparison);
  }, [interestData]);

  useDidMountEffect(() => {
    if (props.interest) {
      let newIntData = [...interestData];
      newIntData = props.interest;
      setShowInterestAlertModal({
        ...showInterestAlertModal,
        showAlert: false,
      });
      setShowInterestsModal({ ...showInterestsModal, showModal: false });
      setInterestData(newIntData);
    }
  }, [props.interest]);

  useDidMountEffect(() => {
    if (props.allInterests) {
      setInterestModalData(props.allInterests);
      setSpinner({ ...showSpinner, status: false, id: "" });
      setSubCategoriesActive(false);
      setCategoriesActive(true);

      setShowInterestsModal({ ...showInterestsModal, showModal: true });
    } else {
      setSpinner({ ...showSpinner, status: false, id: "" });
    }
  }, [props.allInterests]);

  useDidMountEffect(() => {
    if (props.profile) {
      let newData = { ...profileDetails };
      newData = props.profile;

      if (newData.user_profile_page_color) {
        dispatch(props.setActiveColor(newData.user_profile_page_color));
      }

      dispatch(
        props.setDarkTheme(
          newData.user_profile_page_dark ? newData.user_profile_page_dark : 0,
        ),
      );
      dispatch(props.setActiveProfile(newData));
      setProfileDetails(newData);
    }
  }, [props.profile]);

  useDidMountEffect(() => {
    if (props.endorseDetails) {
      setShowEndorseModal({ ...showEndorseDetails, showModal: true });
      setEndorseData(props.endorseDetails);
    }
  }, [props.endorseDetails]);

  useDidMountEffect(() => {
    if (props.image) {
      let userdata = { ...props.activeProfile };
      if (bgImg) {
        userdata["user_profile_bg_img_url"] = props.image.url;
      } else {
        userdata["user_profile_image_url"] = props.image.url;
      }
      dispatch(props.setActiveProfile(userdata));
      setShowCropModal({ ...showCropModal, showModal: false });
      setBgImg(false);
    } else {
      setShowCropModal({ ...showCropModal, showModal: false });
      setBgImg(false);
    }
  }, [props.image]);

  const editExperienceDesignation = async (key, trainingObj) => {
    props.trainingExperience(trainingObj);
  };

  const editJobExperience = async (key, jobObj) => {
    props.jobExperience(jobObj);
  };

  const editQualification = async (key, qualificationObj) => {
    props.qualification(qualificationObj);
  };

  const editCertificate = async (key, certificateObj) => {
    props.certificate(certificateObj);
  };

  const editAward = async (key, awardObj) => {
    props.award(awardObj);
  };

  const editSkill = async (key, skillObj) => {
    props.skill(skillObj);
  };

  const experienceChangeHandler = (e, from, index) => {
    let newActiveProfile = { ...props.activeProfile };

    if (from === "editor") {
      newActiveProfile["experience"][index]["user_experience_description"] = e;
    } else {
      newActiveProfile["experience"][index][e.target.name] = e.target.value;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const jobChangeHandler = (e, from, index) => {
    let newActiveProfile = { ...props.activeProfile };

    if (from === "editor") {
      newActiveProfile["job_experience"][index][
        "user_job_experience_description"
      ] = e;
    } else {
      newActiveProfile["job_experience"][index][e.target.name] = e.target.value;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const qualificationChangeHandler = (e, from, index) => {
    let newActiveProfile = { ...props.activeProfile };

    if (from === "editor") {
      newActiveProfile["qualification"][index]["userqualification_subject"] = e;
    } else {
      newActiveProfile["qualification"][index][e.target.name] = e.target.value;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const certificateChangeHandler = (e, from, index) => {
    let newActiveProfile = { ...props.activeProfile };

    if (from === "editor") {
      newActiveProfile["certificates"][index]["user_certificates_description"] =
        e;
    } else {
      newActiveProfile["certificates"][index][e.target.name] = e.target.value;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const awardChangeHandler = (e, from, index) => {
    let newActiveProfile = { ...props.activeProfile };

    if (from === "editor") {
      newActiveProfile["awards"][index]["user_awards_description"] = e;
    } else {
      newActiveProfile["awards"][index][e.target.name] = e.target.value;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const skillChangeHandler = (e, index) => {
    let newActiveProfile = { ...props.activeProfile };
    newActiveProfile.skill[index][e.target.name] = e.target.value;
    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const onDescSubmitHandler = async (e, source, editor, profileId) => {
    setProfileDetails((prevData) => {
      return { ...prevData, user_profile_description: editor.getHTML() };
    });

    let descriptionData = {
      profile_description: editor.getHTML(),
      user_profile_id: profileId,
    };

    props.description(descriptionData);

    let newActiveProfile = { ...props.activeProfile };
    newActiveProfile.user_profile_description =
      descriptionData.profile_description;
    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
    toast.success("Added");
  };

  const addNewTraining = (
    experienceObj = {},
    index,
    displayOrder,
    isFromRestart = 0,
  ) => {
    let newActiveProfile = { ...props.activeProfile };

    if (isFromRestart) {
      newActiveProfile.experience = [];
    }

    experienceObj["userexperience_profile_id"] = profileDetails.user_profile_id;
    experienceObj["user_experience_display_order"] = displayOrder;

    if (index) {
      newActiveProfile.experience.push(experienceObj);
    } else {
      newActiveProfile.experience.unshift(experienceObj);
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const addNewJob = (
    experienceObj = {},
    index,
    displayOrder,
    isFromRestart = 0,
  ) => {
    let newActiveProfile = { ...props.activeProfile };

    if (isFromRestart) {
      newActiveProfile.job_experience = [];
    }
    experienceObj["user_job_experience_profile_id"] =
      profileDetails.user_profile_id;
    experienceObj["user_job_experience_display_order"] = displayOrder;

    if (index) {
      newActiveProfile.job_experience.push(experienceObj);
    } else {
      newActiveProfile.job_experience.unshift(experienceObj);
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const addNewQualification = (
    qualificationObj = {},
    index,
    displayOrder,
    isFromRestart = 0,
  ) => {
    let newActiveProfile = { ...props.activeProfile };

    if (isFromRestart) {
      newActiveProfile.qualification = [];
    }

    qualificationObj["userqualification_profile_id"] =
      profileDetails.user_profile_id;
    qualificationObj["userqualification_display_order"] = displayOrder;

    if (index) {
      newActiveProfile.qualification.push(qualificationObj);
    } else {
      newActiveProfile.qualification.unshift(qualificationObj);
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const addNewCertificate = (
    certificateObj = {},
    index,
    displayOrder,
    isFromRestart = 0,
  ) => {
    let newActiveProfile = { ...props.activeProfile };

    if (isFromRestart) {
      newActiveProfile.certificates = [];
    }
    certificateObj["user_certificates_profile_id"] =
      profileDetails.user_profile_id;
    certificateObj["user_certificates_display_order"] = displayOrder;

    if (index) {
      newActiveProfile.certificates.push(certificateObj);
    } else {
      newActiveProfile.certificates.unshift(certificateObj);
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const addNewAward = (
    awardObj = {},
    index,
    displayOrder,
    isFromRestart = 0,
  ) => {
    let newActiveProfile = { ...props.activeProfile };

    if (isFromRestart) {
      newActiveProfile.awards = [];
    }
    awardObj["user_awards_profile_id"] = profileDetails.user_profile_id;
    awardObj["user_awards_display_order"] = displayOrder;

    if (index) {
      newActiveProfile.awards.push(awardObj);
    } else {
      newActiveProfile.awards.unshift(awardObj);
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const addNewSkill = (
    skillObj = {},
    index,
    displayOrder,
    isFromRestart = 0,
  ) => {
    let newActiveProfile = { ...props.activeProfile };

    if (isFromRestart) {
      newActiveProfile.skill = [];
    }

    skillObj["userskill_profile_id"] = profileDetails.user_profile_id;
    skillObj["userskill_display_order"] = displayOrder;

    if (index) {
      newActiveProfile.skill.push(skillObj);
    } else {
      newActiveProfile.skill.unshift(skillObj);
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const generateNewTrainingExperience = (e, from) => {
    let index;
    let displayOrder;
    let dataLength = props.activeProfile.experience.length;

    if (from === "top") {
      index = 0;
      displayOrder = dataLength + 1;
    } else {
      index = dataLength + 1;
      displayOrder = 1;
    }

    addNewTraining(experienceObj, index, displayOrder);
  };

  const generateNewJobExperience = (e, from) => {
    let index;
    let displayOrder;
    let dataLength = props.activeProfile.job_experience.length;

    if (from === "top") {
      index = 0;
      displayOrder = dataLength + 1;
    } else {
      index = dataLength + 1;
      displayOrder = 1;
    }

    addNewJob(jobExperienceObj, index, displayOrder);
  };

  const generateNewQualification = (e, from) => {
    let index;
    let displayOrder;
    let dataLength = props.activeProfile.qualification.length;

    if (from === "top") {
      index = 0;
      displayOrder = dataLength + 1;
    } else {
      index = dataLength + 1;
      displayOrder = 1;
    }

    addNewQualification(qualificationObj, index, displayOrder);
  };

  const generateNewCertificate = (e, from) => {
    let index;
    let displayOrder;
    let dataLength = props.activeProfile.certificates.length;

    if (from === "top") {
      index = 0;
      displayOrder = dataLength + 1;
    } else {
      index = dataLength + 1;
      displayOrder = 1;
    }

    addNewCertificate(certificateObj, index, displayOrder);
  };

  const generateNewAward = (e, from) => {
    let index;
    let displayOrder;
    let dataLength = props.activeProfile.awards.length;

    if (from === "top") {
      index = 0;
      displayOrder = dataLength + 1;
    } else {
      index = dataLength + 1;
      displayOrder = 1;
    }

    addNewAward(awardObj, index, displayOrder);
  };

  const generateNewSkill = (e, from) => {
    let index;
    let displayOrder;
    let dataLength = props.activeProfile.skill.length;

    if (from === "top") {
      index = 0;
      displayOrder = dataLength + 1;
    } else {
      index = dataLength + 1;
      displayOrder = 1;
    }

    addNewSkill(skillObj, index, displayOrder);
  };

  const showAndHideOption = async (status, data, source, index) => {
    let newActiveProfile = { ...props.activeProfile };
    let showAndHideData = {
      status: status,
      id: data,
      source: source,
    };

    props.recordHide(showAndHideData);

    switch (source) {
      case "texperience":
        newActiveProfile["experience"][index]["userexperience_show"] = status
          ? 0
          : 1;
        break;
      case "job":
        newActiveProfile["job_experience"][index]["user_job_experience_show"] =
          status ? 0 : 1;
        break;
      case "qualification":
        newActiveProfile["qualification"][index]["userqualification__show"] =
          status ? 0 : 1;
        break;
      case "certificate":
        newActiveProfile["certificates"][index]["user_certificates_show"] =
          status ? 0 : 1;
        break;
      case "award":
        newActiveProfile["awards"][index]["user_awards_show"] = status ? 0 : 1;
        break;
      case "skills":
        newActiveProfile["skill"][index]["userskill_show"] = status ? 0 : 1;
        break;
      case "interest":
        newActiveProfile["interest"][index]["user_interests_show"] = status
          ? 0
          : 1;
        break;
      default:
        break;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const handleChangeDescription = (value, id) => {
    let newProfileData = { ...profileDetails };
    newProfileData["user_profile_description"] = value;
    setProfileDetails(newProfileData);
  };

  const setEndorsement = async (index, skillId) => {
    let endorseData = {
      user_profile_id: profileDetails.user_profile_id,
      userskill_id: skillId,
    };

    props.endorse(endorseData, index, props.activeProfile.skill);
  };

  const showEndorseModal = async (id, skillId) => {
    setSpinner({ ...showSpinner, status: true, id: id });

    let endorseData = {
      user_profile_id: profileDetails.user_profile_id,
      userskill_id: skillId,
    };

    await props.getEndorseDetails(endorseData);
  };

  const closeEndorseModal = () => {
    setSpinner({ ...showSpinner, status: true, id: "" });
    setShowEndorseModal({ ...showEndorseDetails, showModal: false });
  };

  const setImage = async (e, val) => {
    let fd = new FormData();
    fd.append("file", val);
    fd.append("profileId", profileDetails.user_profile_id);

    if (e.isBgImg) {
      fd.append("isBgImg", 1);
      setBgImg(true);
    }
    props.setImages(fd);
  };

  const profileChangeHandler = (event) => {
    let newProfileData = { ...profileDetails };
    newProfileData[event.target.name] = event.target.value;
    setProfileDetails(newProfileData);
  };

  const editProfileData = async (profileData) => {
    props.editProfileMenu(profileData);
  };

  const getProfileWithId = async (profileId) => {
    let profileData = {
      profileId: profileId,
    };

    props.getProfile(profileData);
  };

  const createNewComponent = () => {
    let newProfile = { ...props.activeProfile };
    newProfile.profiles.push(newProfileObj);
    dispatch(userProfileAction.setActiveProfile(newProfile));
  };

  const handleProfile = (e, pKey) => {
    let newProfile = { ...props.activeProfile };
    console.log(newProfile);
    newProfile.profiles[pKey][e.target.name] = e.target.value;
    dispatch(userProfileAction.setActiveProfile(newProfile));
  };

  const createNewProfile = async (key, profile) => {
    console.log(profile);
    await props.createNewProfile(profile, key, props.activeProfile.profiles);
    getProfileWithId(props.activeProfile.profiles[key]["user_profile_id"]);
  };

  const editMode = (e, text) => {
    if (btnText.text === true) {
      props.getProfileDetails(1);
    }
    let newText = { ...btnText };
    newText.text = !text;
    setBtnText(newText);
    let newStatus = { ...editableStatus };
    newStatus.status = !newStatus.status;
    setEditable(newStatus);
  };

  const showInterestModal = async (id) => {
    setSpinner({ ...showSpinner, status: true, id: id });
    props.getInterests();
  };

  const closeInterestModal = () => {
    setShowInterestsModal({ ...showInterestsModal, showModal: false });
  };

  const createInterest = async () => {
    let uniqueComparison = [];
    let uniqueComparison1 = [];

    uniqueComparison = comparison.map(function (item, pos) {
      return parseInt(item.split("_")[1]);
    });

    uniqueComparison1 = interests.filter(function (item, pos) {
      return !(uniqueComparison.indexOf(item) > -1);
    });

    if (uniqueComparison1.length) {
      // show popup
      let newMissed = [...missedInterests];
      let toComp = [];
      interestModalData.map((prop) => {
        if (uniqueComparison1.includes(prop.interests_id)) {
          if (!toComp.includes(prop.interests_name)) {
            toComp.push(prop.interests_name);
          }
        }
      });

      newMissed = toComp.reduce(
        (text, value, i, array) =>
          text + (i < array.length - 1 ? ", " : " and ") + value,
      );
      setShowInterestAlertModal({ ...showInterestAlertModal, showAlert: true });
      setMissedInterests(newMissed);
    } else {
      // submit the form
      submitInterestForm();
    }
  };

  const setFileData = async (e, id) => {
    let file = e.target.files[0];
    setSpinner({ ...showSpinner, status: true, id: id });
    let fileData = new FormData();
    fileData.append("file", file);
    fileData.append("profileId", profileDetails.user_profile_id);
    fileData.append("userId", profileDetails.user_profile_userid);
    let fileResponse = await ProfileService.uploadFile(fileData);

    if (fileResponse?.status) {
      let resultData = await ProfileService.getProfileDetails(1);

      if (resultData?.status) {
        let activeProfile = ProfileService.getActiveProfile(
          resultData.profileData,
        ).shift();
        dispatch(userProfileAction.addUserProfile(resultData.profileData));
        dispatch(userProfileAction.setActiveProfile(activeProfile));
        toast.success("Successfully added CV");
      } else {
        setSpinner({ ...showSpinner, status: false, id: "" });
        toast.error("Try again");
      }
    } else {
      toast.error("Try again");
    }
    setSpinner({ ...showSpinner, status: false, id: "" });
  };

  const sectionShowAndHide = async (status, source) => {
    let showData = {
      source: source,
      status: status,
      profileId: profileDetails.user_profile_id,
    };

    let newData = { ...props.activeProfile };

    switch (source) {
      case "experience":
        newData.user_experience_enabled = status ? 0 : 1;
        break;
      case "job-experience":
        newData.user_job_experience_enabled = status ? 0 : 1;
        break;
      case "awards":
        newData.user_awards_enabled = status ? 0 : 1;
        break;
      case "certifications":
        newData.user_certifications_enabled = status ? 0 : 1;
        break;
      case "description":
        newData.user_description_enabled = status ? 0 : 1;
        break;
      case "skills":
        newData.user_skills_enabled = status ? 0 : 1;
        break;
      case "qualification":
        newData.user_qualification_enabled = status ? 0 : 1;
        break;
      default:
        break;
    }

    dispatch(props.setActiveProfile(newData));
    props.sectionShow(showData);
  };

  const addInterestArray = (e, obj, key) => {
    e.preventDefault();
    let newModal = [...interestModalData];
    newModal[key]["isActive"] = obj.isActive ? 0 : 1;
    setInterestModalData(newModal);

    let newInterests = [...interests];
    let id = obj.interests_id;

    if (!newInterests.includes(id)) {
      newInterests.push(id);
    } else {
      newInterests.splice(newInterests.indexOf(id), 1);
    }

    setInterests(newInterests);
  };

  const setModalStatus = () => {
    setSubCategoriesActive(!subCategoriesActive);
    setCategoriesActive(!categoriesActive);
  };

  const openPreview = async (profileId) => {
    let w1 = commonService.newMeetingWindow();
    let redirectLink = `/${getUserRole().toLowerCase()}/preview/` + profileId;
    console.log(redirectLink);

    w1.location.href = redirectLink;
  };

  const getProfileWithIdNew = (profileId, e) => {
    if (e && e.target === e.currentTarget) {
      getProfileWithId(profileId);
    }
  };

  const closeCropModal = () => {
    setShowCropModal({ ...showCropModal, showModal: false });
  };

  const renderTooltip = (props) => (
    <Tooltip id="previewId" {...props}>
      Double click to preview
    </Tooltip>
  );

  const addSubCategories = (interestId, obj, key, pKey, parentId) => {
    console.log(interestId);
    let newComparison = [...comparison];

    if (!interestChildData.includes(interestId)) {
      interestChildData.push(interestId);
      newComparison.push(interestId + "_" + parentId);
    } else {
      interestChildData.splice(interestChildData.indexOf(interestId), 1);
      newComparison.splice(
        newComparison.indexOf(interestId + "_" + parentId),
        1,
      );
    }

    setComparison(newComparison);
    setInterestChildData(interestChildData);
  };

  const closeInterestAlertModal = () => {
    setShowInterestAlertModal({ ...showInterestAlertModal, showAlert: false });
  };

  const submitInterestForm = async () => {
    let interestInfo = {
      interestId: interestChildData,
      profileId: profileDetails.user_profile_id,
    };

    props.addInterest(interestInfo);
  };
  console.log(props.activeProfile.profiles);
  return (
    <Layout>
      {loaderStatus.status ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={"80vh"}
          width={50}
          className="loader"
        />
      ) : (
        <div className="container-xxl margin-position">
          <div className="row">
            <div className="col-lg-3 profile-top">
              <LeftMenu
                editMode={editMode}
                setImage={setImage}
                profileData={profileDetails}
                editableStatus={editableStatus}
                profileChangeHandler={profileChangeHandler}
                editProfileData={editProfileData}
                btnText={btnText}
                setFileData={setFileData}
                showSpinner={showSpinner}
                showCropModal={showCropModal}
                setShowCropModal={setShowCropModal}
                closeCropModal={closeCropModal}
              />
            </div>

            <div className="col-lg-9 active-profile-view profile-margin-top">
              <div className="profile-name-details row text-capitalize">
                <div className="nav nav-tabs">
                  {props.activeProfile.profiles.length
                    ? props.activeProfile.profiles.map(
                        (profile, profileKey) => {
                          return (
                            <>
                              {!profile.isToggle ? (
                                editableStatus.status ? (
                                  <div
                                    className={
                                      `profile-label nav-link col-md-2 text-center profile-n ` +
                                      (profile.user_profile_id ===
                                      profileDetails.user_profile_id
                                        ? "activetab"
                                        : "")
                                    }
                                  >
                                    <label
                                      role="tab"
                                      className={
                                        `profile-num ` +
                                        (profile.user_profile_id ===
                                        profileDetails.user_profile_id
                                          ? ` active ${props.activeColor}-font`
                                          : "")
                                      }
                                      onClick={(e) =>
                                        getProfileWithIdNew(
                                          profile.user_profile_id,
                                          e,
                                        )
                                      }
                                    >
                                      Profile {profileKey + 1}
                                      <FontAwesomeIcon
                                        icon={faSortDown}
                                        className="arrow"
                                        role="button"
                                      />
                                      {profile.user_profile_id ===
                                      profileDetails.user_profile_id ? (
                                        ""
                                      ) : (
                                        <span className="delete-profile btn-light">
                                          <Trash
                                            id={profile.user_profile_id}
                                            index={profileKey}
                                            source="profile"
                                            active={
                                              props.activeProfile.profiles
                                            }
                                          />
                                        </span>
                                      )}
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control form-control-sm text-center input-cls"
                                      name="user_profile_name"
                                      onChange={(e) =>
                                        handleProfile(e, profileKey)
                                      }
                                      onBlur={(e) =>
                                        createNewProfile(profileKey, profile)
                                      }
                                      value={
                                        profile.user_profile_name
                                          ? profile.user_profile_name
                                          : ""
                                      }
                                    />
                                  </div>
                                ) : (
                                  <div
                                    className={
                                      `profile-label nav-link p-2 col-md-2 ` +
                                      (profile.user_profile_id ===
                                      profileDetails.user_profile_id
                                        ? ` active ${props.activeColor}-font`
                                        : "")
                                    }
                                    onDoubleClick={() =>
                                      openPreview(profile.user_profile_url)
                                    }
                                  >
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={renderTooltip}
                                    >
                                      <label
                                        onClick={(e) =>
                                          getProfileWithIdNew(
                                            profile.user_profile_id,
                                            e,
                                          )
                                        }
                                        role="tab"
                                      >
                                        {profile.user_profile_name}
                                      </label>
                                    </OverlayTrigger>
                                  </div>
                                )
                              ) : (
                                ""
                              )}
                            </>
                          );
                        },
                      )
                    : null}
                  <div className="col">
                    <div className="pull-right new-profile-plus">
                      {editableStatus.status ? (
                        <span
                          className={`btn btn-sm m-2 btn-${props.activeColor}`}
                          onClick={(e) => createNewComponent(e)}
                        >
                          {" "}
                          <FontAwesomeIcon
                            role="button"
                            icon={faUserPlus}
                            className=""
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      <span
                        className={`btn btn-sm  btn-${props.activeColor}`}
                        onClick={(e) => editMode(e, btnText.text)}
                      >
                        {btnText.text === false ? (
                          <FontAwesomeIcon icon={faPen} />
                        ) : (
                          // <FontAwesomeIcon icon={faUserSecret} />
                          <span className="fa-layers fa-fw">
                            <FontAwesomeIcon icon={faEye} />
                            {/* <FontAwesomeIcon
                              icon={faEye}
                              transform="shrink-6 up-2 right-8"
                            /> */}
                          </span>
                        )}{" "}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs
                defaultActiveKey="aboutme"
                className={`profile-nav-tabs profile-deatils-view profile-heading-font  about-me-weight ${props.activeColor}-nav`}
              >
                <Tab eventKey="aboutme" title="About me">
                  <div className="tab-content border-left border-bottom border-right pb-4 pr-4 mb-4">
                    <div
                      className="tab-pane pl-4 active"
                      id="about"
                      role="tabpanel"
                      aria-labelledby="about-tab"
                    >
                      {/* trainer profile brief introduction */}
                      {editableStatus.status ||
                      profileDetails.user_description_enabled ? (
                        <>
                          <div className="profile-deatils-view  aboutme-brief">
                            {/* <div className="text-infos clearfix my-2"> */}
                            <div className="d-flex my-2 flex-row">
                              <div
                                className={
                                  `lead font-weight-bold col-auto profile-heading-font` +
                                  (!profileDetails.user_description_enabled
                                    ? "text-muted hide-opacity"
                                    : "")
                                }
                                // style={{ width: "91.5%" }}
                              >
                                Brief introduction
                              </div>
                              {editableStatus.status ? (
                                <div
                                  className={
                                    `col title-hide-container ` +
                                    (profileDetails.user_description_enabled ===
                                    1
                                      ? "checkbox-container1-off"
                                      : "checkbox-container1")
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    id="checkbox-desc"
                                    onChange={(e) =>
                                      sectionShowAndHide(
                                        e.currentTarget.checked,
                                        "description",
                                      )
                                    }
                                    defaultChecked={
                                      profileDetails.user_description_enabled ===
                                      1
                                        ? false
                                        : true
                                    }
                                  />
                                  <label htmlFor="checkbox-desc">
                                    <span className="hide-text-position">
                                      Hide
                                    </span>
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            {/* </div> */}

                            {editableStatus.status ? (
                              <ReactQuill
                                key={profileDetails.user_profile_id}
                                modules={modules}
                                formats={formats}
                                value={profileDetails.user_profile_description}
                                onBlur={(e, source, editor) =>
                                  onDescSubmitHandler(
                                    e,
                                    source,
                                    editor,
                                    profileDetails.user_profile_id,
                                  )
                                }
                                onChange={(e) =>
                                  handleChangeDescription(
                                    e,
                                    profileDetails.user_profile_id,
                                  )
                                }
                              />
                            ) : (
                              <p className="paragraph-height">
                                {profileDetails.user_profile_description
                                  ? renderHTML(
                                      profileDetails.user_profile_description,
                                    )
                                  : "No description found"}
                              </p>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {/* Training section */}
                      {(getUserRole() == "TRAINER" &&
                        profileDetails.user_experience_enabled) ||
                      (getUserRole() == "TRAINER" && editableStatus.status) ? (
                        <>
                          <div className="profile-deatils-view box-shadow">
                            <div className="d-flex my-2 flex-row">
                              <div
                                className={
                                  `lead font-weight-bold col-auto profile-heading-font` +
                                  (!profileDetails.user_experience_enabled
                                    ? "text-muted hide-opacity"
                                    : "")
                                }
                                // style={{ width: "91.5%" }}
                              >
                                Training experience
                              </div>
                              {editableStatus.status ? (
                                <div
                                  className={
                                    `col title-hide-container  ` +
                                    (profileDetails.user_experience_enabled ===
                                    1
                                      ? "checkbox-container1-off"
                                      : "checkbox-container1")
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    id="checkbox-experience"
                                    onChange={(e) =>
                                      sectionShowAndHide(
                                        e.currentTarget.checked,
                                        "experience",
                                      )
                                    }
                                    defaultChecked={
                                      profileDetails.user_experience_enabled ===
                                      1
                                        ? false
                                        : true
                                    }
                                  />
                                  <label htmlFor="checkbox-experience">
                                    <span className="hide-text-position">
                                      Hide
                                    </span>
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            {editableStatus.status === true ? (
                              <div className="mt-2 mb-2">
                                <span
                                  onClick={(e) =>
                                    generateNewTrainingExperience(e, "top")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-muted mx-2"
                                    role="button"
                                  />
                                  Add Training experience
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {props.activeProfile.experience.length
                              ? props.activeProfile.experience.map(
                                  (training, trainingKey) => {
                                    if (
                                      training.userexperience_show ||
                                      (editableStatus.status &&
                                        !training.userexperience_show)
                                    ) {
                                      return (
                                        <TrainingExperience
                                          index={trainingKey}
                                          trainingData={training}
                                          editExperienceDesignation={
                                            editExperienceDesignation
                                          }
                                          experienceChangeHandler={
                                            experienceChangeHandler
                                          }
                                          editableStatus={editableStatus}
                                          showAndHideOption={showAndHideOption}
                                          modules={modules}
                                          formats={formats}
                                          activeExperience={
                                            props.activeProfile.experience
                                          }
                                        />
                                      );
                                    }
                                  },
                                )
                              : null}

                            {editableStatus.status === true ? (
                              <div className="m-2">
                                <span
                                  onClick={(e) =>
                                    generateNewTrainingExperience(e, "bottom")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-muted mx-2"
                                    role="button"
                                  />
                                  Add Training experience
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* job experience section */}
                      {profileDetails.user_job_experience_enabled ||
                      editableStatus.status ? (
                        <>
                          <div className="profile-deatils-view box-shadow">
                            <div className="d-flex my-2 flex-row">
                              <div
                                className={
                                  `lead font-weight-bold col-auto profile-heading-font` +
                                  (!profileDetails.user_job_experience_enabled
                                    ? "text-muted hide-opacity"
                                    : "")
                                }
                                id="jobExperienceTitle"
                                // style={{ width: "91.5%" }}
                              >
                                Job experience
                              </div>
                              {editableStatus.status ? (
                                <div
                                  className={
                                    `col title-hide-container ` +
                                    (profileDetails.user_job_experience_enabled ===
                                    1
                                      ? "checkbox-container1-off"
                                      : "checkbox-container1")
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    id="checkbox-job-experience"
                                    onChange={(e) =>
                                      sectionShowAndHide(
                                        e.currentTarget.checked,
                                        "job-experience",
                                      )
                                    }
                                    defaultChecked={
                                      profileDetails.user_job_experience_enabled ===
                                      1
                                        ? false
                                        : true
                                    }
                                  />
                                  <label htmlFor="checkbox-job-experience">
                                    <span className="hide-text-position">
                                      Hide
                                    </span>
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            {editableStatus.status === true ? (
                              <div className="mt-2 mb-2">
                                <span
                                  onClick={(e) =>
                                    generateNewJobExperience(e, "top")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-muted mx-2"
                                    role="button"
                                  />
                                  Add Job experience
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {props.activeProfile.job_experience.length
                              ? props.activeProfile.job_experience.map(
                                  (job, jobKey) => {
                                    if (
                                      job.user_job_experience_show ||
                                      (editableStatus.status &&
                                        !job.user_job_experience_show)
                                    ) {
                                      return (
                                        <JobExperience
                                          index={jobKey}
                                          jobData={job}
                                          activeJobExperience={
                                            props.activeProfile.job_experience
                                          }
                                          editJobExperience={editJobExperience}
                                          jobChangeHandler={jobChangeHandler}
                                          editableStatus={editableStatus}
                                          showAndHideOption={showAndHideOption}
                                        />
                                      );
                                    }
                                  },
                                )
                              : null}

                            {editableStatus.status === true ? (
                              <div className="mb-2">
                                <span
                                  onClick={(e) =>
                                    generateNewJobExperience(e, "bottom")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-muted mx-2"
                                    role="button"
                                  />
                                  Add Job experience
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                      {/* qualification section */}
                      {profileDetails.user_qualification_enabled ||
                      editableStatus.status ? (
                        <>
                          <div className="profile-deatils-view box-shadow">
                            <div className="d-flex my-2 flex-row">
                              <div
                                className={
                                  `lead font-weight-bold col-auto profile-heading-font` +
                                  (!profileDetails.user_qualification_enabled
                                    ? "text-muted hide-opacity"
                                    : "")
                                }
                                // style={{ width: "91.5%" }}
                              >
                                Qualifications
                              </div>
                              {editableStatus.status ? (
                                <div
                                  className={
                                    `col title-hide-container ` +
                                    (profileDetails.user_qualification_enabled ===
                                    1
                                      ? "checkbox-container1-off"
                                      : "checkbox-container1")
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    id="checkbox-qualifications"
                                    onChange={(e) =>
                                      sectionShowAndHide(
                                        e.currentTarget.checked,
                                        "qualification",
                                      )
                                    }
                                    defaultChecked={
                                      profileDetails.user_qualification_enabled ===
                                      1
                                        ? false
                                        : true
                                    }
                                  />
                                  <label htmlFor="checkbox-qualifications">
                                    <span className="hide-text-position">
                                      Hide
                                    </span>
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            {editableStatus.status === true ? (
                              <div className="mt-2 mb-2">
                                <span
                                  onClick={(e) =>
                                    generateNewQualification(e, "top")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-muted mx-2"
                                    role="button"
                                  />
                                  Add Qualification
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {props.activeProfile.qualification.length
                              ? props.activeProfile.qualification.map(
                                  (qualification, qualificationKey) => {
                                    if (
                                      qualification.userqualification__show ||
                                      (editableStatus.status &&
                                        !qualification.userqualification__show)
                                    ) {
                                      return (
                                        <Qualification
                                          index={qualificationKey}
                                          qualificationData={qualification}
                                          activeQualification={
                                            props.activeProfile.qualification
                                          }
                                          editQualification={editQualification}
                                          qualificationChangeHandler={
                                            qualificationChangeHandler
                                          }
                                          editableStatus={editableStatus}
                                          showAndHideOption={showAndHideOption}
                                        />
                                      );
                                    }
                                  },
                                )
                              : null}

                            {editableStatus.status === true ? (
                              <div className="mb-2">
                                <span
                                  onClick={(e) =>
                                    generateNewQualification(e, "bottom")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-muted mx-2"
                                    role="button"
                                  />
                                  Add Qualification
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* certificate section */}
                      {profileDetails.user_certifications_enabled ||
                      editableStatus.status ? (
                        <>
                          <div className="profile-deatils-view box-shadow">
                            <div className="d-flex my-2 flex-row">
                              <div
                                className={
                                  `lead font-weight-bold col-auto profile-heading-font` +
                                  (!profileDetails.user_certifications_enabled
                                    ? "text-muted hide-opacity"
                                    : "")
                                }
                                // style={{ width: "91.5%" }}
                              >
                                Certifications
                              </div>
                              {editableStatus.status ? (
                                <div
                                  className={
                                    `col title-hide-container ` +
                                    (profileDetails.user_certifications_enabled ===
                                    1
                                      ? "checkbox-container1-off"
                                      : "checkbox-container1")
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    id="checkbox-certifications"
                                    onChange={(e) =>
                                      sectionShowAndHide(
                                        e.currentTarget.checked,
                                        "certifications",
                                      )
                                    }
                                    defaultChecked={
                                      profileDetails.user_certifications_enabled ===
                                      1
                                        ? false
                                        : true
                                    }
                                  />
                                  <label htmlFor="checkbox-certifications">
                                    <span className="hide-text-position">
                                      Hide
                                    </span>
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            {editableStatus.status ? (
                              <div className="mt-2 mb-2">
                                <span
                                  onClick={(e) =>
                                    generateNewCertificate(e, "top")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-muted mx-2"
                                    role="button"
                                  />
                                  Add Certificates
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {props.activeProfile.certificates.length
                              ? props.activeProfile.certificates.map(
                                  (certificate, certificateKey) => {
                                    if (
                                      certificate.user_certificates_show ||
                                      (editableStatus.status &&
                                        !certificate.user_certificates_show)
                                    ) {
                                      return (
                                        <Certificates
                                          index={certificateKey}
                                          certificateData={certificate}
                                          activeCertificates={
                                            props.activeProfile.certificates
                                          }
                                          editCertificate={editCertificate}
                                          certificateChangeHandler={
                                            certificateChangeHandler
                                          }
                                          editableStatus={editableStatus}
                                          showAndHideOption={showAndHideOption}
                                        />
                                      );
                                    }
                                  },
                                )
                              : null}

                            {editableStatus.status ? (
                              <div className="mb-2">
                                <span
                                  onClick={(e) =>
                                    generateNewCertificate(e, "bottom")
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-muted mx-2"
                                  />
                                  Add Certificates
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* awards section */}
                      {(profileDetails.user_awards_enabled &&
                        props.activeProfile.awards[0]?.user_awards_id !==
                          null) ||
                      editableStatus.status ? (
                        <>
                          <div className="profile-deatils-view box-shadow">
                            <div className="d-flex my-2 flex-row">
                              <div
                                className={
                                  `lead font-weight-bold col-auto profile-heading-font` +
                                  (!profileDetails.user_awards_enabled
                                    ? "text-muted hide-opacity"
                                    : "")
                                }
                                // style={{ width: "91.5%" }}
                              >
                                Awards
                              </div>
                              {editableStatus.status ? (
                                <div
                                  className={
                                    `col title-hide-container ` +
                                    (profileDetails.user_awards_enabled === 1
                                      ? "checkbox-container1-off"
                                      : "checkbox-container1")
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    id="checkbox-awards"
                                    onChange={(e) =>
                                      sectionShowAndHide(
                                        e.currentTarget.checked,
                                        "awards",
                                      )
                                    }
                                    defaultChecked={
                                      profileDetails.user_awards_enabled === 1
                                        ? false
                                        : true
                                    }
                                  />
                                  <label htmlFor="checkbox-awards">
                                    <span className="hide-text-position">
                                      Hide
                                    </span>
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>

                            {editableStatus.status === true ? (
                              <div className="mt-2 mb-2">
                                <span
                                  onClick={(e) => generateNewAward(e, "top")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-muted mx-2"
                                    role="button"
                                  />
                                  Add Awards
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            {props.activeProfile.awards.length
                              ? props.activeProfile.awards.map(
                                  (award, awardKey) => {
                                    if (
                                      award.user_awards_show ||
                                      (editableStatus.status &&
                                        !award.user_awards_show)
                                    ) {
                                      return (
                                        <Awards
                                          index={awardKey}
                                          awardData={award}
                                          activeAwards={
                                            props.activeProfile.awards
                                          }
                                          editAward={editAward}
                                          awardChangeHandler={
                                            awardChangeHandler
                                          }
                                          editableStatus={editableStatus}
                                          showAndHideOption={showAndHideOption}
                                        />
                                      );
                                    }
                                  },
                                )
                              : null}

                            {editableStatus.status === true ? (
                              <div className="mb-2">
                                <span
                                  onClick={(e) => generateNewAward(e, "bottom")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-muted mx-2"
                                  />
                                  Add Awards
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* Skill and endorsement section */}
                      {/* {profileDetails.user_skills_enabled ||
                      editableStatus.status ? (
                        <>
                          <div className="profile-deatils-view box-shadow">
                            <div className="d-flex my-2 flex-row">
                              <div
                                className={
                                  `lead font-weight-bold col-auto profile-heading-font` +
                                  (!profileDetails.user_skills_enabled
                                    ? "text-muted hide-opacity"
                                    : "")
                                }
                              // style={{ width: "91.5%" }}
                              >
                                Skills and Endorsements
                              </div>
                              {editableStatus.status ? (
                                <div
                                  className={
                                    `col title-hide-container ` +
                                    (profileDetails.user_skills_enabled === 1
                                      ? "checkbox-container1-off"
                                      : "checkbox-container1")
                                  }
                                >
                                  <input
                                    type="checkbox"
                                    id="checkbox-skills"
                                    onChange={(e) =>
                                      sectionShowAndHide(
                                        e.currentTarget.checked,
                                        "skills",
                                      )
                                    }
                                    defaultChecked={
                                      profileDetails.user_skills_enabled === 1
                                        ? false
                                        : true
                                    }
                                  />
                                  <label htmlFor="checkbox-skills">
                                    <span className="hide-text-position">
                                      Hide
                                    </span>
                                  </label>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            {editableStatus.status === true ? (
                              <div className="mt-2 mb-4">
                                <span
                                  onClick={(e) => generateNewSkill(e, "top")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    className="text-muted mx-2"
                                    role="button"
                                  />
                                  Add Skills and endorsements
                                </span>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="row justify-content-between flex-column skill-endorse-scroll">
                              {props.activeProfile.skill.length
                                ? props.activeProfile.skill.map(
                                  (eskill, skilleKey) => {
                                    if (
                                      eskill.userskill_show ||
                                      (editableStatus.status &&
                                        !eskill.userskill_show)
                                    ) {
                                      return (
                                        <Skillendorsement
                                          index={skilleKey}
                                          skillData={eskill}
                                          activeSkill={
                                            props.activeProfile.skill
                                          }
                                          editSkill={editSkill}
                                          editableStatus={editableStatus}
                                          showAndHideOption={
                                            showAndHideOption
                                          }
                                          setEndorsement={setEndorsement}
                                          showEndorseModal={showEndorseModal}
                                          showSpinner={showSpinner}
                                          skillChangeHandler={
                                            skillChangeHandler
                                          }
                                        />
                                      );
                                    }
                                  },
                                )
                                : null}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )} */}

                      {/* More about me section */}
                      <div
                        className={`profile-deatils-view box-shadow ${props.activeColor}-font`}
                      >
                        <p className="lead font-weight-bold text-infos mb-2 profile-heading-font">
                          More about me
                        </p>
                        <Tabs
                          defaultActiveKey="skills"
                          id="uncontrolled-tab-example"
                          className={`m-0  more-about-tab ${props.activeColor}-nav `}
                        >
                          <Tab eventKey="skills" title="Skills">
                            {editableStatus.status === true ? (
                              <div className="mb-4 my-3">
                                <span
                                  onClick={(e) => generateNewSkill(e, "top")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    role="button"
                                    className="text-muted mx-2"
                                  />
                                  Add Skills
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="row justify-content-between flex-column skill-scroll">
                              {props.activeProfile.skill.length
                                ? props.activeProfile.skill.map(
                                    (skill, indexs) => {
                                      if (
                                        skill.userskill_show ||
                                        (editableStatus.status &&
                                          !skill.user_skill_show)
                                      ) {
                                        return (
                                          <MoreSkills
                                            index={indexs}
                                            skillData={skill}
                                            activeSkill={
                                              props.activeProfile.skill
                                            }
                                            editSkill={editSkill}
                                            skillChangeHandler={
                                              skillChangeHandler
                                            }
                                            editableStatus={editableStatus}
                                            showAndHideOption={
                                              showAndHideOption
                                            }
                                            showSpinner={showSpinner}
                                          />
                                        );
                                      }
                                    },
                                  )
                                : null}
                            </div>
                          </Tab>
                          <Tab eventKey="interests" title="Interests">
                            {editableStatus.status ? (
                              <div className="mb-4 my-3">
                                {showSpinner.status &&
                                showSpinner.id === "interest" ? (
                                  <CommanSpinner />
                                ) : (
                                  <span
                                    onClick={(e) =>
                                      showInterestModal("interest")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      className="text-muted mx-2"
                                      role="button"
                                    />
                                    Add Interest
                                  </span>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="row justify-content-between flex-column more-interest-scroll">
                              {interestData.length ? (
                                interestData.map((interest, intKey) => {
                                  if (
                                    interest.user_interests_show ||
                                    (editableStatus.status &&
                                      !interest.user_interests_show)
                                  ) {
                                    return (
                                      <Interests
                                        index={intKey}
                                        editableStatus={editableStatus}
                                        showInterestModal={showInterestModal}
                                        showSpinner={showSpinner}
                                        interestData={interest}
                                        allInterests={interestData}
                                        activeInterest={
                                          props.activeProfile.interest
                                        }
                                        showAndHideOption={showAndHideOption}
                                      />
                                    );
                                  }
                                })
                              ) : (
                                <p className="mt-3 mb-3">No data found</p>
                              )}
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      )}

      {/* Endorsements list modal */}
      <Modal
        show={showEndorseDetails.showModal}
        onHide={closeEndorseModal}
        backdrop="static"
        keyboard={false}
        className="tj-modal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Endorsements</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <>
            {endorseData.length > 0
              ? endorseData.map((endorse) => {
                  return (
                    <div className="row">
                      <div className="col-md-4 text-center">
                        <Link
                          to={`${"profile-view/"}${
                            endorse.profile_description.user_profile_url
                          }`}
                          target="_blank"
                        >{`${endorse.user.first_name}  ${endorse.user.last_name}`}</Link>
                      </div>
                      <div className="col-md-8 text-justify">
                        <h6>{endorse.profile_description.user_profile_name}</h6>
                      </div>
                    </div>
                  );
                })
              : "No endorsements"}
          </>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="success" onClick={closeEndorseModal}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Interest modal */}
      <Modal
        show={showInterestsModal.showModal}
        onHide={closeInterestModal}
        backdrop="static"
        keyboard={false}
        className="tj-modal int-h"
        size="lg"
      >
        <div>
          <>
            {!categoriesActive ? (
              <div className="interest-back mt-2">
                <FontAwesomeIcon
                  role="button"
                  icon={faAngleDoubleLeft}
                  size="1x"
                  onClick={setModalStatus}
                />
              </div>
            ) : (
              ""
            )}
            <div className="interest-mod-times mt-2 ">
              <FontAwesomeIcon
                role="button"
                icon={faTimes}
                size="1x"
                onClick={closeInterestModal}
              />
            </div>
            {categoriesActive ? (
              <img
                className="int-cat-img"
                src={
                  process.env.PUBLIC_URL + "/images/icons/interest-category.png"
                }
                alt=""
              />
            ) : (
              <img
                className="int-cat-img"
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/interest-subcategory.png"
                }
                alt=""
              />
            )}
            <div className="text-center mt-1">
              <div className="row">
                {categoriesActive ? (
                  <h4 className="about-me-weight">My Interest Category</h4>
                ) : (
                  <h4 className="about-me-weight">My Interest Sub Category</h4>
                )}
              </div>
              <div className="row">
                <small>Please select your area of interest</small>
              </div>
            </div>
            {/* interest categories */}
            {categoriesActive ? (
              <div className="int-card">
                <div className="row categories-height">
                  {interestModalData.length > 0
                    ? interestModalData.map((interest, iKey) => {
                        interest.isActive =
                          interests.indexOf(interest.interests_id) > -1 ? 1 : 0;
                        return (
                          <div className="col-xl-3 col-lg-4 col-6 mt-3 mb-2">
                            <button
                              key={iKey}
                              className={
                                `text-truncate btn default interest-btn-category mx-2 ` +
                                (interest.isActive
                                  ? iKey < 12
                                    ? colorSet1[iKey]
                                    : colorSet2[0]
                                  : "")
                              }
                              onClick={(e) =>
                                addInterestArray(e, interest, iKey)
                              }
                            >
                              {interest.interests_name}
                            </button>
                          </div>
                        );
                      })
                    : "No data found"}
                </div>
                {interestModalData.length ? (
                  <div className="row mt-5 mb-5">
                    <div className="col-md-4 mx-auto text-center">
                      <button
                        className="profile-gradient-btn btn btn-secondary btn-block rounded"
                        onClick={setModalStatus}
                        disabled={interests.length > 0 ? "" : "true"}
                      >
                        Continue
                      </button>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            {/* sub categories */}
            {subCategoriesActive ? (
              <>
                <div className="int-sub-card interest-scroll">
                  <div className="row mb-4">
                    {interestModalData.length
                      ? interestModalData.map((interest, k) => {
                          if (interests.includes(interest.interests_id)) {
                            return (
                              <div>
                                <div className="row sub-title">
                                  <h4 className="sub-int-title">
                                    {interest.interests_name}
                                  </h4>
                                </div>
                                <div className="row mb-4 int-bg  text-center">
                                  {interest.children.map((child, childKey) => {
                                    child.isSubActive =
                                      interestChildData.indexOf(
                                        child.interests_id,
                                      ) > -1
                                        ? 1
                                        : 0;
                                    return (
                                      <div className=" col-lg-4 col-md-6 col-12 mt-2 mb-1">
                                        <button
                                          className={
                                            `btn interest-btn default text-truncate ` +
                                            (child.isSubActive
                                              ? k % 2 === 0
                                                ? colorSet1[childKey]
                                                : colorSet2[childKey]
                                              : "")
                                          }
                                          onClick={(e) =>
                                            addSubCategories(
                                              child.interests_id,
                                              child,
                                              childKey,
                                              k,
                                              interest.interests_id,
                                            )
                                          }
                                        >
                                          {child.interests_name}
                                        </button>
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            );
                          }
                        })
                      : ""}
                  </div>
                </div>
                <ModalFooter className="justify-content-center">
                  <button
                    type="button"
                    className="btn text-center btn-sm interest-close-btn"
                    onClick={() => createInterest()}
                  >
                    Submit
                  </button>
                </ModalFooter>
              </>
            ) : (
              ""
            )}
          </>
        </div>
      </Modal>

      {/* interest alert modal */}
      <Modal
        show={showInterestAlertModal.showAlert}
        onHide={closeInterestAlertModal}
        backdrop="static"
        keyboard={false}
        centered
        className="tj-modal"
      >
        <FontAwesomeIcon
          icon={faTimes}
          role="button"
          className="text-muted interest-alert-times"
          onClick={() => closeInterestAlertModal()}
        />
        <Modal.Header className="d-flex">
          <p className="flex-grow-1 text-center interest-alert-text">
            Are you sure you want to continue without choosing the sub category
            for {missedInterests}?
          </p>
        </Modal.Header>

        <Modal.Footer className="justify-content-center">
          <button
            type="button"
            className="btn btn-primary global-blue mx-2 btn-sm"
            onClick={submitInterestForm}
          >
            Continue
          </button>
          <button
            type="button"
            className="btn btn-outline-danger btn-sm"
            onClick={closeInterestAlertModal}
          >
            No
          </button>
        </Modal.Footer>
      </Modal>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  allInterests: state.profile.allInterests,
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  endorseDetails: state.profile.endorseDetails,
  interest: state.profile.interest,
  profile: state.profile.profile,
  image: state.profile.image,
});

export default connect(mapStateToProps, {
  getProfileDetails,
  setActiveProfile,
  addUserProfile,
  setActiveColor,
  setDarkTheme,
  trainingExperience,
  jobExperience,
  qualification,
  certificate,
  award,
  skill,
  description,
  recordHide,
  createNewProfile,
  sectionShow,
  endorse,
  getEndorseDetails,
  editProfileMenu,
  addInterest,
  getInterests,
  getProfile,
  setImages,
})(Profile);

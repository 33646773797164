import React from 'react';

import { useDrag, useDrop } from 'react-dnd';

import { ItemTypes } from './ItemTypes';

const Card = ({ id, text, moveCard, findCard, toggleCard, menu, index, activeColor }) => {
  const originalIndex = findCard(id).index;

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: { id:id, originalIndex },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  //    end: (item, monitor) => {
  //     const { id: droppedId, originalIndex } = monitor.getItem();
  //     const didDrop = monitor.didDrop();
  //     if (!didDrop) {
  //       moveCard(droppedId, originalIndex);
  //     }
  // },
  });

  const [, drop] = useDrop({
    accept: ItemTypes.CARD,
    canDrop: () => false,
    hover({ id: draggedId }) {
      if (draggedId !== id) {
        const { index: hoverIndex } = findCard(id);
        moveCard(draggedId, hoverIndex);
        // console.log(hoverIndex)
      }
    },
  });

  const opacity = isDragging ? 0 : 1;
  const cursor = 'move';

  return (
    <div className={`right-menu ` + ((menu.enabled) ? activeColor : '')} style={{ cursor, opacity }} ref={(text === 'Course Code' || text === 'Course Name') ? null : (node) => drag(drop(node))}>
      <div className='labels-font' onClick={(e) => toggleCard(menu, index)}  role='button'>
        {text}
      </div>
    </div>
  );
};

export default Card;
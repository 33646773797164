import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import RightMenus from './RightMenus';

const DAndDRightMenu = (props) => {
  return (
    <DndProvider backend={HTML5Backend}>
      <div className="">
        <RightMenus allData={props} />
      </div>
    </DndProvider>
  );
}

export default DAndDRightMenu;
import React, { Suspense, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import CommanSpinner from "../../components/spinner/CommanSpinner";
import ProfileService from "../../services/profile.service";
import LoginModal from "./LoginModal";
import Cookies from "js-cookie";

const UploadCVModal = (props) => {
  let countClick = Cookies.get("count") === "false" ? false : true;

  const [showModal, setShowModal] = useState(false);
  const [identifier, setIdentifier] = useState("");

  useEffect(() => {
    // Retrieve or generate unique identifier from cookie
    let id = Cookies.get("userIdentifier");
    if (!id) {
      id = `user_${Date.now()}`;
      Cookies.set("userIdentifier", id, { expires: 365 });
    }
    setIdentifier(id);
  }, []);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);
  const handleApiCall = async (e) => {
    try {
      const resultData = await ProfileService.buttonClick(identifier);
      if (resultData) {
        props.setFileData(e, "resume");
        Cookies.set("count", true);
      } else {
        handleShow();
        Cookies.set("count", false);
      }
    } catch (error) {
      console.error("Error during API call:", error);
    }
  };

  return (
    <>
      <Modal show={props.showModal} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title className="w-100 text-center">
            Upload Your CV
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.showSpinner.status && props.showSpinner.id === "resume" ? (
            <label className={`btn btn-block btn-global-blue`}>
              <CommanSpinner />
            </label>
          ) : (
            <label
              className={`btn btn-block profile-btn prfl-details-none ${
                !countClick ? "btn-primary disabled" : "btn-global-blue"
              }`}
              onClick={() => (!countClick ? handleShow() : "")}
            >
              <b>Upload CV</b>
              <input
                type="file"
                accept="application/msword, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                name="resumeUpload"
                className="d-none"
                onChange={(e) => handleApiCall(e)}
                disabled={!countClick}
              />
            </label>
          )}
          <small className="prfl-details-none">(*.pdf;*.doc)</small>
        </Modal.Body>
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer> */}
      </Modal>
      <Suspense fallback={""}>
        <LoginModal show={showModal} handleClose={handleClose} />
      </Suspense>
    </>
  );
};

export default UploadCVModal;

export const defaultMenus = ([{
    name: 'Course Code',
    type: 'string',
    enabled: 1
  },
  {
    name: "Course Name",
    type: 'string',
    enabled: 1
  },
  {
    name: "Training Category",
    type: 'training_category',
    enabled: 1
  },
  {
    name: "Course / Program Title",
    type: 'string',
    enabled: 1
  },
  {
    name: "Course Short description",
    type: 'string',
    enabled: 1
  },
  {
    name: "Language",
    type: 'language',
    enabled: 1
  },
  {
    name: "Level",
    type: 'level',
    enabled: 1
  },
  {
    name: "Feedback Status",
    type: 'string',
    enabled: 1
  }
])

export const planMenus = ([{
    name: "Course Name",
    type: 'string'
  },
  {
    name: "Training Category",
    type: 'training_category'
  },
  {
    name: "Course / Program Title",
    type: 'string'
  },
  {
    name: "Course Short description",
    type: 'string'
  },
  {
    name: "Language",
    type: 'language'
  },
  {
    name: "Level",
    type: 'level'
  },
  {
    name: "Pre-requisite",
    type: 'string'
  },
  {
    name: "Activity (Online Training / Class Room Training)",
    type: 'string'
  },
  {
    name: "Batch Number",
    type: 'batch_type'
  },
  {
    name: "Batch Name",
    type: 'batch_type'
  },
  {
    name: "Planned Students / Participants",
    type: 'batch_type'
  }
])

export const batchMenus = ([{
    name: "Course Name",
    type: 'string'
  },
  {
    name: "Training Category",
    type: 'training_category'
  },
  {
    name: "Course / Program Title",
    type: 'string'
  },
  {
    name: "Course Short description",
    type: 'string'
  },
  {
    name: "Language",
    type: 'language'
  },
  {
    name: "Level",
    type: 'level'
  },
  {
    name: "Batch Number",
    type: 'batch_type'
  },
  {
    name: "Batch Name",
    type: 'batch_type'
  },
  {
    name: "Planned Students / Participants",
    type: 'batch_type'
  },
  {
    name: "Currency",
    type: 'currency'
  },
  {
    name: "Course Fee",
    type: 'currency_format'
  },
  {
    name: "Program Status",
    type: 'string'
  },
  {
    name: "Free / Paid",
    type: 'batch_type'
  }
])

export const associateMenus = ([{
    name: "Course Name",
    type: 'string'
  },
  {
    name: "Training Category",
    type: 'training_category'
  },
  {
    name: "Course / Program Title",
    type: 'string'
  },
  {
    name: "Language",
    type: 'language'
  },
  {
    name: "Level",
    type: 'level'
  },
  {
    name: "Batch Name",
    type: 'batch_type'
  },
  {
    name: "Organization",
    type: 'string'
  },
  {
    name: "Associates / Vendor",
    type: 'string'
  },
  {
    name: "Planned Students / Participants",
    type: 'batch_type'
  }
])

export const financeMenus = ([{
    name: "Course Name",
    type: 'string'
  },
  {
    name: "Training Category",
    type: 'training_category'
  },
  {
    name: "Course / Program Title",
    type: 'string'
  },
  {
    name: "Language",
    type: 'language'
  },
  {
    name: "Level",
    type: 'level'
  },
  {
    name: "Activity (Online Training / Class Room Training)",
    type: 'string'
  },
  {
    name: "Batch Name",
    type: 'batch_type'
  },
  {
    name: "Organization",
    type: 'string'
  },
  {
    name: "Associates / Vendor",
    type: 'string'
  },
  {
    name: "Students / Participants attended",
    type: 'string'
  },
  {
    name: "Program Status",
    type: 'string'
  },
  {
    name: "Free / Paid",
    type: 'batch_type'
  },
  {
    name: "Currency",
    type: 'currency'
  },
  {
    name: "Course Fee",
    type: 'currency_format'
  },
  {
    name: "Pending",
    type: 'string'
  },
  {
    name: "Payment status",
    type: 'string'
  }
])

export const feedbackMenus = ([{
    name: "Course Name",
    type: 'string'
  },
  {
    name: "Training Category",
    type: 'training_category'
  },
  {
    name: "Course / Program Title",
    type: 'string'
  },
  {
    name: "Language",
    type: 'language'
  },
  {
    name: "Level",
    type: 'level'
  },
  {
    name: "Activity (Online Training / Class Room Training)",
    type: 'string'
  },
  {
    name: "Batch Number",
    type: 'batch_type'
  },
  {
    name: "Batch Name",
    type: 'batch_type'
  },
  {
    name: "Organization",
    type: 'string'
  },
  {
    name: "Associates / Vendor",
    type: 'string'
  },
  {
    name: "Planned Students / Participants",
    type: 'batch_type'
  },
  {
    name: "Students / Participants attended",
    type: 'string'
  },
  {
    name: "Program Status",
    type: 'string'
  },
  {
    name: "Feedback Status",
    type: 'string'
  },
  {
    name: "Feedback Rating",
    type: 'string'
  },
  {
    name: "Upload Feedback Doc proof",
    type: 'string'
  }
])
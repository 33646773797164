import { useState, useEffect } from "react";

import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import { useLocation, useHistory } from "react-router-dom";

import Login from "./Login";
import Register from "./Register";
import CarouselData from "./CarouselData";
import CommonService from "../../services/common.service";
import { getUser } from "../../utils/UserDetails";
import { caroseldata, breakPoints } from "../../constants/DefaultValues";

const AuthForm = () => {
  const history = useHistory();
  const location = useLocation();
  const currentUser = getUser();

  const [carouselDetails, setCarouselDetails] = useState([]);
  const [viewLogin, setViewLogin] = useState(true);
  const [masterRoles, setMasterRoles] = useState([]);
  const [activeRole, setActiveRole] = useState(null);
  const [currentWordIndex, setCurrentWordIndex] = useState(0);
  const [currentText, setCurrentText] = useState("");
  const [isDeleting, setIsDeleting] = useState(false);
  const words = ["Company", "Recruiter", "Student", "Trainer"]; // Array of words to display
  const [typingSpeed, settypingSpeed] = useState("250");

  // const typingSpeed = 1000; // Adjust typing speed as needed

  useEffect(() => {
    let windowview = location.pathname.split("/").pop();

    // if(currentUser) {
    //   history.push("/home")
    // }

    if (windowview && windowview === "register") {
      setViewLogin(false);
    }

    getRoles();
    setCarouselDetails(caroseldata);
  }, []);

  useEffect(() => {
    const updateTypewriter = () => {
      const currentWord = words[currentWordIndex];
      let nextText = currentText;
      settypingSpeed(250);
      if (isDeleting) {
        nextText = currentWord.substring(0, nextText.length - 1);
      } else {
        nextText = currentWord.substring(0, nextText.length + 1);
      }

      if (!isDeleting && nextText === currentWord) {
        setIsDeleting(true);
        settypingSpeed(1000); // Pause after completing typing
      } else if (isDeleting && nextText === "") {
        setIsDeleting(false);
        setCurrentWordIndex((prevIndex) => (prevIndex + 1) % words.length);
        settypingSpeed(250); // Pause before next typing
      }
      setCurrentText(nextText);
    };

    const typingTimer = setTimeout(updateTypewriter, typingSpeed);

    return () => clearTimeout(typingTimer);
  }, [currentText, currentWordIndex, isDeleting, words, typingSpeed]);

  const getRoles = async () => {
    let resultData = await CommonService.getRoles();

    if (resultData && resultData.status === 2000) {
      setMasterRoles(resultData.data);
    }
  };

  const setRolesActive = (obj, roleKey) => {
    console.log(obj);
    let roles = [...masterRoles];
    setActiveRole(obj.roles_master_code);
    roles.map((v) => {
      v.isActive = false;
    });
    roles[roleKey]["isActive"] = obj.isActive ? false : true;
    setMasterRoles(roles);
  };

  return (
    <div className="auth container mt-4">
      <div className="row login ">
        <div className="col-md-8 login-left-container">
          <div className="tjunction-logo">
            <Link to="/">
              <img src={process.env.PUBLIC_URL + "/images/logo.png"} />
            </Link>
          </div>
          <div className="tjunction-description pt-4">
            <h2 className="tjunction-description-heading">
              Your platform for{" "}
              <span data-period="2000" data-type={JSON.stringify(words)}>
                {currentText}{" "}
              </span>
            </h2>
            <div className="tjunction-description-content ">
              Trainers, Tutors, Teachers, Trainees, Tutees! T Junction is a
              platform for all your training needs.be it cooking, stitching,
              photography ...to cloud, ai, data science.. to maths, physics,
              chemistry tuitions... prepare your own curriculum, upload your
              training aterials and start your own online / classroom / home.
            </div>
          </div>
          {viewLogin ? (
            <>
              {/* <div className="image-container col-md-6">
                <img
                  src={process.env.PUBLIC_URL + "/images/icons/login2-icon.svg"}
                  className="login-cover-image"
                />
              </div> */}

              <div className="container testimonial">
                <div className="row">
                  <div className="col">
                    <h4 className="mb-3">Our Testimonials</h4>
                  </div>
                </div>
                <Carousel
                  breakPoints={breakPoints}
                  enableAutoPlay
                  autoPlaySpeed={1500}
                >
                  {carouselDetails.map((item, key) => {
                    return <CarouselData item={item} key={key} />;
                  })}
                </Carousel>
              </div>
            </>
          ) : (
            <div className="shadow-explore register-category">
              <h5 className=" my-0 text-center">
                <b className=" fw-bolder">
                  {" "}
                  Choose the below option to explore more before sign up
                </b>
              </h5>
              <div className="categories-list  row">
                {masterRoles.map((roles, roleKey) => {
                  return (
                    <div className="col-4">
                      <div
                        className={
                          `categories   py-1 w-75  bg-img-${roleKey + 1} ` +
                          (roles.isActive ? `active` : ``)
                        }
                        onClick={() => setRolesActive(roles, roleKey)}
                      >
                        <h6 className="categories-title p-0 mx-1  my-2">
                          {roles.roles_master_name}
                        </h6>
                      </div>
                      <div className="  ">
                        {roles.roles_master_code == "TRAINER" && (
                          <>
                            <ol>
                              <li className="my-2">
                                {" "}
                                <Link to="#" className="">
                                  Create Curriculum
                                </Link>
                              </li>
                              <li className="my-2">
                                <Link to="#">Create Content</Link>
                              </li>
                            </ol>
                          </>
                        )}
                        {roles.roles_master_code == "STUDENT" && (
                          <>
                            <ol>
                              {" "}
                              <li className="my-2">
                                <Link to="#">Career Road Map</Link>
                              </li>
                              <li className="my-2">
                                <Link to="#">Profile Match to the JD</Link>
                              </li>
                            </ol>
                          </>
                        )}
                        {roles.roles_master_code == "RECRUITER" && (
                          <>
                            <ol>
                              <li className="my-2">
                                <Link to="#">JD based folder allocation</Link>
                              </li>
                              <li className="my-2">
                                <Link to="#">
                                  JD enhancement and JD creation
                                </Link>
                              </li>
                              <li className="my-2">
                                <Link to="#">JD based resource ranking </Link>
                              </li>
                              <li className="my-2">
                                <Link to="#">
                                  {" "}
                                  JD (job description) creation
                                </Link>
                              </li>
                            </ol>
                          </>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="row ">
                <div className="col-4"></div>
                <div className="col-4 " style={{ height: "35px" }}>
                  <div className="ms-2  py-1">
                    <Link
                      to={
                        activeRole
                          ? `/explore/${activeRole?.toLowerCase()}`
                          : "#"
                      }
                      className={`btn btn-outline-primary font-weight-bold w-75 py-2 ${
                        !activeRole ? "disabled-link" : ""
                      }`}
                      style={
                        !activeRole
                          ? { pointerEvents: "none", opacity: 0.6 }
                          : {}
                      }
                    >
                      Click to explore
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-4 auth-container ">
          {viewLogin ? (
            <Login
              setViewLogin={setViewLogin}
              masterRoles={masterRoles}
              activeRole={activeRole}
              setMasterRoles={setMasterRoles}
            />
          ) : (
            <Register
              setViewLogin={setViewLogin}
              masterRoles={masterRoles}
              activeRole={activeRole}
              setRolesActive={setRolesActive}
              setMasterRoles={setMasterRoles}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthForm;

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as moment from "moment";
import renderHTML from "react-render-html";

import Trash from "../trash/Trash";

const JobExperience = (props) => {
  let descriptionIsVisible = 0;
  let designationIsVisible = 0;
  let roleIsVisible = 0;
  let companyIsVisible = 0;
  let fromIsVisible = 0;
  let toIsVisible = 0;

  if (props.jobData.user_job_experience_description) {
    descriptionIsVisible = 1;
  }
  if (props.jobData.user_job_experience_role) {
    roleIsVisible = 1;
  }
  if (props.jobData.user_job_experience_designation) {
    designationIsVisible = 1;
  }

  if (props.jobData.user_job_experience_from) {
    fromIsVisible = 1;
  }

  if (props.jobData.user_job_experience_to) {
    toIsVisible = 1;
  }

  if (props.jobData.user_job_experience_company_name) {
    companyIsVisible = 1;
  }

  if (props.editableStatus.status) {
    descriptionIsVisible = 1;
    designationIsVisible = 1;
    roleIsVisible = 1;
    fromIsVisible = 1;
    toIsVisible = 1;
    companyIsVisible = 1;
  }
  const handleBlur = () => {
    if (
      props.jobData.user_job_experience_company_name !== null &&
      props.jobData.user_job_experience_designation &&
      props.jobData.user_job_experience_role &&
      props.jobData.user_job_experience_from !== null &&
      props.jobData.user_job_experience_to !== null &&
      props.jobData.user_job_experience_description
    ) {
      props.editJobExperience(props.index, props.jobData);
    }
  };

  return (
    <div className={!props.editableStatus.status ? "" : "card mb-4"}>
      {designationIsVisible ||
      fromIsVisible ||
      toIsVisible ||
      companyIsVisible ? (
        <div className={!props.editableStatus.status ? "" : "card-header p-0 "}>
          <div className="col hide-resp profile-hide-view-css">
            {props.editableStatus.status ? (
              <>
                <div
                  className={
                    props.jobData.user_job_experience_show === 1
                      ? "checkbox-container-off"
                      : "checkbox-container"
                  }
                >
                  <input
                    type="checkbox"
                    id={"checkbox-job-exp-" + props.index}
                    onChange={(e) =>
                      props.showAndHideOption(
                        e.currentTarget.checked,
                        props.jobData.user_job_experience_id,
                        "job",
                        props.index,
                      )
                    }
                    name="jobExperienceShowHide"
                    defaultChecked={
                      props.jobData.user_job_experience_show === 1
                        ? false
                        : true
                    }
                  />
                  <label htmlFor={"checkbox-job-exp-" + props.index}>
                    <span
                      className={
                        props.jobData.user_job_experience_show === 1
                          ? "inner-hide-text-position1"
                          : "inner-hide-text-position"
                      }
                    >
                      Hide
                    </span>
                  </label>
                </div>
                {props.activeJobExperience.length > 1 ? (
                  <div className="job-trash trash">
                    <Trash
                      id={props.jobData.user_job_experience_id}
                      index={props.index}
                      source="job"
                      active={props.activeJobExperience}
                    />
                  </div>
                ) : (
                  <div className="job-trash trash trash-muted-css">
                    <Trash muted={true} />
                  </div>
                )}
              </>
            ) : (
              <div className="checkbox-container"></div>
            )}
          </div>
          <div className="d-flex flex-md-wrap flex-sm-wrap flex-column flex-lg-row flex-md-row flex-sm-row gap-1 mx-auto align-items-center">
            {companyIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto width-css ` +
                  (props.editableStatus.status &&
                  !props.jobData.user_job_experience_show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    Company name{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="text"
                    key={`jobcomp${props.index}`}
                    className={
                      `form-control py-1 my-2 text-center ` +
                      (props.editableStatus.status &&
                      !props.jobData.user_job_experience_show
                        ? "hide-opacity"
                        : "")
                    }
                    name="user_job_experience_company_name"
                    value={
                      props.jobData.user_job_experience_company_name
                        ? props.jobData.user_job_experience_company_name
                        : ""
                    }
                    title={props.jobData.user_job_experience_company_name}
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.jobChangeHandler(e, "text", props.index)
                    }
                  />
                ) : (
                  <p className="tab-sub-heading">
                    {props.jobData.user_job_experience_company_name
                      ? props.jobData.user_job_experience_company_name
                      : ""}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-4"></div>
            )}
            {designationIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto  width-css ` +
                  (props.editableStatus.status &&
                  !props.jobData.user_job_experience_show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    Designation{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="text"
                    key={`jobdesig${props.index}`}
                    title={props.jobData.user_job_experience_designation}
                    className={
                      `form-control py-1 my-2 text-center ` +
                      (props.editableStatus.status &&
                      !props.jobData.user_job_experience_show
                        ? "hide-opacity"
                        : "")
                    }
                    name="user_job_experience_designation"
                    value={
                      props.jobData.user_job_experience_designation
                        ? props.jobData.user_job_experience_designation
                        : ""
                    }
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.jobChangeHandler(e, "text", props.index)
                    }
                  />
                ) : (
                  <p className="px-2">
                    {props.jobData.user_job_experience_designation
                      ? props.jobData.user_job_experience_designation
                      : ""}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-4 px-4"></div>
            )}

            {roleIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto  width-css ` +
                  (props.editableStatus.status &&
                  !props.jobData.user_job_experience_show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    Role{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="text"
                    key={`jobdesig${props.index}`}
                    className={
                      `form-control py-1 my-2 text-center ` +
                      (props.editableStatus.status &&
                      !props.jobData.user_job_experience_show
                        ? "hide-opacity"
                        : "")
                    }
                    name="user_job_experience_role"
                    title={props.jobData.user_job_experience_role}
                    value={
                      props.jobData.user_job_experience_role
                        ? props.jobData.user_job_experience_role
                        : ""
                    }
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.jobChangeHandler(e, "text", props.index)
                    }
                  />
                ) : (
                  <p className="px-2">
                    {props.jobData.user_job_experience_role
                      ? props.jobData.user_job_experience_role
                      : ""}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-4 px-4"></div>
            )}

            {fromIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto  width-css ` +
                  (props.editableStatus.status &&
                  !props.jobData.user_job_experience_show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    From{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="date"
                    className={
                      `form-control py-1 my-2 text-center date ` +
                      (props.editableStatus.status &&
                      !props.jobData.user_job_experience_show
                        ? "hide-opacity"
                        : "")
                    }
                    name="user_job_experience_from"
                    value={
                      props.jobData.user_job_experience_from
                        ? props.jobData.user_job_experience_from
                        : ""
                    }
                    onChange={(e) =>
                      props.jobChangeHandler(e, "text", props.index)
                    }
                    onBlur={() => handleBlur()}
                  />
                ) : (
                  <p className="sub-tab-date">
                    {" "}
                    {props.jobData.user_job_experience_from !== null
                      ? moment(props.jobData.user_job_experience_from).format(
                          "MMM-YYYY",
                        )
                      : ""}{" "}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-2 px-2"></div>
            )}

            {toIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto  width-css ` +
                  (props.editableStatus.status &&
                  !props.jobData.user_job_experience_show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    To{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="date"
                    className={
                      `form-control py-1 my-2 text-center date ` +
                      (props.editableStatus.status &&
                      !props.jobData.user_job_experience_show
                        ? "hide-opacity"
                        : "")
                    }
                    name="user_job_experience_to"
                    value={
                      props.jobData.user_job_experience_to
                        ? props.jobData.user_job_experience_to
                        : ""
                    }
                    onChange={(e) =>
                      props.jobChangeHandler(e, "text", props.index)
                    }
                    onBlur={() => handleBlur()}
                  />
                ) : (
                  <p className="sub-tab-date">
                    {" "}
                    {props.jobData.user_job_experience_to !== null
                      ? moment(props.jobData.user_job_experience_to).format(
                          "MMM-YYYY",
                        )
                      : ""}{" "}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-2 px-2"></div>
            )}
          </div>
        </div>
      ) : (
        "No job experience details found"
      )}

      {/* description section */}
      <div>
        {descriptionIsVisible ? (
          <div className="card-body p-1 desc-resp">
            <div className="row">
              <div className="col-md-12">
                {props.editableStatus.status ? (
                  <ReactQuill
                    className={
                      props.editableStatus.status &&
                      !props.jobData.user_job_experience_show
                        ? "hide-opacity"
                        : ""
                    }
                    key={`jobdesc${props.index}`}
                    name="user_job_experience_description"
                    modules={props.modules}
                    formats={props.formats}
                    value={props.jobData.user_job_experience_description}
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.jobChangeHandler(e, "editor", props.index)
                    }
                  />
                ) : (
                  <p className="">
                    {props.jobData.user_job_experience_description
                      ? renderHTML(
                          props.jobData.user_job_experience_description,
                        )
                      : ""}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default JobExperience;

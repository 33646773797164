import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import * as moment from "moment";
import renderHTML from "react-render-html";

import Trash from "../trash/Trash";

const Qualification = (props) => {
  let descriptionIsVisible = 0;
  let degreeIsVisible = 0;
  let specificationIsVisible = 0;
  let collegeIsVisible = 0;
  let fromIsVisible = 0;
  let toIsVisible = 0;

  if (props.qualificationData.userqualification_subject) {
    descriptionIsVisible = 1;
  }
  if (props.qualificationData.userqualification_specification) {
    specificationIsVisible = 1;
  }
  if (props.qualificationData.userqualification_name) {
    degreeIsVisible = 1;
  }

  if (props.qualificationData.userqualification_year_start) {
    fromIsVisible = 1;
  }

  if (props.qualificationData.userqualification_year_end) {
    toIsVisible = 1;
  }

  if (props.qualificationData.userqualification_institution_name) {
    collegeIsVisible = 1;
  }

  if (props.editableStatus.status) {
    descriptionIsVisible = 1;
    degreeIsVisible = 1;
    specificationIsVisible = 1;
    fromIsVisible = 1;
    toIsVisible = 1;
    collegeIsVisible = 1;
  }

  const handleBlur = () => {
    if (
      props.qualificationData.userqualification_institution_name !== null &&
      props.qualificationData.userqualification_name !== null &&
      props.qualificationData.userqualification_specification !== null &&
      props.qualificationData.userqualification_year_start !== null &&
      props.qualificationData.userqualification_year_end !== null &&
      props.qualificationData.userqualification_subject
    ) {
      props.editQualification(props.index, props.qualificationData);
    }
  };

  return (
    <div className={!props.editableStatus.status ? "" : "card mb-4"}>
      {degreeIsVisible ||
      fromIsVisible ||
      toIsVisible ||
      collegeIsVisible ||
      specificationIsVisible ? (
        <div className={!props.editableStatus.status ? "" : "card-header p-0"}>
          <div className="col hide-resp profile-hide-view-css">
            {props.editableStatus.status ? (
              <>
                <div
                  className={
                    props.qualificationData.userqualification__show === 1
                      ? "checkbox-container-off"
                      : "checkbox-container"
                  }
                >
                  <input
                    type="checkbox"
                    id={"checkbox-qual-" + props.index}
                    onChange={(e) =>
                      props.showAndHideOption(
                        e.currentTarget.checked,
                        props.qualificationData.userqualification_id,
                        "qualification",
                        props.index,
                      )
                    }
                    name="qualificationShowHide"
                    defaultChecked={
                      props.qualificationData.userqualification__show === 1
                        ? false
                        : true
                    }
                  />
                  <label htmlFor={"checkbox-qual-" + props.index}>
                    <span
                      className={
                        props.qualificationData.userqualification__show === 1
                          ? "inner-hide-text-position1"
                          : "inner-hide-text-position"
                      }
                    >
                      Hide
                    </span>
                  </label>
                </div>
                {props.activeQualification.length > 1 ? (
                  <div className="job-trash trash">
                    <Trash
                      id={props.qualificationData.userqualification_id}
                      index={props.index}
                      source="qualification"
                      active={props.activeQualification}
                    />
                  </div>
                ) : (
                  <div className="job-trash trash trash-muted-css">
                    <Trash muted={true} />
                  </div>
                )}
              </>
            ) : (
              <div className="checkbox-container"></div>
            )}
          </div>
          <div className="d-flex flex-md-wrap flex-sm-wrap flex-column flex-lg-row flex-md-row flex-sm-row gap-1 mx-auto align-items-center">
            {collegeIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto width-css ` +
                  (props.editableStatus.status &&
                  !props.qualificationData.userqualification__show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    Institution name{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="text"
                    key={`qualins${props.index}`}
                    className={
                      `form-control py-1 my-2 text-center ` +
                      (props.editableStatus.status &&
                      !props.qualificationData.userqualification__show
                        ? "hide-opacity"
                        : "")
                    }
                    name="userqualification_institution_name"
                    value={
                      props.qualificationData.userqualification_institution_name
                        ? props.qualificationData
                            .userqualification_institution_name
                        : ""
                    }
                    title={
                      props.qualificationData.userqualification_institution_name
                    }
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.qualificationChangeHandler(e, "text", props.index)
                    }
                  />
                ) : (
                  <p className="tab-sub-heading">
                    {props.qualificationData.userqualification_institution_name
                      ? props.qualificationData
                          .userqualification_institution_name
                      : ""}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-4"></div>
            )}
            {degreeIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto width-css ` +
                  (props.editableStatus.status &&
                  !props.qualificationData.userqualification__show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    Degree{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="text"
                    key={`qualdeg${props.index}`}
                    className={
                      `form-control py-1 my-2 text-center ` +
                      (props.editableStatus.status &&
                      !props.qualificationData.userqualification__show
                        ? "hide-opacity"
                        : "")
                    }
                    name="userqualification_name"
                    title={props.qualificationData.userqualification_name}
                    value={
                      props.qualificationData.userqualification_name
                        ? props.qualificationData.userqualification_name
                        : ""
                    }
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.qualificationChangeHandler(e, "text", props.index)
                    }
                  />
                ) : (
                  <p className="">
                    {props.qualificationData.userqualification_name
                      ? props.qualificationData.userqualification_name
                      : ""}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-4 px-4"></div>
            )}

            {specificationIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto width-css ` +
                  (props.editableStatus.status &&
                  !props.qualificationData.userqualification__show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    Specification{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="text"
                    key={`qualdeg${props.index}`}
                    className={
                      `form-control py-1 my-2 text-center ` +
                      (props.editableStatus.status &&
                      !props.qualificationData.userqualification__show
                        ? "hide-opacity"
                        : "")
                    }
                    name="userqualification_specification"
                    title={
                      props.qualificationData.userqualification_specification
                    }
                    value={
                      props.qualificationData.userqualification_specification
                        ? props.qualificationData
                            .userqualification_specification
                        : ""
                    }
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.qualificationChangeHandler(e, "text", props.index)
                    }
                  />
                ) : (
                  <p className="">
                    {props.qualificationData.userqualification_specification
                      ? props.qualificationData.userqualification_specification
                      : ""}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-4 px-4"></div>
            )}

            {fromIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto width-css ` +
                  (props.editableStatus.status &&
                  !props.qualificationData.userqualification__show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    From{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="date"
                    className={
                      `form-control py-1 my-2 text-center date-width date ` +
                      (props.editableStatus.status &&
                      !props.qualificationData.userqualification__show
                        ? "hide-opacity"
                        : "")
                    }
                    name="userqualification_year_start"
                    value={
                      props.qualificationData.userqualification_year_start
                        ? props.qualificationData.userqualification_year_start
                        : ""
                    }
                    onChange={(e) =>
                      props.qualificationChangeHandler(e, "text", props.index)
                    }
                    onBlur={() => handleBlur()}
                  />
                ) : (
                  <p className="sub-tab-date">
                    {" "}
                    {props.qualificationData.userqualification_year_start !==
                    null
                      ? moment(
                          props.qualificationData.userqualification_year_start,
                        ).format("MMM-YYYY")
                      : ""}{" "}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-2 px-2"></div>
            )}

            {toIsVisible ? (
              <div
                className={
                  `flex-lg-fill flex-md-fill flex-sm-fill mx-auto width-css ` +
                  (props.editableStatus.status &&
                  !props.qualificationData.userqualification__show
                    ? "hide-opacity"
                    : "")
                }
              >
                {props.editableStatus.status ? (
                  <label className="profile-form-label justify-content-center d-flex card-labels-size">
                    {" "}
                    To{" "}
                  </label>
                ) : (
                  ""
                )}
                {props.editableStatus.status ? (
                  <input
                    type="date"
                    className={
                      `form-control py-1 my-2 text-center date-width date ` +
                      (props.editableStatus.status &&
                      !props.qualificationData.userqualification__show
                        ? "hide-opacity"
                        : "")
                    }
                    name="userqualification_year_end"
                    value={
                      props.qualificationData.userqualification_year_end
                        ? props.qualificationData.userqualification_year_end
                        : ""
                    }
                    onChange={(e) =>
                      props.qualificationChangeHandler(e, "text", props.index)
                    }
                    onBlur={() => handleBlur()}
                  />
                ) : (
                  <p className="sub-tab-date">
                    {" "}
                    {props.qualificationData.userqualification_year_end !== null
                      ? moment(
                          props.qualificationData.userqualification_year_end,
                        ).format("MMM-YYYY")
                      : ""}{" "}
                  </p>
                )}
              </div>
            ) : (
              <div className="col-md-2 px-2"></div>
            )}
          </div>
        </div>
      ) : (
        "No qualification details found"
      )}

      {/* description section */}
      <div>
        {descriptionIsVisible ? (
          <div className="card-body p-1 desc-resp">
            <div className="row">
              <div className="col-md-12">
                {props.editableStatus.status ? (
                  <ReactQuill
                    className={
                      props.editableStatus.status &&
                      !props.qualificationData.userqualification__show
                        ? "hide-opacity"
                        : ""
                    }
                    key={`qualdesc${props.index}`}
                    name="userqualification_subject"
                    modules={props.modules}
                    formats={props.formats}
                    value={props.qualificationData.userqualification_subject}
                    onBlur={() => handleBlur()}
                    onChange={(e) =>
                      props.qualificationChangeHandler(e, "editor", props.index)
                    }
                  />
                ) : (
                  <p className="">
                    {props.qualificationData.userqualification_subject
                      ? renderHTML(
                          props.qualificationData.userqualification_subject,
                        )
                      : ""}
                  </p>
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Qualification;

import React, { useState, useEffect, Suspense } from "react";

import { Link, useLocation, useHistory } from "react-router-dom";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { BiHeart, BiPlus } from "react-icons/bi";
import {
  BsJournalText,
  BsCameraVideo,
  BsPersonWorkspace,
  BsBriefcase,
  BsBook,
  BsCloud,
  BsPersonCheck,
  BsCircle,
} from "react-icons/bs";

import CustomUserLinkModal from "../Trainers/dashboard/FeedLeftMenu/CustomUserLinkModal";
import CommonIcons from "../../components/CommonIcons";
import { getUser, getUserMenus, getUserRole } from "../../utils/UserDetails";
import {
  getCustomUserLink,
  setCustomUserLinkModal,
} from "../../actions/customUserLink";
import {
  setTrainingApplyDetails,
  AppliedDetails,
  RecruiterAppliedDetails,
  uploadCv,
} from "../../actions/reqTraining";
import { feedSidebarIsCollapsed } from "../../actions/common";
import { getZoomConnect } from "../../actions/zoomMeeting";

// import TrainingApplyDetailsModal from "./TrainingApplyDetailsModal";

// import TrainingCourse from './TrainingCourse';

const CustomUserLink = React.lazy(() =>
  import("../Trainers/dashboard/FeedLeftMenu/CustomUserLink"),
);
// const ZoomMeeting = React.lazy(() => import("./ZoomMeeting/ZoomMeeting"));
// const TrainingCourse = React.lazy(() =>
//   import("./TrainingCourse/TrainingCourse"),
// );

const FeedLeftMenu = (props) => {
  const currentUser = getUser();

  const history = useHistory();
  const { role } = useParams();
  const search = useLocation().search;
  const queryParams = new URLSearchParams(search);
  const zoomRedirectCode = queryParams.get("code");

  const [isExpanded, setIsExpanded] = useState(null);
  const [collapseHover, setCollapseHover] = useState(null);
  const [menuHover, setMenuHover] = useState(null);
  const [activeMenu, setActiveMenu] = useState(null);
  const [customUserMenu, setCustomUserMenu] = useState(null);
  const [showResults, setShowResults] = useState(false);
  const [showCv, setShowCv] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  const [isMyFavOpen, setIsMyFavOpen] = useState(true);
  const [isMyPageOpen, setIsMyPageOpen] = useState(false);
  const [isVidOpen, setIsVidOpen] = useState(true);
  const [isMyTrainingOpen, setIsMyTrainingOpen] = useState(true);
  const [isMyJobsOpen, setIsMyJobsOpen] = useState(false);
  const [isMyLearningsOpen, setIsMyLearningsOpen] = useState(false);
  const [isMyCareerOpen, setIsMyCareerOpen] = useState(true);
  const [isMySpaceOpen, setIsMySpaceOpen] = useState(true);
  const [isMyRecruitOpen, setIsMyRecruitOpen] = useState(true);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");

  const handleFileChange = (e) => {
    e.stopPropagation();
    setFile(e.target.files[0]);
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!file) {
      setError("Please upload a file."); // Set error message if no file
      return;
    }
    props.uploadCv({ profileId: 45, user_id: 12, resume: file }); //setShowCv(false); // Close the upload form
  };

  const onClick = () => {
    if (showResults) {
      setShowResults(false);
    } else {
      setShowResults(true);
    }
  };

  const onClickCv = (e) => {
    e.stopPropagation();
    setError("");
    setShowCv((prev) => !prev); // Toggle the visibility of the CV upload form
  };

  const onClickZoom = () => {
    if (showZoom) {
      setShowZoom(false);
    } else {
      setShowZoom(true);
    }
  };

  useEffect(() => {
    if (currentUser) {
      props.getCustomUserLink();
    }
    setIsExpanded(true);
    setCollapseHover(false);
    setMenuHover(false);
  }, []);

  useEffect(() => {
    setCustomUserMenu(props.customUserLink);
  }, [props.customUserLink]);

  useEffect(() => {
    if (zoomRedirectCode) {
      queryParams.delete("code");
      queryParams.delete("state");
      history.replace({
        search: queryParams.toString(),
      });

      props.getZoomConnect({ code: zoomRedirectCode });
      setTimeout(() => {
        if (window.opener && window.name === "tjunctionZoomAuth") {
          window.self.close();
        }
      }, 1000);
    }
  }, [zoomRedirectCode]);

  useEffect(() => {
    props.feedSidebarIsCollapsed(isExpanded ? "is-expand" : "");
  }, [isExpanded]);

  const handleToggler = () => {
    setCollapseHover(false);
    setActiveMenu("");
    if (isExpanded) {
      setIsExpanded(false);
      return;
    }
    setIsExpanded(true);
  };

  const openLinkModal = (customUserLinkObj = {}) => {
    let modalData = { ...props.customUserLinkModal };
    props.setCustomUserLinkModal({
      ...modalData,
      isModalOpen: true,
      customUserLinkObj: { ...customUserLinkObj },
    });
  };

  const openModal = (appliedDetailsData = {}) => {
    // let modalContent = { ...props.modalContent };
    // props.setTrainingApplyDetails({
    //   ...modalContent,
    //   isModalOpen: true,
    //   appliedDetailsData: { ...appliedDetailsData },
    // });
    if (getUserRole() == "STUDENT" || getUserRole() == "TRAINER") {
      props.AppliedDetails({ type: 1 });
    } else if (getUserRole() == "RECRUITER") {
      props.RecruiterAppliedDetails({ type: 1 });
    }
  };

  const formStyle = {
    marginTop: "10px", // Adjust to move the submit link down
  };

  const linkStyle = {
    display: "inline-block",
    color: "#007bff", // Blue color for the link
    textDecoration: "none",
    fontWeight: "bold",
    cursor: "pointer",
    marginTop: "10px",
    fontSize: "12px", // Additional margin if needed
  };

  const linkHoverStyle = {
    textDecoration: "underline",
  };

  const containerStyle = {
    marginTop: "10px", // Adjust to move the submit link down
  };
  return (
    <>
      <div
        className={isExpanded ? "qboard-sidebar" : "qboard-sidebar collapsed"}
      >
        <div className="sidebar-items">
          <div
            className={`collapse-left d-none d-lg-block mx-2`}
            onClick={handleToggler}
            onMouseEnter={() => setCollapseHover(true)}
            onMouseLeave={() => setCollapseHover(false)}
            // style={{ marginRight: "8px" }}
          >
            {isExpanded ? (
              <CommonIcons collapse={1} collapseHover={collapseHover} />
            ) : (
              <CommonIcons expand={1} collapseHover={collapseHover} />
            )}
          </div>
          <div className="overflow-auto">
            {/* <div className="sidebar-item">
              <div
                id={props.activeColor}
                className={
                  `item mb-1 ` +
                  (isMyFavOpen === true ? `${props.activeColor} active` : ``)
                }
                onMouseEnter={() => setMenuHover(true)}
                onClick={() =>
                  currentUser && isExpanded && setIsMyFavOpen(!isMyFavOpen)
                }
              >
                // <img src={process.env.PUBLIC_URL + '/images/menu-icons/heart.svg'} className="sidebar-icons" />
                <BiHeart style={{ margin: isExpanded ? "" : "auto" }} />
                <span className="sidebar-text">My Favourite</span>
                <span
                  className={
                    `fas fa sidebar-arrow-down ` +
                    (isMyFavOpen === true ? "fa-angle-left" : "fa-angle-down")
                  }
                ></span>
              </div>
            </div>
            {currentUser && isMyFavOpen === true ? (
              <div className="sidebar-nav">
                <Suspense fallback={""}>
                  <div id={props.activeColor} className="item sub-item">
                    <span className="sidebar-text" onClick={openLinkModal}>
                      <BiPlus className="plus-icon" />
                      <span className="sidebar-text">Add Link</span>
                    </span>
                  </div>
                  {customUserMenu && customUserMenu.length
                    ? customUserMenu.map((menu, menuKey) => {
                        return (
                          <CustomUserLink
                            key={menuKey}
                            menu={menu}
                            openLinkModal={openLinkModal}
                          />
                        );
                      })
                    : null}
                </Suspense>
              </div>
            ) : null} */}
            <div
              id={props.activeColor}
              className={
                ` item mb-1 ` +
                (isMyPageOpen === true ? `${props.activeColor} active` : ``)
              }
              onClick={() => isExpanded && setIsMyPageOpen(!isMyPageOpen)}
            >
              {/* <img src={process.env.PUBLIC_URL + '/images/menu-icons/page-view.svg'} className="sidebar-icons" /> */}
              <BsJournalText style={{ margin: isExpanded ? "" : "auto" }} />
              <span className="sidebar-text">My Page</span>
              <span
                className={
                  `fas fa sidebar-arrow-down ` +
                  (isMyPageOpen == true ? "fa-angle-left" : "fa-angle-down")
                }
              ></span>
            </div>
            {isMyPageOpen === true ? (
              <>
                <div className="item sub-item">
                  <Link to={`/profile/${role.toLowerCase()}`}>
                    <span
                      className="sidebar-text"
                      id={`${props.activeColor}` + `-insidezoom`}
                    >
                      My Profile
                    </span>
                  </Link>
                </div>

                {/* <div className="item sub-item">
                  <span
                    className="sidebar-text"
                    id={`${props.activeColor}` + `-insidezoom`}
                  >
                    <span className="sidebar-text">My Job</span>
                  </span>
                </div> */}

                {/* <div className="item sub-item">
                  <span className="sidebar-text" onClick={onClickCv}>
                    <span className="sidebar-text">CV Upload</span>
                    {showCv === true ? (
                      <div
                        style={containerStyle}
                        onClick={(e) => e.stopPropagation()}
                      >
                        <form
                          onSubmit={handleSubmit}
                          onClick={(e) => e.stopPropagation()}
                        >
                          <input
                            type="file"
                            onChange={handleFileChange}
                            accept=".pdf,.doc,.docx"
                            onClick={(e) => e.stopPropagation()}
                          />
                          {error && (
                            <p
                              style={{
                                color: "red",
                                fontSize: "12px",
                                marginBottom: "1px",
                              }}
                            >
                              {error}
                            </p>
                          )}
                          <a
                            href="#"
                            onClick={(e) => {
                              e.preventDefault(); // Prevent default anchor behavior
                              handleSubmit(e); // Handle file upload
                            }}
                            style={linkStyle}
                            onMouseOver={(e) =>
                              (e.target.style.textDecoration =
                                linkHoverStyle.textDecoration)
                            }
                            onMouseOut={(e) =>
                              (e.target.style.textDecoration =
                                linkStyle.textDecoration)
                            }
                          >
                            Submit
                          </a>
                        </form>
                      </div>
                    ) : null}
                  </span>
                </div> */}
              </>
            ) : null}
            {role == "trainer" && (
              <div
                id={props.activeColor}
                className={
                  `item mb-1 ` +
                  (isMyTrainingOpen === true
                    ? `${props.activeColor} active`
                    : ``)
                }
                onClick={() =>
                  isExpanded && setIsMyTrainingOpen(!isMyTrainingOpen)
                }
              >
                {/* <img src={process.env.PUBLIC_URL + '/images/menu-icons/page-view.svg'} className="sidebar-icons" /> */}
                {/* <i class="bi bi-journal-text sidebar-icons"></i> */}
                <BsPersonWorkspace
                  style={{ margin: isExpanded ? "" : "auto" }}
                />
                <span className="sidebar-text">My Trainings</span>
                <span
                  className={
                    `fas fa sidebar-arrow-down ` +
                    (isMyTrainingOpen === true
                      ? "fa-angle-left"
                      : "fa-angle-down")
                  }
                ></span>
              </div>
            )}
            {isMyTrainingOpen === true && role == "trainer" ? (
              // <div className="item sub-item">
              //   <Link to="/trainer/training" target="_blank">
              //   <BsCircle className='small' /><span id={`${props.activeColor}`+ `-insidezoom`} className="sidebar-text">Manage My Trainings</span>
              //   </Link>
              // </div>
              <>
                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    // onClick={() =>
                    //   window.open(
                    //     " http://trainer.tjunction.co.in:8501/",
                    //     "_blank",
                    //   )
                    // }
                  >
                    <span className="sidebar-text my-2">
                      Create Curriculum (Ai)
                    </span>
                  </div>
                </Suspense>

                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    // onClick={() =>
                    //   window.open(
                    //     " http://trainer.tjunction.co.in:8501/",
                    //     "_blank",
                    //   )
                    // }
                  >
                    <span className="sidebar-text my-2">
                      Create Content (Ai)
                    </span>
                  </div>
                </Suspense>

                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    //onClick={onClick}
                  >
                    <span className="sidebar-text my-2">View Courses</span>
                  </div>
                </Suspense>

                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    onClick={onClick}
                  >
                    <span className="sidebar-text my-2">
                      Manage My Trainings
                    </span>
                    {/* {showResults ? <TrainingCourse /> : null} */}
                  </div>
                </Suspense>
              </>
            ) : null}
            {/* <div
              id={props.activeColor}
              className={
                `item mb-1 ` +
                (isMyJobsOpen === true ? `${props.activeColor} active` : ``)
              }
              onClick={() => isExpanded && setIsMyJobsOpen(isMyJobsOpen)}
            >
              <BsBriefcase style={{ margin: isExpanded ? "" : "auto" }} />
              <span className="sidebar-text">My Jobs</span>
              <span
                className={
                  `fas fa sidebar-arrow-down ` +
                  (isMyJobsOpen === true ? "fa-angle-left" : "fa-angle-down")
                }
              ></span>
            </div> */}
            {isMyJobsOpen === true ? (
              <>
                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    //onClick={onClick}
                  >
                    <span className="sidebar-text my-2">Applied</span>
                  </div>
                </Suspense>

                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    //onClick={onClick}
                  >
                    <span className="sidebar-text my-2">Posted</span>
                  </div>
                </Suspense>
              </>
            ) : null}
            {/* <div
              id={props.activeColor}
              className={
                `item mb-1 ` +
                (isMyLearningsOpen === true
                  ? `${props.activeColor} active`
                  : ``)
              }
              onClick={() =>
                isExpanded && setIsMyLearningsOpen(isMyLearningsOpen)
              }
            >
              <BsBook style={{ margin: isExpanded ? "" : "auto" }} />
              <span className="sidebar-text">My Learnings</span>
              <span
                className={
                  `fas fa sidebar-arrow-down ` +
                  (isMyLearningsOpen === true
                    ? "fa-angle-left"
                    : "fa-angle-down")
                }
              ></span>
            </div> */}
            {isMyLearningsOpen === true ? (
              <>
                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    //onClick={onClick}
                  >
                    <span className="sidebar-text my-2">My Courses</span>
                  </div>
                </Suspense>

                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    //onClick={onClick}
                  >
                    <span className="sidebar-text my-2">History</span>
                  </div>
                </Suspense>
              </>
            ) : null}
            {role == "student" && (
              <div
                id={props.activeColor}
                className={
                  `item mb-1 ` +
                  (isMyCareerOpen === true ? `${props.activeColor} active` : ``)
                }
                onClick={() => isExpanded && setIsMyCareerOpen(!isMyCareerOpen)}
              >
                {/* <img src={process.env.PUBLIC_URL + '/images/menu-icons/page-view.svg'} className="sidebar-icons" /> */}
                {/* <i class="bi bi-journal-text sidebar-icons"></i> */}
                <BsBriefcase style={{ margin: isExpanded ? "" : "auto" }} />
                <span className="sidebar-text">My Career</span>
                <span
                  className={
                    `fas fa sidebar-arrow-down ` +
                    (isMyCareerOpen === true
                      ? "fa-angle-left"
                      : "fa-angle-down")
                  }
                ></span>
              </div>
            )}

            {isMyCareerOpen === true && role == "student" ? (
              <>
                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    // onClick={() =>
                    //   window.open("http://student.tjunction.co.in", "_blank")
                    // }
                  >
                    <span className="sidebar-text my-2">
                      Career Road Map (Ai)
                    </span>
                  </div>
                </Suspense>

                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    // onClick={() =>
                    //   window.open("http://student.tjunction.co.in", "_blank")
                    // }
                  >
                    <span className="sidebar-text my-2">
                      Profile Match to the JD (Ai)
                    </span>
                  </div>
                </Suspense>
              </>
            ) : null}
            {/* <div
              id={props.activeColor}
              className={
                `item mb-1  ` +
                (isVidOpen === true ? `${props.activeColor} active ` : ``)
              }
              onClick={() => isExpanded && setIsVidOpen(!isVidOpen)}
            >
              <BsCameraVideo style={{ margin: isExpanded ? "" : "auto" }} />
              <span className="sidebar-text">Video Conferencing</span>
              <span
                className={
                  `fas fa sidebar-arrow-down ` +
                  (isVidOpen === true ? "fa-angle-left" : "fa-angle-down")
                }
              ></span>
            </div> */}
            {/* {isVidOpen === true ? (
              <Suspense fallback={""}>
                <div
                  id={props.activeColor}
                  className="item sub-item "
                  onClick={onClickZoom}
                >
                  <span className="sidebar-text">Zoom</span>
                  {showZoom ? <ZoomMeeting /> : null}
                </div>
                <div
                  id={`${props.activeColor}` + `-insidezoom`}
                  className="item sub-item "
                >
                  <span className="sidebar-text">Google Meet</span>
                </div>
                <div
                  id={`${props.activeColor}` + `-insidezoom`}
                  className="item sub-item "
                >
                  <span className="sidebar-text">ZointVC</span>
                </div>
              </Suspense>
            ) : null} */}
            {/* <div
              id={props.activeColor}
              className={
                `item mb-1 ` +
                (isMySpaceOpen === true ? `${props.activeColor} active` : ``)
              }
              onClick={() => isExpanded && setIsMySpaceOpen(!isMySpaceOpen)}
            >
              <BsCloud style={{ margin: isExpanded ? "" : "auto" }} />
              <span className="sidebar-text">My Space</span>
              <span
                className={
                  `fas fa sidebar-arrow-down ` +
                  (isMySpaceOpen === true ? "fa-angle-left" : "fa-angle-down")
                }
              ></span>
            </div> */}

            {/* {isMySpaceOpen === true ? (
              <>
                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    //onClick={onClick}
                  >
                    <span className="sidebar-text my-2">One drive</span>
                  </div>
                </Suspense>

                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    //onClick={onClick}
                  >
                    <span className="sidebar-text my-2">Google drive</span>
                  </div>
                </Suspense>
              </>
            ) : null} */}

            {role == "recruiter" && (
              <div
                id={props.activeColor}
                className={
                  `item mb-1 ` +
                  (isMyRecruitOpen === true
                    ? `${props.activeColor} active`
                    : ``)
                }
                onClick={() =>
                  isExpanded && setIsMyRecruitOpen(!isMyRecruitOpen)
                }
              >
                <BsPersonCheck style={{ margin: isExpanded ? "" : "auto" }} />
                <span className="sidebar-text">My recruitment</span>
                <span
                  className={
                    `fas fa sidebar-arrow-down ` +
                    (isMyRecruitOpen === true
                      ? "fa-angle-left"
                      : "fa-angle-down")
                  }
                ></span>
              </div>
            )}

            {isMyRecruitOpen === true && role == "recruiter" ? (
              <>
                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    //   onClick={() =>
                    //     window.open(
                    //       " http://recruiter.tjunction.co.in ",
                    //       "_blank",
                    //     )
                    //   }
                  >
                    <span className="sidebar-text my-2">
                      JD based folder allocation (Ai)
                    </span>
                  </div>
                </Suspense>

                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    // onClick={() =>
                    //   window.open(
                    //     " http://recruiter.tjunction.co.in ",
                    //     "_blank",
                    //   )
                    // }
                  >
                    <span className="sidebar-text my-2">
                      JD enhancement and JD creation (Ai)
                    </span>
                  </div>
                </Suspense>

                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    // onClick={() =>
                    //   window.open(
                    //     " http://recruiter.tjunction.co.in ",
                    //     "_blank",
                    //   )
                    // }
                  >
                    <span className="sidebar-text my-2">
                      JD based resource ranking (Ai)
                    </span>
                  </div>
                </Suspense>

                <Suspense fallback={""}>
                  <div
                    id={props.activeColor}
                    className="item sub-item "
                    // onClick={() =>
                    //   window.open(
                    //     " http://recruiter.tjunction.co.in ",
                    //     "_blank",
                    //   )
                    // }
                  >
                    <span className="sidebar-text my-2">
                      JD (job description) creation (Ai)
                    </span>
                  </div>
                </Suspense>
              </>
            ) : null}
          </div>
        </div>
      </div>

      {/* <CustomUserLinkModal />
      <TrainingApplyDetailsModal /> */}
    </>
  );
};

const mapStateToProps = (state) => ({
  activeColor: state.activetheme.activeColor,
  customUserLink: state.customUserLink.customUserLink,
  customUserLinkModal: state.customUserLink.customUserLinkModal,
  cvData: state.customUserLink.cvData,
  activePopupWindow: state.common.activePopupWindow,
});

export default connect(mapStateToProps, {
  getCustomUserLink,
  setCustomUserLinkModal,
  feedSidebarIsCollapsed,
  getZoomConnect,
  setTrainingApplyDetails,
  AppliedDetails,
  RecruiterAppliedDetails,
  uploadCv,
})(FeedLeftMenu);

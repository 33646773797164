import React from "react";
import FeedLayout from "../../components/layout/FeedLayout";

const Home = () => {

  return (
    <FeedLayout>
      <div className="m-3 text-center">
        <h5>Student Dashboard</h5>
      </div>
    </FeedLayout>
  );
};

export default (Home);

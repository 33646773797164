import { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import { Modal, Table } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import useDidMountEffect from '../../../../components/DidMount';
import Spinner from '../../../../components/spinner/CommanSpinner';
import { addFinance, deleteFinance } from '../../../../actions/training';

const FinanceModal = (props) => {
  const inputRef = useRef([]);
  // const financeObj = {
  //   course_finance_id: null,
  //   course_id: null,
  //   students_attended: null,
  //   program_status: null,
  //   course_type: null,
  //   currency: null,
  //   course_fee: null,
  //   pending: null,
  //   payment_status: null
  // }
  const [financeData, setFinanceData] = useState([]);
  const [financeDeleteAlert, setFinanceDeleteAlert] = useState({ show: false, financeId: null, index: null });
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    setShowSpinner(false);
    if (props.financeData.length) {
      let data = props.financeData[0];
      setFinanceData(data.finance);
    }
  }, [props.financeData]);

  useDidMountEffect(() => {
    setShowSpinner(false);
  }, [props.finances])

  // const generateFinanceGrid = (event) => {
  //   if (event.target.value !== null) {
  //     let temp = [...financeData];
  //     let count = event.target.value - temp.length;

  //     for (let i = 0; i < count; i++) {
  //       let tempObj = { ...financeObj }
  //       tempObj.key = i;
  //       temp.push(tempObj)
  //     }

  //     setFinanceData(temp);
  //   }
  // }

  const handleFinanceChange = (event, index) => {
    let newfinance = [...financeData];
    newfinance[index][event.target.name] = event.target.value;
    newfinance[index][inputRef.current[index].name] = inputRef.current[index].value;
    newfinance[index]['course_id'] = props.courseId[0].id;

    setFinanceData(newfinance);
  }

  const submitfinance = (e) => {
    setShowSpinner(true);
    let financeInfo = {
      courseId: props.courseId[0].id,
      data: financeData
    }

    props.addFinance(financeInfo);
  }

  const deleteFinance = (index, id) => {
    setFinanceDeleteAlert({ ...financeDeleteAlert, show: true, financeId: id, index: index })
  }

  const confirmDeleteFinance = () => {
    let temp = [...financeData];
    temp.splice(financeDeleteAlert.index, 1);
    setFinanceData(temp);
    setFinanceDeleteAlert({ ...financeDeleteAlert, show: false, financeId: null, index: null })

    if (financeDeleteAlert.financeId !== null) {
      let financeInfo = {
        courseId: props.courseId[0].id,
        financeId: financeDeleteAlert.financeId
      }
      props.deleteFinance(financeInfo)
    }
  }

  const closeFinanceDeleteAlert = () => {
    setFinanceDeleteAlert({ ...financeDeleteAlert, show: false, financeId: null, index: null })
  }

  return (
    <>
      <Modal show={props.showFinanceModal} onHide={props.closeFinanceModal} backdrop="static" keyboard={false} centered className="tj-modal" size="lg">
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1">Assign finance</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className='m-2'>
            <Table striped bordered hover size="md" responsive>
              <thead>
                <tr>
                  <th>Students Attended</th>
                  <th>Program Status</th>
                  <th>Course Type</th>
                  <th>Course Currency</th>
                  <th>Course Fee</th>
                  <th>Payment Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {financeData.map((item, k) => (
                  <tr>
                    <td>
                      <input type='text' className='form-control form-control-sm' placeholder='Organization' name='organization' ref={el => inputRef.current[k] = el} defaultValue={item.organization} onChange={(e) => handleFinanceChange(e, k)} key={k} />
                    </td>
                    <td>
                      <input type='text' className='form-control form-control-sm' placeholder='finance' name='finances_finance' onChange={(e) => handleFinanceChange(e, k)} defaultValue={item.finances_finance} key={k} />
                    </td>
                    <td>
                      <input type='text' className='form-control form-control-sm' placeholder='Planned students' name='planned_students' onChange={(e) => handleFinanceChange(e, k)} defaultValue={item.planned_students} key={k} />
                    </td>
                    <td>
                      <div className=''>
                        <FontAwesomeIcon icon={faTimes} className='batch-delete' role='button' onClick={(e) => deleteFinance(k, item.finance_id)} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-outline-primary mx-2 btn-sm" onClick={(e) => submitfinance(e)}>{(showSpinner) ? <Spinner /> : 'Submit'}</button>
        </Modal.Footer>
      </Modal>

      {/* delete finance alert modal */}
      <Modal show={financeDeleteAlert.show} onHide={closeFinanceDeleteAlert} backdrop="static" keyboard={false} centered className="tj-modal">
        <Modal.Header closeButton className="d-flex">
          <Modal.Title className="flex-grow-1 text-center">Delete confirmation</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p className='text-center'>Are you sure want to delete finance ?</p>
        </Modal.Body>

        <Modal.Footer className="justify-content-center">
          <button type="button" className="btn btn-success mx-2" onClick={confirmDeleteFinance}>Yes</button>
          <button type="button" className="btn btn-outline-danger" onClick={closeFinanceDeleteAlert}>No</button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  finances: state.training.finance
})

export default connect(mapStateToProps, { addFinance, deleteFinance })(FinanceModal);
import Trash from "../trash/Trash";

const MoreSkills = (props) => {
  let skillNameIsVisible = 0;
  let ratingIsVisible = 0;

  if (props.skillData.userskill_name) {
    skillNameIsVisible = 1;
  }

  if (props.skillData.userskill_expertize) {
    ratingIsVisible = 1;
  }

  if (props.editableStatus.status) {
    skillNameIsVisible = 1;
    ratingIsVisible = 1;
  }

  return (
    <>
      {skillNameIsVisible ? (
        <div
          className={
            `col-xl-6 col-12 d-flex justify-content-spacearound mb-2   ` +
            (props.editableStatus.status ? "" : "")
          }
        >
          <div className="col-md-7  col-sm-7 col-5">
            {props.editableStatus.status ? (
              <input
                type="text"
                key={`mskillname${props.index}`}
                className={
                  `form-control py-1 m-2 text-center ` +
                  (props.editableStatus.status &&
                  !props.skillData.userskill_show
                    ? "hide-opacity"
                    : "")
                }
                name="userskill_name"
                value={
                  props.skillData.userskill_name
                    ? props.skillData.userskill_name
                    : ""
                }
                onBlur={(e) => props.editSkill(props.index, props.skillData)}
                onChange={(e) => props.skillChangeHandler(e, props.index)}
              />
            ) : (
              <p className="py-1 m-2">
                {props.skillData.userskill_name
                  ? props.skillData.userskill_name
                  : ""}
              </p>
            )}
          </div>
          {props.editableStatus.status ? (
            <div className="col-1">
              <p className="text-center py-3 px-2">
                {props.skillData.userskill_expertize}
              </p>
            </div>
          ) : (
            <div className="col-1"></div>
          )}
          <div className="col-4 d-flex  align-content-end ">
            {ratingIsVisible ? (
              <div className="mt-2">
                {props.editableStatus.status ? (
                  <input
                    type="range"
                    value={
                      props.skillData.userskill_expertize !== null
                        ? props.skillData.userskill_expertize
                        : 1
                    }
                    min="1"
                    max="10"
                    step="1"
                    onChange={(e) => props.skillChangeHandler(e, props.index)}
                    onBlur={() => props.editSkill(props.index, props.skillData)}
                    className="slider"
                    name="userskill_expertize"
                    id={props.index + "-slider-menu"}
                  />
                ) : (
                  <p className="py-1 my-2">
                    {props.skillData.userskill_expertize}
                  </p>
                )}
              </div>
            ) : (
              <div></div>
            )}

            <div>
              {props.activeSkill.length > 1 && props.editableStatus.status ? (
                <div className="more-skill-trash  trash ">
                  <Trash
                    id={props.skillData.userskill_id}
                    index={props.index}
                    source="skills"
                    active={props.activeSkill}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>{" "}
        </div>
      ) : (
        "No skill found"
      )}
    </>
  );
};

export default MoreSkills;

import React from 'react'

const FormLoader = () => {
    return (
        <span
          className="spinner-border spinner-border-sm ml-5"
          role="status"
          aria-hidden="true"
        ></span>
    )
}

export default FormLoader

import moment from 'moment';

export const colors = [
  'global-blue',
  'global-green',
  'global-pink',
  'global-orange',
  'global-purple'
  
];

export const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 550, itemsToShow: 2},
  { width: 850, itemsToShow: 3 },
  { width: 1150, itemsToShow: 4, itemsToScroll: 2 },
  { width: 1450, itemsToShow: 5 },
  { width: 1750, itemsToShow: 6 },
]

export const  months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
export const  currentYear = new Date().getFullYear();


export const caroseldata = [
  {
    footerData: 'Katrina Peter',
    footerBgColor: '#3ABDCC',
    profilePic : process.env.PUBLIC_URL + '/images/carousel-img/profile-pic1.svg'
  },
  {
    footerData: 'Suresh Shetty',
    footerBgColor: '#F1AA30',
    profilePic : process.env.PUBLIC_URL + '/images/carousel-img/profile-pic2.svg'
  },
  {
    footerData:'Robert Mathew',
    footerBgColor: '#BC30E5',
    profilePic : process.env.PUBLIC_URL + '/images/carousel-img/profile-pic3.svg'
  },
  {
    footerData: 'Katrina Peter',
    footerBgColor: '#3ABDCC',
    profilePic : process.env.PUBLIC_URL + '/images/carousel-img/profile-pic1.svg'
  },
  {
    footerData: 'Suresh Shetty',
    footerBgColor: '#F1AA30',
    profilePic : process.env.PUBLIC_URL + '/images/carousel-img/profile-pic2.svg'
  },
  {
    footerData:'Robert Mathew',
    footerBgColor: '#BC30E5',
    profilePic : process.env.PUBLIC_URL + '/images/carousel-img/profile-pic3.svg'
  },
]

export const getDate = (val) => {
  var moment = require('moment');
  const date = new Date(val);
  const lastValue = moment(date).format('DD-MMM-YYYY');
  return lastValue;
};
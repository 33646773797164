import { Spinner } from "react-bootstrap";

const CommanSpinner = () => {
  return (
    <div>
      <Spinner animation="border" variant="white" size="sm" />
    </div>
  );
};

export default CommanSpinner;

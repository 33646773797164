import { useState, useEffect } from "react";

import { NavDropdown } from "react-bootstrap";
import { connect } from "react-redux";

import CommonService from "../../../../services/common.service";
import { getUser } from "../../../../utils/UserDetails";
import { setCommentData } from "../../../../actions/dashboradCommon";

const CommentHeader = (props) => {
  const [post, setPost] = useState();
  const currentUser = getUser();

  useEffect(() => {
    setPost(props.post);
  }, [props.post]);

  const addComments = (post) => {
    let commentPostData = { ...props.postComment };
    commentPostData.isOpen = true;
    commentPostData.key_table = "";
    commentPostData.qboard_id = "";
    commentPostData.qboard_parent_id = "";
    commentPostData.requirements_job_id = "";
    commentPostData.requirements_training_id = "";
    commentPostData.comments_parent_id = "";

    if (post.common_comments_id) {
      commentPostData.cmt_id = post.common_comments_id;
    }
    props.setCommentData(commentPostData);
  };

  return (
    <>
      {post ? (
        <ul className="list-inline post-box-input-1 d-flex justify-content-between mb-0">
          <li className="list-inline-item comment-profile">
            <div className="d-flex profile">
              <img
                className="thumbnail p-1"
                src={
                  post.user_profile_image_url
                    ? post.user_profile_image_url
                    : process.env.PUBLIC_URL + "/images/icons/prfl1.png"
                }
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src =
                    process.env.PUBLIC_URL + "/images/icons/prfl1.png";
                }}
              />
              <h6 className="user-name">{post.user}</h6>
            </div>
          </li>
          {/* <li className="list-inline-item my-auto date-view">
            {CommonService.formDate(post.created_at)}
          </li> */}

          <li className="list-inline-item my-auto show-more-btn ">
            {currentUser ? (
              <NavDropdown className="show-more-view" title="...">
                <NavDropdown.Item>
                  <i className="fas fa fa-warning warning"></i>
                </NavDropdown.Item>
                {post.isMyComment !== 0 && (
                  <NavDropdown.Item>
                    <i
                      className="fas fa fa-pencil"
                      onClick={() => addComments(post)}
                    ></i>
                  </NavDropdown.Item>
                )}

                <NavDropdown.Item>
                  <i className="fas fa fa-close"></i>
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}
          </li>
        </ul>
      ) : null}
    </>
  );
};

const mapStateToProps = (state) => ({
  keyTable: state.dashboardCommon.keyTable,
});

export default connect(mapStateToProps, { setCommentData })(CommentHeader);

import { useState, useEffect, Suspense } from "react";

import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAngleDoubleLeft,
  faTimes,
  faUserPlus,
  faUser,
  faEye,
  faUserSecret,
  faBookOpen,
  faPen,
} from "@fortawesome/free-solid-svg-icons";
import Cookies from "js-cookie";
import renderHTML from "react-render-html";
import { Tab, Tabs, Modal, Button, ModalFooter } from "react-bootstrap";
import Loader from "react-loader-spinner";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import LeftMenu from "./LeftMenu";
import TrainingExperience from "../Trainers/profile/trainingexperience/TrainingExperience";
import JobExperience from "../Trainers/profile/jobexperience/JobExperience";
import Qualification from "../Trainers/profile/qualifications/Qualifications";
import Certificates from "../Trainers/profile/certifications/Certificates";
import Awards from "../Trainers/profile/awards/Awards";
import MoreSkills from "../Trainers/profile/moreskills/MoreSkills";
// import Skillendorsement from "./skillendorsement/SkillEndorsement";
import Interests from "../Trainers/profile/interests/Interests";
import CommanSpinner from "../../components/spinner/CommanSpinner";
import Layout from "../../components/layout/Layout";
import ProfileService from "../../services/profile.service";
import * as userProfileAction from "../../actions/profile";
import {
  getProfileDetails,
  addUserProfile,
  setActiveProfile,
  trainingExperience,
  jobExperience,
  qualification,
  certificate,
  award,
  skill,
  description,
  recordHide,
  createNewProfile,
  sectionShow,
  endorse,
  getEndorseDetails,
  editProfileMenu,
  addInterest,
  getInterests,
  getProfile,
  setImages,
} from "../../actions/profile";
import { setActiveColor, setDarkTheme } from "../../actions/colorTheme";
import commonService from "../../services/common.service";
import Trash from "../Trainers/profile/trash/Trash";
import useDidMountEffect from "../Trainers/profile/DidMount";
import { getUserRole } from "../../utils/UserDetails";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import LoginModal from "./LoginModal";
import moment from "moment";
import Axios from "../../config/axios";
import UploadCVModal from "./UploadCVModal";

const Profile = (props) => {
  const { role } = useParams();
  const [showModal, setShowModal] = useState(false);

  const experienceObj = {
    userexperience_id: null,
    userexperience_profile_id: null,
    userexperience_designation: null,
    user_experience_description: null,
    user_experience_display_order: null,
    userexperience_show: 1,
    userexperience_from: null,
    userexperience_to: null,
  };

  const jobExperienceObj = {
    user_job_experience_id: null,
    user_job_experience_profile_id: null,
    user_job_experience_job_name: null,
    user_job_experience_company_name: null,
    user_job_experience_description: null,
    user_job_experience_display_order: null,
    user_job_experience_show: 1,
    user_job_experience_from: null,
    user_job_experience_to: null,
  };

  const qualificationObj = {
    userqualification_id: null,
    userqualification_profile_id: null,
    userqualification_name: null,
    userqualification_institution_name: null,
    userqualification_subject: null,
    userqualification_display_order: null,
    userqualification__show: 1,
    userqualification_year_start: null,
    userqualification_year_end: null,
  };

  const certificateObj = {
    user_certificates_id: null,
    user_certificates_profile_id: null,
    user_certificates_name: null,
    user_certificates_institution_name: null,
    user_certificates_display_order: null,
    user_certificates_show: 1,
    user_certificates_description: null,
  };

  const awardObj = {
    user_awards_id: null,
    user_awards_title: null,
    user_awards_description: null,
    user_awards_profile_id: null,
    user_awards_show: 1,
    user_awards_display_order: null,
  };

  const skillObj = {
    userskill_id: null,
    userskill_profile_id: null,
    userskill_name: null,
    userskill_display_order: null,
    userskill_expertize: null,
    userskill_show: 1,
  };

  const newProfileObj = {
    user_profile_id: null,
    user_profile_userid: null,
    user_profile_name: null,
    user_profile_location: null,
    user_profile_url: null,
    user_profile_email: null,
    user_profile_description: null,
    user_profile_contact_number: null,
    user_profile_image_url: null,
    user_profile_enabled: 1,
    user_profile_show: 1,
    user_experience_enabled: 1,
    user_job_experience_enabled: 1,
    user_qualification_enabled: 1,
    user_description_enabled: 1,
    user_certifications_enabled: 1,
    user_awards_enabled: 1,
    user_profile_locality: null,
    user_profile_city: null,
    user_profile_state: null,
    user_profile_country: null,
    user_profile_page_color: null,
    user_profile_page_dark: null,
    user_profile_bg_img_url: null,
  };

  let modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link"],
    ],
  };

  let formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
  ];

  const colorSet1 = [
    "type1-1",
    "type1-2",
    "type1-3",
    "type1-4",
    "type1-5",
    "type1-6",
    "type1-7",
    "type1-8",
    "type1-9",
    "type1-10",
    "type1-11",
    "type1-12",
    "type1-13",
    "type1-14",
    "type1-15",
  ];

  const colorSet2 = [
    "type2-1",
    "type2-2",
    "type2-3",
    "type2-4",
    "type2-5",
    "type2-6",
    "type2-7",
    "type2-8",
    "type2-9",
    "type2-10",
    "type2-11",
    "type2-12",
    "type2-13",
    "type2-14",
    "type2-15",
  ];

  const location = useLocation();
  const dispatch = useDispatch();
  const [profileDetails, setProfileDetails] = useState({});
  const [editableStatus, setEditable] = useState({ status: false });
  const [loaderStatus, setLoaderStatus] = useState({ status: false });
  const [showEndorseDetails, setShowEndorseModal] = useState({
    showModal: false,
  });
  const [allUserDetails, setAllUserDetails] = useState({});
  const [showSpinner, setSpinner] = useState({ status: false, id: "" });
  const [endorseData, setEndorseData] = useState([]);
  const [btnText, setBtnText] = useState({ text: false });
  const [interestModalData, setInterestModalData] = useState([]);
  const [showInterestsModal, setShowInterestsModal] = useState({
    showModal: false,
  });
  const [interestData, setInterestData] = useState([]);
  const [interests, setInterests] = useState([]);
  const [subCategoriesActive, setSubCategoriesActive] = useState(false);
  const [categoriesActive, setCategoriesActive] = useState(true);
  const [showCropModal, setShowCropModal] = useState({ showModal: false });
  const [interestChildData, setInterestChildData] = useState([]);
  const [comparison, setComparison] = useState([]);
  const [showInterestAlertModal, setShowInterestAlertModal] = useState({
    showAlert: false,
  });
  const [missedInterests, setMissedInterests] = useState([]);
  const [bgImg, setBgImg] = useState(false);
  const [identifier, setIdentifier] = useState("");
  const [hasClicked, setHasClicked] = useState(false);

  useEffect(() => {
    // Retrieve or generate unique identifier from cookie
    let id = Cookies.get("userIdentifier");
    if (!id) {
      id = `user_${Date.now()}`;
      Cookies.set("userIdentifier", id, { expires: 365 });
    }
    setIdentifier(id);
  }, []);

  useDidMountEffect(() => {
    if (props.profile) {
      let newData = { ...profileDetails };
      newData = props.profile;

      if (newData.user_profile_page_color) {
        dispatch(props.setActiveColor(newData.user_profile_page_color));
      }

      dispatch(
        props.setDarkTheme(
          newData.user_profile_page_dark ? newData.user_profile_page_dark : 0,
        ),
      );
      dispatch(props.setActiveProfile(newData));
      setProfileDetails(newData);
    }
  }, [props.profile]);

  useDidMountEffect(() => {
    if (props.endorseDetails) {
      setShowEndorseModal({ ...showEndorseDetails, showModal: true });
      setEndorseData(props.endorseDetails);
    }
  }, [props.endorseDetails]);

  const editSkill = async (key, skillObj) => {
    props.skill(skillObj);
  };

  const skillChangeHandler = (e, index) => {
    let newActiveProfile = { ...props.activeProfile };
    newActiveProfile.skill[index][e.target.name] = e.target.value;
    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const addNewSkill = (
    skillObj = {},
    index,
    displayOrder,
    isFromRestart = 0,
  ) => {
    let newActiveProfile = { ...props.activeProfile };

    if (isFromRestart) {
      newActiveProfile.skill = [];
    }

    skillObj["userskill_profile_id"] = profileDetails.user_profile_id;
    skillObj["userskill_display_order"] = displayOrder;

    if (index) {
      newActiveProfile.skill.push(skillObj);
    } else {
      newActiveProfile.skill.unshift(skillObj);
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const editExperienceDesignation = async (key, trainingObj) => {
    props.trainingExperience(trainingObj);
  };

  const editQualification = async (key, qualificationObj) => {
    props.qualification(qualificationObj);
  };

  const qualificationChangeHandler = (e, from, index) => {
    let newActiveProfile = { ...props.activeProfile };

    if (from === "editor") {
      newActiveProfile["qualification"][index]["userqualification_subject"] = e;
    } else {
      newActiveProfile["qualification"][index][e.target.name] = e.target.value;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const editCertificate = async (key, certificateObj) => {
    props.certificate(certificateObj);
  };

  const editAward = async (key, awardObj) => {
    props.award(awardObj);
  };

  const awardChangeHandler = (e, from, index) => {
    let newActiveProfile = { ...props.activeProfile };

    if (from === "editor") {
      newActiveProfile["awards"][index]["user_awards_description"] = e;
    } else {
      newActiveProfile["awards"][index][e.target.name] = e.target.value;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const certificateChangeHandler = (e, from, index) => {
    let newActiveProfile = { ...props.activeProfile };

    if (from === "editor") {
      newActiveProfile["certificates"][index]["user_certificates_description"] =
        e;
    } else {
      newActiveProfile["certificates"][index][e.target.name] = e.target.value;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const editJobExperience = async (key, jobObj) => {
    props.jobExperience(jobObj);
  };

  const jobChangeHandler = (e, from, index) => {
    let newActiveProfile = { ...props.activeProfile };

    if (from === "editor") {
      newActiveProfile["job_experience"][index][
        "user_job_experience_description"
      ] = e;
    } else {
      newActiveProfile["job_experience"][index][e.target.name] = e.target.value;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const experienceChangeHandler = (e, from, index) => {
    let newActiveProfile = { ...props.activeProfile };

    if (from === "editor") {
      newActiveProfile["experience"][index]["user_experience_description"] = e;
    } else {
      newActiveProfile["experience"][index][e.target.name] = e.target.value;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const generateNewSkill = (e, from) => {
    let index;
    let displayOrder;
    let dataLength = props.activeProfile.skill.length;

    if (from === "top") {
      index = 0;
      displayOrder = dataLength + 1;
    } else {
      index = dataLength + 1;
      displayOrder = 1;
    }

    addNewSkill(skillObj, index, displayOrder);
  };

  const showAndHideOption = async (status, data, source, index) => {
    let newActiveProfile = { ...props.activeProfile };
    let showAndHideData = {
      status: status,
      id: data,
      source: source,
    };

    props.recordHide(showAndHideData);

    switch (source) {
      case "texperience":
        newActiveProfile["experience"][index]["userexperience_show"] = status
          ? 0
          : 1;
        break;
      case "job":
        newActiveProfile["job_experience"][index]["user_job_experience_show"] =
          status ? 0 : 1;
        break;
      case "qualification":
        newActiveProfile["qualification"][index]["userqualification__show"] =
          status ? 0 : 1;
        break;
      case "certificate":
        newActiveProfile["certificates"][index]["user_certificates_show"] =
          status ? 0 : 1;
        break;
      case "award":
        newActiveProfile["awards"][index]["user_awards_show"] = status ? 0 : 1;
        break;
      case "skills":
        newActiveProfile["skill"][index]["userskill_show"] = status ? 0 : 1;
        break;
      case "interest":
        newActiveProfile["interest"][index]["user_interests_show"] = status
          ? 0
          : 1;
        break;
      default:
        break;
    }

    dispatch(userProfileAction.setActiveProfile(newActiveProfile));
  };

  const setImage = async (e, val) => {
    let fd = new FormData();
    fd.append("file", val);
    fd.append("profileId", profileDetails.user_profile_id);

    if (e.isBgImg) {
      fd.append("isBgImg", 1);
      setBgImg(true);
    }
    props.setImages(fd);
  };

  const profileChangeHandler = (event) => {
    let newProfileData = { ...profileDetails };
    newProfileData[event.target.name] = event.target.value;
    setProfileDetails(newProfileData);
  };

  const editProfileData = async (profileData) => {
    props.editProfileMenu(profileData);
  };

  const createNewComponent = () => {
    let newProfile = { ...props.activeProfile };
    newProfile.profiles.push(newProfileObj);
    dispatch(userProfileAction.setActiveProfile(newProfile));
  };

  const editMode = (e, text) => {
    if (btnText.text === true) {
      props.getProfileDetails(1);
    }
    let newText = { ...btnText };
    newText.text = !text;
    setBtnText(newText);
    let newStatus = { ...editableStatus };
    newStatus.status = !newStatus.status;
    setEditable(newStatus);
  };

  const showInterestModal = async (id) => {
    setSpinner({ ...showSpinner, status: true, id: id });
    props.getInterests();
  };

  const setFileData = async (e, id) => {
    let file = e.target.files[0];
    setSpinner({ ...showSpinner, status: true, id: id });
    let fileData = new FormData();
    fileData.append("resume", file);
    fileData.append("profileId", 1);
    fileData.append("user_id", 2);
    // let resultData = await ProfileService.buttonClick(identifier);
    let fileResponse = await ProfileService.getAllUserProfileDetails(fileData);
    setAllUserDetails(fileResponse.data);
    // if (fileResponse?.status) {
    //   let resultData = await ProfileService.getProfileDetails(1);

    //   if (resultData?.status) {
    //     let activeProfile = ProfileService.getAllUserProfileDetails(
    //       resultData.profileData,
    //     ).shift();
    //     dispatch(userProfileAction.addUserProfile(resultData.profileData));
    //     dispatch(userProfileAction.setActiveProfile(activeProfile));
    //     toast.success("Successfully added CV");
    //   } else {
    //     setSpinner({ ...showSpinner, status: false, id: "" });
    //     toast.error("Try again");
    //   }
    // } else {
    //   toast.error("Try again");
    // }

    setSpinner({ ...showSpinner, status: false, id: "" });
  };

  const closeCropModal = () => {
    setShowCropModal({ ...showCropModal, showModal: false });
  };

  const renderTooltip = (props) => (
    <Tooltip id="previewId" {...props}>
      Double click to preview
    </Tooltip>
  );

  const handleShow = () => setShowModal(true);

  const handleClose = () => setShowModal(false);
  // console.log(allUserDetails?.user_awards[0]?.user_awards_profile_id);

  return (
    <Layout>
      {loaderStatus.status ? (
        <Loader
          type="ThreeDots"
          color="#00BFFF"
          height={"80vh"}
          width={50}
          className="loader"
        />
      ) : (
        <div className="container-xxl margin-position">
          <div className="row">
            <div className="col-lg-3 profile-top">
              <LeftMenu
                editMode={editMode}
                setImage={setImage}
                profileData={profileDetails}
                editableStatus={editableStatus}
                profileChangeHandler={profileChangeHandler}
                editProfileData={editProfileData}
                btnText={btnText}
                setFileData={setFileData}
                showSpinner={showSpinner}
                showCropModal={showCropModal}
                setShowCropModal={setShowCropModal}
                closeCropModal={closeCropModal}
              />
            </div>

            <div className="col-lg-9 active-profile-view profile-margin-top">
              <div className="profile-name-details row text-capitalize">
                <div className="nav nav-tabs">
                  <>
                    <div
                      className={
                        `profile-label nav-link p-2 col-md-2 ` +
                        ` active ${props.activeColor}-font`
                      }
                      onClick={() => handleShow()}
                    >
                      <OverlayTrigger placement="top" overlay={renderTooltip}>
                        <label role="tab">Profile</label>
                      </OverlayTrigger>
                    </div>
                  </>

                  <div className="col">
                    <div className="pull-right new-profile-plus">
                      {editableStatus.status ? (
                        <span
                          className={`btn btn-sm m-2 btn-${props.activeColor}`}
                          onClick={(e) => createNewComponent(e)}
                        >
                          {" "}
                          <FontAwesomeIcon
                            role="button"
                            icon={faUserPlus}
                            className=""
                          />
                        </span>
                      ) : (
                        ""
                      )}
                      <span
                        className={`btn btn-sm  btn-${props.activeColor}`}
                        onClick={() => handleShow()}
                      >
                        <span className="fa-layers fa-fw">
                          <FontAwesomeIcon icon={faPen} />
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <Tabs
                defaultActiveKey="aboutme"
                className={`profile-nav-tabs profile-deatils-view profile-heading-font  about-me-weight ${props.activeColor}-nav`}
              >
                <Tab eventKey="aboutme" title="About me">
                  <div className="tab-content border-left border-bottom border-right pb-4 pr-4 mb-4">
                    <div
                      className="tab-pane pl-4 active"
                      id="about"
                      role="tabpanel"
                      aria-labelledby="about-tab"
                    >
                      {/* trainer profile brief introduction */}

                      <>
                        <div className="profile-deatils-view  aboutme-brief">
                          {/* <div className="text-infos clearfix my-2"> */}
                          <div className="d-flex my-2 flex-row">
                            <div
                              className={
                                `lead font-weight-bold col-auto profile-heading-font` +
                                (!profileDetails.user_description_enabled
                                  ? "text-muted hide-opacity"
                                  : "")
                              }
                              // style={{ width: "91.5%" }}
                            >
                              Brief introduction
                            </div>
                          </div>

                          <p className="paragraph-height">
                            {profileDetails.user_profile_description
                              ? renderHTML(
                                  profileDetails.user_profile_description,
                                )
                              : "No description found"}
                          </p>
                          {/* )} */}
                        </div>
                      </>

                      {/* Training section */}
                      {role == "trainer" ? (
                        <>
                          <div className="profile-deatils-view box-shadow">
                            <div className="d-flex my-2 flex-row">
                              <div
                                className={
                                  `lead font-weight-bold col-auto profile-heading-font` +
                                  (!allUserDetails?.user_training_experience
                                    ? "text-muted hide-opacity"
                                    : "")
                                }
                                // style={{ width: "91.5%" }}
                              >
                                Training experience
                              </div>
                            </div>

                            {allUserDetails?.user_training_experience
                              ?.length ? (
                              allUserDetails?.user_training_experience.map(
                                (training, trainingKey) => {
                                  if (
                                    1 ||
                                    (editableStatus.status &&
                                      !training.userexperience_show)
                                  ) {
                                    return (
                                      <TrainingExperience
                                        index={trainingKey}
                                        trainingData={training}
                                        editExperienceDesignation={
                                          editExperienceDesignation
                                        }
                                        experienceChangeHandler={
                                          experienceChangeHandler
                                        }
                                        editableStatus={editableStatus}
                                        showAndHideOption={showAndHideOption}
                                        modules={modules}
                                        formats={formats}
                                        activeExperience={
                                          allUserDetails?.user_training_experience
                                        }
                                      />
                                    );
                                  }
                                },
                              )
                            ) : (
                              <p>No training details found</p>
                            )}
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* Job experience */}
                      <>
                        <div className="profile-deatils-view box-shadow">
                          <div className="d-flex my-2 flex-row">
                            <div
                              className={
                                `lead font-weight-bold col-auto profile-heading-font` +
                                (allUserDetails?.user_job_experience
                                  ? ""
                                  : "text-muted hide-opacity")
                              }
                              id="jobExperienceTitle"
                              // style={{ width: "91.5%" }}
                            >
                              Job experience
                            </div>
                          </div>
                          {allUserDetails?.user_job_experience?.length ? (
                            allUserDetails?.user_job_experience.map(
                              (job, jobKey) => {
                                if (
                                  1 ||
                                  (editableStatus.status &&
                                    !job.user_job_experience_show)
                                ) {
                                  return (
                                    <JobExperience
                                      index={jobKey}
                                      jobData={job}
                                      activeJobExperience={
                                        allUserDetails?.user_job_experience
                                      }
                                      editJobExperience={editJobExperience}
                                      jobChangeHandler={jobChangeHandler}
                                      editableStatus={editableStatus}
                                      showAndHideOption={showAndHideOption}
                                    />
                                  );
                                }
                              },
                            )
                          ) : (
                            <p>No job experience found</p>
                          )}
                        </div>
                      </>

                      {/* qualification section */}

                      <>
                        <div className="profile-deatils-view box-shadow">
                          <div className="d-flex my-2 flex-row">
                            <div
                              className={
                                `lead font-weight-bold col-auto profile-heading-font` +
                                (!allUserDetails?.user_qualifications
                                  ? "text-muted hide-opacity"
                                  : "")
                              }
                              // style={{ width: "91.5%" }}
                            >
                              Qualifications
                            </div>
                          </div>
                          {allUserDetails?.user_qualifications?.length
                            ? allUserDetails?.user_qualifications.map(
                                (qualification, qualificationKey) => {
                                  if (
                                    1 ||
                                    (editableStatus.status &&
                                      !qualification.userqualification__show)
                                  ) {
                                    return (
                                      <Qualification
                                        index={qualificationKey}
                                        qualificationData={qualification}
                                        activeQualification={
                                          allUserDetails?.user_qualifications
                                        }
                                        editQualification={editQualification}
                                        qualificationChangeHandler={
                                          qualificationChangeHandler
                                        }
                                        editableStatus={editableStatus}
                                        showAndHideOption={showAndHideOption}
                                      />
                                    );
                                  }
                                },
                              )
                            : "No qualification details found"}
                        </div>
                      </>
                      {/* Certifications */}
                      <>
                        <div className="profile-deatils-view box-shadow">
                          <div className="d-flex my-2 flex-row">
                            <div
                              className={
                                `lead font-weight-bold col-auto profile-heading-font` +
                                (!allUserDetails?.user_certificates
                                  ? "text-muted hide-opacity"
                                  : "")
                              }
                              // style={{ width: "91.5%" }}
                            >
                              Certifications
                            </div>
                          </div>
                          {allUserDetails?.user_certificates?.length
                            ? allUserDetails?.user_certificates.map(
                                (certificate, certificateKey) => {
                                  if (
                                    1 ||
                                    (editableStatus.status &&
                                      !certificate.user_certificates_show)
                                  ) {
                                    return (
                                      <Certificates
                                        index={certificateKey}
                                        certificateData={certificate}
                                        activeCertificates={
                                          allUserDetails?.user_certificates
                                        }
                                        editCertificate={editCertificate}
                                        certificateChangeHandler={
                                          certificateChangeHandler
                                        }
                                        editableStatus={editableStatus}
                                        showAndHideOption={showAndHideOption}
                                      />
                                    );
                                  }
                                },
                              )
                            : "No certification found"}
                        </div>
                      </>

                      {/* awards section */}
                      {allUserDetails?.user_awards ? (
                        <>
                          <div className="profile-deatils-view box-shadow">
                            <div className="d-flex my-2 flex-row">
                              <div
                                className={`lead font-weight-bold col-auto profile-heading-font`}
                                // style={{ width: "91.5%" }}
                              >
                                Awards
                              </div>
                            </div>
                            <div>
                              {allUserDetails?.user_awards?.length
                                ? allUserDetails?.user_awards.map(
                                    (award, awardKey) => {
                                      if (
                                        1 ||
                                        (editableStatus.status &&
                                          !award.user_awards_show)
                                      ) {
                                        return (
                                          <Awards
                                            index={awardKey}
                                            awardData={award}
                                            activeAwards={
                                              allUserDetails?.user_awards
                                            }
                                            editAward={editAward}
                                            awardChangeHandler={
                                              awardChangeHandler
                                            }
                                            editableStatus={editableStatus}
                                            showAndHideOption={
                                              showAndHideOption
                                            }
                                          />
                                        );
                                      }
                                    },
                                  )
                                : null}

                              {/* description section */}
                            </div>
                          </div>
                        </>
                      ) : (
                        ""
                      )}

                      {/* More about me section */}
                      <div
                        className={`profile-deatils-view box-shadow ${props.activeColor}-font`}
                      >
                        <p className="lead font-weight-bold text-infos mb-2 text-muted hide-opacity ">
                          More about me
                        </p>
                        <Tabs
                          defaultActiveKey="skills"
                          id="uncontrolled-tab-example"
                          className={`m-0  more-about-tab ${props.activeColor}-nav `}
                        >
                          <Tab eventKey="skills" title="Skills">
                            {editableStatus.status === true ? (
                              <div className="mb-4 my-3">
                                <span
                                  onClick={(e) => generateNewSkill(e, "top")}
                                  style={{ cursor: "pointer" }}
                                >
                                  <FontAwesomeIcon
                                    icon={faPlus}
                                    role="button"
                                    className="text-muted mx-2"
                                  />
                                  Add Skills
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="row justify-content-between flex-column skill-scroll">
                              {allUserDetails.user_skills?.length
                                ? allUserDetails.user_skills.map(
                                    (skill, indexs) => {
                                      if (
                                        1 ||
                                        (editableStatus.status &&
                                          !skill.user_skill_show)
                                      ) {
                                        return (
                                          <MoreSkills
                                            index={indexs}
                                            skillData={skill}
                                            activeSkill={
                                              allUserDetails?.user_skills
                                            }
                                            editSkill={editSkill}
                                            skillChangeHandler={
                                              skillChangeHandler
                                            }
                                            editableStatus={editableStatus}
                                            showAndHideOption={
                                              showAndHideOption
                                            }
                                            showSpinner={showSpinner}
                                          />
                                        );
                                      }
                                    },
                                  )
                                : null}
                            </div>
                          </Tab>
                          <Tab eventKey="interests" title="Interests">
                            {editableStatus.status ? (
                              <div className="mb-4 my-3">
                                {showSpinner.status &&
                                showSpinner.id === "interest" ? (
                                  <CommanSpinner />
                                ) : (
                                  <span
                                    onClick={(e) =>
                                      showInterestModal("interest")
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <FontAwesomeIcon
                                      icon={faPlus}
                                      className="text-muted mx-2"
                                      role="button"
                                    />
                                    Add Interest
                                  </span>
                                )}
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="row justify-content-between flex-column more-interest-scroll">
                              {interestData?.length ? (
                                interestData.map((interest, intKey) => {
                                  if (
                                    interest.user_interests_show ||
                                    (editableStatus.status &&
                                      !interest.user_interests_show)
                                  ) {
                                    return (
                                      <Interests
                                        index={intKey}
                                        editableStatus={editableStatus}
                                        showInterestModal={showInterestModal}
                                        showSpinner={showSpinner}
                                        interestData={interest}
                                        allInterests={interestData}
                                        activeInterest={
                                          props.activeProfile.interest
                                        }
                                        showAndHideOption={showAndHideOption}
                                      />
                                    );
                                  }
                                })
                              ) : (
                                <p className="mt-3 mb-3">No data found</p>
                              )}
                            </div>
                          </Tab>
                        </Tabs>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs>
            </div>
          </div>
        </div>
      )}

      <Suspense fallback={""}>
        <LoginModal show={showModal} handleClose={handleClose} />
      </Suspense>
      <Suspense fallback={""}>
        <UploadCVModal
          showModal={true}
          setFileData={setFileData}
          showSpinner={showSpinner}
        ></UploadCVModal>
      </Suspense>
    </Layout>
  );
};

const mapStateToProps = (state) => ({
  allInterests: state.profile.allInterests,
  activeProfile: state.profile.activeProfile,
  activeColor: state.activetheme.activeColor,
  darkTheme: state.activetheme.darkTheme,
  endorseDetails: state.profile.endorseDetails,
  interest: state.profile.interest,
  profile: state.profile.profile,
  image: state.profile.image,
});

export default connect(mapStateToProps, {
  getProfileDetails,
  setActiveProfile,
  addUserProfile,
  setActiveColor,
  setDarkTheme,
  trainingExperience,
  jobExperience,
  qualification,
  certificate,
  award,
  skill,
  description,
  recordHide,
  createNewProfile,
  sectionShow,
  endorse,
  getEndorseDetails,
  editProfileMenu,
  addInterest,
  getInterests,
  getProfile,
  setImages,
})(Profile);

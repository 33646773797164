import React from 'react';

import { connect } from "react-redux";

import CommonService from '../../../../../services/common.service';

const TrainingDetails = (props) => {
  return (
    <div className='row requirement-preview px-2'>
       <div className='col-6'>

              {(props.post.requirements_training_trainer_looking_for_id) ?
                    <div className="form-group row">
                      <label  className="col-sm col-form-label">Looking for: </label>
                      <div className="col-sm">
                        {props.post.requirements_training_trainer_looking_for_id}
                      </div>
                    </div>
                : null 
              }

              {(props.post?.require_industry?.industry_name) ?
                  <div className="form-group row">
                    <label  className="col-sm col-form-label">Industry: </label>
                    <div className="col-sm">
                      {props.post.require_industry.industry_name}
                    </div>
                  </div>
              : null 
            }
            {(props.post?.requirements_training_category_id) ?
              <div className="form-group row">
                <label  className="col-sm col-form-label">Training Category: </label>
                <div className="col-sm">
                {CommonService.filterArray(props.trainingCategory, 'category_id', props.post.requirements_training_category_id).pop().category_name} 
                </div>
              </div>
          : null 
          }

        {(props.post?.requirements_training_no_of_days) ?
          <div className="form-group row">
            <label  className="col-sm col-form-label">Planned training days: </label>
            <div className="col-sm">
           {props.post.requirements_training_no_of_days}
            </div>
          </div>
        : null 
        }

        {(props.post?.requirements_training_startdate) ?
          <div className="form-group row">
            <label  className="col-sm col-form-label">From: </label>
            <div className="col-sm">
           {props.post.requirements_training_startdate}
            </div>
          </div>
        : null 
        }

        {(props.post?.requirements_training_enddate) ?
            <div className="form-group row">
              <label  className="col-sm col-form-label">To: </label>
              <div className="col-sm">
            {props.post.requirements_training_enddate}
              </div>
            </div>
          : null 
          }
    

       </div>
       <div className='col-6'>
       {(props.post?.requirements_training_expectation) ?
              <div className="form-group row">
                <label  className="col-sm col-form-label">Specific expectation in Training:</label>
                <div className="col-sm">
                  {props.post.requirements_training_expectation}
                </div>
              </div>
          : null 
        } 

        {(props.post?.requirements_training_hours_per_day) ?
          <div className="form-group row">
            <label  className="col-sm col-form-label">Hours of training per day to plan:</label>
            <div className="col-sm">
          {props.post.requirements_training_hours_per_day}
            </div>
          </div>
        : null 
        }
         {(props.post?.requirements_training_no_of_participants) ?
            <div className="form-group row">
              <label  className="col-sm col-form-label">Number of Participant:</label>
              <div className="col-sm">
            {props.post.requirements_training_no_of_participants}
              </div>
            </div>
          : null 
          }

          {(props.post?.requirements_training_cost_currency) ?
            <div className="form-group row">
              <label  className="col-sm col-form-label">Training Cost:</label>
              <div className="col-sm">
              {CommonService.filterArray(props.currencies, 'currency_code', props.post.requirements_training_cost_currency).pop().currency_symbol} {props.post.requirements_training_cost}
              </div>
            </div>
          : null 
          }


        </div>
    </div>
  );
};

const mapStateToProps = state => ({
  trainingCategory: state.reqTraining.trainingCategory,
  currencies: state.common.currencies,
})

export default connect(mapStateToProps)(TrainingDetails);

import { useState, useRef, useCallback, Suspense } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import { Modal, ProgressBar } from "react-bootstrap";
import Cropper from "react-easy-crop";
import Overlay from "react-bootstrap/Overlay";
import Tooltip from "react-bootstrap/Tooltip";
import { RatingView } from "react-simple-star-rating";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  EmailShareButton,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import {
  faBriefcase,
  faMapMarker,
  faComment,
  faLink,
  faPencilAlt,
} from "@fortawesome/free-solid-svg-icons";
import { gapi } from "gapi-script";

import CommanSpinner from "../../components/spinner/CommanSpinner";
import { setActiveProfile, addUserProfile } from "../../actions/profile";
import { getUserPlan } from "../../utils/UserDetails";
import LoginModal from "./LoginModal";

const LeftMenu = (props) => {
  const [imageUrl, setImageUrl] = useState({ url: "", isBgImg: false });
  const [isBgImg, setIsBgImg] = useState(false);
  const [speed, setSpeed] = useState(0);
  const fileRef = useRef();
  const imgRef = useRef();
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [upImg, setUpImg] = useState();
  const [rotation, setRotation] = useState(0);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const target = useRef(null);

  let countClick = Cookies.get("count") ? Cookies.get("count") : 0;

  const onBgImgHandler = () => {
    fileRef.current.click();
  };

  const onImgHandler = () => {
    imgRef.current.click();
  };

  const onSelectFile = (e, isBgImg = 0) => {
    resetSelect();

    if (e.target.files && e.target.files.length > 0) {
      props.setShowCropModal({ ...props.showCropModal, showModal: true });
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);

      if (isBgImg === 1) {
        setIsBgImg(true);
        setImageUrl({ ...imageUrl, isBgImg: true });
      }
    } else {
      console.log("error");
    }
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        upImg,
        croppedAreaPixels,
        rotation,
      );
      props.setImage(imageUrl, croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation]);

  const createImage = (url) => {
    const image = new Image();
    image.addEventListener("load", image);
    image.setAttribute("crossOrigin", "anonymous");
    image.src = url;
    return image;
  };

  async function getCroppedImg(
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false },
  ) {
    const image = createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation,
    );
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);
    ctx.drawImage(image, 0, 0);
    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
    );
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    ctx.putImageData(data, 0, 0);

    return canvas.toDataURL("image/jpg");
  }

  function getRadianAngle(degreeValue) {
    return (degreeValue * Math.PI) / 180;
  }

  function rotateSize(width, height, rotation) {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  }

  const resetSelect = () => {
    setIsBgImg(false);
    setZoom(1);
    setImageUrl({ ...imageUrl, isBgImg: false });
    setUpImg(null);

    return true;
  };

  const googlecontact = () => {
    const CLIENT_ID =
      "529738993063-jjpc5udvo98lf1ghtp5decdhg1el0l6k.apps.googleusercontent.com";
    const API_KEY = "AIzaSyCfloUmKM0-0d0AHlLhg8zPx2W_pU3qfXw";
    const SCOPES = "https://www.googleapis.com/auth/contacts.readonly";
    const initClient = () => {
      gapi.client
        .init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: [
            "https://people.googleapis.com/$discovery/rest?version=v1",
          ],
          scope: SCOPES,
        })
        .then(() => {
          gapi.auth2
            .getAuthInstance()
            .signIn()
            .then(() => {
              loadContacts();
            });
        });
    };
    gapi.load("client:auth2", initClient);
  };

  const loadContacts = () => {
    gapi.client.people.people.connections
      .list({
        resourceName: "people/me",
        pageSize: 1000,
        personFields: "names,emailAddresses,phoneNumbers",
      })
      .then((response) => {
        const contacts = response.result.connections;
        console.log(contacts);
      });
  };

  const handleShow = () => setShowModal(true);

  const handleClose = () => setShowModal(false);

  return (
    <>
      <div className="post-details-area for-profile">
        <div className="title-label">
          {/* <span className={`view-edit-status ${props.activeColor}`} onClick={(e) => props.editMode(e, props.btnText.text)}>{(props.btnText.text === false) ? 'Click to edit' : 'Click to view'}  </span> */}
        </div>
      </div>
      <div className="card position-relative about-me" id="leftMenuDiv">
        {/* bg img */}
        <div className="profile-background">
          <img
            src={
              props.profileData.user_profile_bg_img_url
                ? props.profileData.user_profile_bg_img_url
                : process.env.PUBLIC_URL +
                  "/images/icons/profile-background.jpg"
            }
            role="button"
            className="profile-cover-image"
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = process.env.PUBLIC_URL + "/images/back-cover.png";
            }}
          />
          <input
            type="file"
            accept="image/*"
            onChange={(e) => onSelectFile(e, 1)}
            ref={fileRef}
          />
        </div>

        {/* round img */}
        <div className="profile-round-image">
          <img
            src={
              props.profileData.user_profile_image_url
                ? props.profileData.user_profile_image_url
                : ""
            }
            className="profile-pic shadow"
            alt=""
            onError={(e) => {
              e.target.onerror = null;
              e.target.src =
                process.env.PUBLIC_URL + "/images/icons/profile1.jpg";
            }}
          />
          <input
            type="file"
            accept="image/*"
            name="profileImageUpload"
            onChange={(e) => onSelectFile(e)}
            ref={imgRef}
          />
        </div>

        <div className="card-body card-body-bottom pt-0">
          <div className="profile-data">
            <h5
              className={`card-title text-capitalize ${props.activeColor}-font`}
              //   onClick={() => {
              //     window.open(
              //       `preview/${props.profileData.user_profile_url}`,
              //       "_blank",
              //     );
              //   }}
              style={{ cursor: "pointer" }}
            >
              Anonymous
            </h5>
            <div className="d-flex flex-row bd-highlight justify-content-end">
              <div className="p-1 bd-highlight">
                <FontAwesomeIcon icon={faBriefcase} className="text-muted" />
              </div>
              <div className="p-1 bd-highlight">
                {props.editableStatus.status ? (
                  <input
                    type="text"
                    className="form-control py-1 m-0 text-center"
                    name="user_profile_name"
                    value={
                      props.profileData.user_profile_name
                        ? props.profileData.user_profile_name
                        : ""
                    }
                    onBlur={(e) => props.editProfileData(props.profileData)}
                    onChange={(e) => props.profileChangeHandler(e)}
                  />
                ) : (
                  <small>
                    <p className="m-1">Profile</p>
                  </small>
                )}
              </div>
            </div>
            <div className="star-left-menu prfl-details-none m-1">
              {/* <RatingView ratingValue={0} stars={5} size={15} /> */}
            </div>
          </div>
          <p className="card-text prfl-details-none pb-0 mb-0">
            <div className="d-flex flex-row justify-content-end m-1 ">
              {props.editableStatus.status ? (
                <input
                  type="text"
                  className="form-control py-1 m-0 text-center"
                  placeholder="Address"
                  name="user_profile_location"
                  value={
                    props.profileData.user_profile_location
                      ? props.profileData.user_profile_location
                      : ""
                  }
                  onBlur={(e) => props.editProfileData(props.profileData)}
                  onChange={(e) => props.profileChangeHandler(e)}
                />
              ) : props.editableStatus.status ? (
                <small className="mx-2">
                  <FontAwesomeIcon
                    icon={faMapMarker}
                    className="text-muted map-icon"
                  />
                  {props.profileData
                    ? props.profileData.user_profile_location
                    : ""}
                </small>
              ) : (
                ""
              )}
            </div>
            <div className="d-flex flex-row justify-content-end bd-highlight m-1">
              {props.editableStatus.status ? (
                <input
                  type="text"
                  className="form-control py-1 me-1 text-center"
                  placeholder="Area"
                  name="user_profile_locality"
                  value={
                    props.profileData.user_profile_locality
                      ? props.profileData.user_profile_locality
                      : ""
                  }
                  onBlur={(e) => props.editProfileData(props.profileData)}
                  onChange={(e) => props.profileChangeHandler(e)}
                />
              ) : (
                <small className="mx-2">
                  {props.profileData.user_profile_locality}
                </small>
              )}
              {props.editableStatus.status ? (
                <input
                  type="text"
                  className="form-control py-1 m-0 text-center"
                  placeholder="City"
                  name="user_profile_city"
                  value={
                    props.profileData.user_profile_city
                      ? props.profileData.user_profile_city
                      : ""
                  }
                  onBlur={(e) => props.editProfileData(props.profileData)}
                  onChange={(e) => props.profileChangeHandler(e)}
                />
              ) : (
                <small className="mx-2">
                  {props.profileData.user_profile_city}
                </small>
              )}
            </div>
            <div className="d-flex flex-row justify-content-end bd-highlight m-1">
              {props.editableStatus.status ? (
                <input
                  type="text"
                  className="form-control py-1 me-1 text-center"
                  placeholder="State"
                  name="user_profile_state"
                  value={
                    props.profileData.user_profile_state
                      ? props.profileData.user_profile_state
                      : ""
                  }
                  onBlur={(e) => props.editProfileData(props.profileData)}
                  onChange={(e) => props.profileChangeHandler(e)}
                />
              ) : (
                <small className="mx-2">
                  {props.profileData.user_profile_state}
                </small>
              )}
              {props.editableStatus.status ? (
                <input
                  type="text"
                  className="form-control py-1 m-0 text-center"
                  placeholder="Country"
                  name="user_profile_country"
                  value={
                    props.profileData.user_profile_country
                      ? props.profileData.user_profile_country
                      : ""
                  }
                  onBlur={(e) => props.editProfileData(props.profileData)}
                  onChange={(e) => props.profileChangeHandler(e)}
                />
              ) : (
                <small className="mx-2">
                  {props.profileData.user_profile_country}
                </small>
              )}
            </div>
            <FontAwesomeIcon icon={faComment} className="text-muted" />{" "}
            <small className="m-2">
              example@gmail.com
              {/* {props.profileData ? props.profileData.user_profile_email : ""} */}
            </small>
            <br />
            <small className="m-2">
              {props.profileData
                ? props.profileData.user_profile_contact_number
                : ""}
            </small>
            <div className="d-flex flex-row align-items-center justify-content-end  profile-url ">
              <div className="mr-2" onClick={() => handleShow()}>
                <Link to="#">
                  <small className="">profileview/preview</small>
                </Link>
              </div>
              <div
                className="px-2"
                ref={target}
                onClick={() => handleShow()}
                onMouseLeave={() => setShow(false)}
              >
                <FontAwesomeIcon
                  icon={faLink}
                  className="text-muted wa-icon"
                  role="button"
                  //   onClick={() => {
                  //     navigator.clipboard.writeText(
                  //       window.location.href +
                  //         "-view/" +
                  //         props.profileData.user_profile_url,
                  //     );
                  //   }}
                />
              </div>
              <Overlay target={target.current} show={show} placement="top">
                {(props) => <Tooltip {...props}>Copied!</Tooltip>}
              </Overlay>
              <div className="px-2" onClick={() => handleShow()}>
                {/* <WhatsappShareButton
                  url={
                    window.location.href +
                    "-view/" +
                    props.profileData.user_profile_url
                  }
                  title="ProfileUrl: "
                  separator=""
                > */}
                <WhatsappIcon className="wa-icon" round={true} />
                {/* </WhatsappShareButton> */}
              </div>
              <div className="px-2 email-icon-svg" onClick={() => handleShow()}>
                {/* <EmailShareButton
                  subject="Profile Url"
                  url=""
                  body={
                    window.location.href +
                    "-view/" +
                    props.profileData.user_profile_url
                  }
                > */}
                <img
                  className="email-img"
                  src={process.env.PUBLIC_URL + "/images/email-icon.png"}
                  alt=""
                  width={22}
                  height={22}
                />
                {/* </EmailShareButton> */}
              </div>
            </div>
          </p>
          <div className="d-flex pt-0 pb-0 justify-content-center">
            <div>
              <small className="px-2 ">Your current plan is</small>
            </div>
            <div>
              <h5
                className={`text-capitalize  fw-bold ${props.activeColor}-font`}
              >
                {getUserPlan()?.plan_name?.membership_type_name
                  ? getUserPlan()?.plan_name?.membership_type_name
                  : "No Plan"}
              </h5>
            </div>
          </div>
          <div
            className="px-2 pb-2 d-flex justify-content-center"
            onClick={() => handleShow()}
          >
            {" "}
            <Link to={"#"}>
              <small>Click to upgrade plan</small>
            </Link>
          </div>
          {/* <div
            onClick={googlecontact}
            className="d-flex justify-content-center"
          >
            <span className="btn btn-sm bg-dark-light w-100">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/images/icons/icon-google-search.svg"
                }
                width={20}
                height={20}
                className="me-2"
              />
              Import Google contact
            </span>
          </div>
          <hr className="prfl-details-none" /> */}

          <div className=" pt-2 d-flex  justify-content-between">
            {" "}
            <small className="prfl-details-none py-3">
              Profile completeness
            </small>
            <div className="circle-wrapper ">
              <div
                className="circle-progress"
                style={{ "--value": speed }}
              ></div>
              <div className="circle-inner">
                <span className="percentage">{speed}%</span>
              </div>
            </div>
          </div>
          {/* <div className="progress prfl-details-none">
            <div
              className={`progress-bar w-75 m-0 ${props.activeColor}`}
              role="progressbar"
              aria-valuenow="75"
              aria-valuemin="0"
              aria-valuemax="100"
            >
              75%
            </div>
          </div> */}

          <p>
            <small className="prfl-details-none">
              Profile last updated on:
              <br />
              1st March 2019
            </small>
          </p>

          {props.showSpinner.status && props.showSpinner.id === "resume" ? (
            <label className={`btn  btn-block btn-${props.activeColor}`}>
              <CommanSpinner />
            </label>
          ) : (
            <label
              className={`btn  btn-block    profile-btn prfl-details-none ${
                countClick > 1
                  ? "btn-primary disabled"
                  : `btn-${props.activeColor}`
              }`}
              onClick={() => (countClick > 1 ? handleShow() : "")}
            >
              <b> Upload CV </b>
              <input
                type="file"
                accept="application/msword, text/plain, application/pdf, application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                name="resumeUpload"
                className="d-none "
                onChange={(e) => props.setFileData(e, "resume")}
                disabled={countClick > 1 ? true : false}
              />
            </label>
          )}
          <small className="prfl-details-none">(*.pdf;*.doc)</small>

          <hr className="prfl-details-none" />
          <p className="prfl-details-none">
            People{" "}
            {props.profileData.user
              ? ` ${props.profileData.user.first_name}  ${props.profileData.user.last_name}`
              : ""}{" "}
            is following
          </p>
          <hr className="prfl-details-none" />
          <p className="prfl-details-none">
            Groups{" "}
            {props.profileData.user
              ? ` ${props.profileData.user.first_name}  ${props.profileData.user.last_name}`
              : ""}{" "}
            is following
          </p>
          <hr className="prfl-details-none" />
          <p className="prfl-details-none">
            People following{" "}
            {props.profileData.user
              ? ` ${props.profileData.user.first_name}  ${props.profileData.user.last_name}`
              : ""}
          </p>
        </div>
      </div>

      <Suspense fallback={""}>
        <LoginModal show={showModal} handleClose={handleClose} />
      </Suspense>

      {/* Image modal */}
      <Modal
        show={props.showCropModal.showModal}
        onHide={props.closeCropModal}
        backdrop="static"
        keyboard={false}
        className="tj-modal"
        centered
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title className="flex-grow-1 text-center">
            Upload image
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              position: "relative",
              width: "100%",
              height: 250,
              background: "#333",
            }}
          >
            <Cropper
              image={upImg}
              crop={crop}
              {...(isBgImg ? { cropShape: "rect" } : { cropShape: "round" })}
              zoom={zoom}
              {...(isBgImg ? { aspect: 16 / 8 } : { aspect: 1 })}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            />
          </div>
          <div className="row justify-content-center mt-4">
            <div className="col-md-4"></div>
            <div className="col-md-1" style={{ marginTop: "-3px" }}>
              <label className="mx-3">Zoom</label>
            </div>
            <div className="col-md-6">
              <input
                type="range"
                value={zoom !== null ? zoom : ""}
                min={1}
                max={3}
                step={0.1}
                onChange={(e) => setZoom(e.target.value)}
                id="zoom-range"
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-sm interest-close-btn"
            onClick={showCroppedImage}
          >
            Upload
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  userProfiles: state.userProfiles,
  activeProfile: state.activeProfile,
  activeColor: state.activetheme.activeColor,
});

export default connect(mapStateToProps, { setActiveProfile, addUserProfile })(
  LeftMenu,
);

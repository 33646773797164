import { useEffect, useState } from "react";
import { batch, connect } from "react-redux";

import { Modal } from "react-bootstrap";
// import CurrencyFormat from 'react-currency-format';
// import renderHTML from 'react-render-html';

import { addBatch } from "../../../../actions/training";
import useDidMountEffect from "../../../../components/DidMount";
import Spinner from "../../../../components/spinner/CommanSpinner";

const BatchModal = (props) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const [batchCount, setBatchCount] = useState(null);
  const [batchName, setBatchName] = useState(null);
  const [participantsCount, setParticipantsCount] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  useDidMountEffect(() => {
    setShowSpinner(false);
  }, [props.batch]);

  const validateForm = () => {
    let formErrors = {};

    if (!startDate) formErrors.startDate = "Start date is required";
    if (!endDate) formErrors.endDate = "End date is required";
    if (!batchName) formErrors.batchName = "Batch Name is required";
    if (!participantsCount)
      formErrors.participantsCount = "Number of Participants is required";

    props.setErrors(formErrors);

    return Object.keys(formErrors).length === 0; // returns true if no errors
  };

  const submitBatch = (e) => {
    if (
      startDate !== null &&
      endDate !== null &&
      batchName !== null &&
      participantsCount !== null
    ) {
      setShowSpinner(true);
      let batchData = {
        course_id: props.courseId[0].id,
        startDate: startDate,
        end_date: endDate,
        batch_name: batchName,
        total_participants: participantsCount,
      };
      props.setErrors({});
      props.addBatch(batchData);
    } else {
      validateForm();
    }
  };

  return (
    <>
      <Modal
        show={props.showBatchModal}
        onHide={props.closeBatchModal}
        backdrop="static"
        keyboard={false}
        centered
        className="tj-modal"
        size="lg"
      >
        <Modal.Header
          closeButton
          className={`d-flex mb-3 mt-3 `}
          style={{ height: "50px" }}
        >
          <Modal.Title className="flex-grow-1">Add Batch</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="m-2">
            {/* <div className="col-md-6  text-right ">
                <label>Number of Batches:</label>
              </div>
              <div className="col-md-6 ">
                <input
                  type="number"
                  className="form-control form-control-sm"
                  onChange={(e) => setBatchCount(e.target.value)}
                  required
                />
                <div style={{ height: "5px" }}>
                  {props?.errors.batchCount && (
                    <span className="text-danger">
                      {props?.errors.batchCount}
                    </span>
                  )}
                </div>
              </div> */}

            <div className="row mb-3 ">
              <div className="col-md-6 text-right">
                <label>Batch Name:</label>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control form-control-sm w-100"
                  onChange={(e) => setBatchName(e.target.value)}
                  required
                />
                <div style={{ height: "5px" }}>
                  {props?.errors.batchName && (
                    <span className="text-danger">
                      {props?.errors.batchName}
                    </span>
                  )}
                </div>
              </div>

              {/* <div style={{ height: "5px" }}>
                  {errors.batchName && (
                    <span className="text-danger">{errors.batchName}</span>
                  )}
                </div> */}
            </div>
            <div className="row mb-3">
              <div className="col-md-6 text-right">
                <label>Number of Participants:</label>
              </div>
              <div className="col-md-6">
                <input
                  type="number"
                  className="form-control form-control-sm"
                  onChange={(e) => setParticipantsCount(e.target.value)}
                  required
                />
                <div style={{ height: "5px" }}>
                  {props?.errors.participantsCount && (
                    <span className="text-danger">
                      {props?.errors.participantsCount}
                    </span>
                  )}
                </div>
              </div>
              {/* <div style={{ height: "5px" }}>
                {errors.participantsCount && (
                  <span className="text-danger">
                    {errors.participantsCount}
                  </span>
                )}
              </div> */}
            </div>
            <div className="row mb-3">
              <div className="col-md-6 text-right">
                <label>Start Date:</label>
              </div>
              <div className="col-md-6">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  onChange={(e) => setStartDate(e.target.value)}
                  required
                />
                <div style={{ height: "5px" }}>
                  {props?.errors.startDate && (
                    <span className="text-danger">
                      {props?.errors.startDate}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="row mb-3">
              <div className="col-md-6 text-right">
                <label>End Date:</label>
              </div>
              <div className="col-md-6">
                <input
                  type="date"
                  className="form-control form-control-sm"
                  onChange={(e) => setEndDate(e.target.value)}
                  required
                />
                <div style={{ height: "5px" }}>
                  {props?.errors.endDate && (
                    <span className="text-danger">{props?.errors.endDate}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-md-12 text-end">
                <button
                  type="button"
                  className="btn btn-outline-primary  btn-sm "
                  onClick={(e) => submitBatch(e)}
                >
                  {showSpinner ? <Spinner /> : "Submit"}
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  batch: state.training.batch,
});

export default connect(mapStateToProps, { addBatch })(BatchModal);
